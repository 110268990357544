import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { ConfigService } from 'src/app/tools/config.service';
import { FormComponent } from 'src/app/controllers/form/form.component';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ModelUser } from 'src/app/DAL/model-user';
import { ModelPerimeter } from 'src/app/DAL/model-perimeter';
import { actionMode, orientationType } from 'src/app/controllers/iconbutton/iconbutton.component';

/**
 * Admin user
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  host: {'class': 'user'},
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {
  /**
   * Sync key
   */
  SYNC_KEY: string = 'coacheer.sync';
  /**
   * Updating details
   */
  updating: boolean = false;
  /**
   * Sync
   */
  sync: boolean;
  /**
   * Sync Id
   */
  syncId: number;
  /*
  * Form name
  */
 formName: string;
 /**
  * List of form data
  */
 datas: Array<Formdata>;
 /**
  * Role icons
  */
 roleIcons: any = {
   'super_admin': 'account_balance',
   'admin': 'verified_user',
   'super_user': 'record_voice_over',
   'user': 'person',
 };
 /**
  * Roles
  */
 roles: Array<{'value': string,'name': string}> = [
   {
     'value': '1',
     'name': 'super_admin'
   },
   {
     'value': '2',
     'name': 'admin'
   },
   {
     'value': '3',
     'name': 'super_user'
   },
   {
     'value': '4',
     'name': 'user'
   }
 ];

 /**
  * License id
  */
 @Input() licenseId: string;
 /**
  * current configuration
  */
 @Input() conf: ConfigService;
 /**
  * current user
  */
 @Input() currentUser: any;
 /**
  * Action fired
  */
 @Output() endEdit: EventEmitter<any> = new EventEmitter();
 /**
  * Action fired
  */
 @Output() saved: EventEmitter<any> = new EventEmitter();
 /**
  * View child of the form
  */
 @ViewChild('c_form', {static: false}) c_form: FormComponent;

 /**
  * 
  * @param resources 
  * @param http 
  * @param dialog 
  * @param snackBar 
  */
 constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

 async ngOnInit() {
  this.datas = new Array<Formdata>();
  this.checkDetails();
  let requiredInfo = {
    require: true,
    requireText: this.resources.getResource('fa.common.required'),
    datas: [],
    hint: '*'
  };
  let role_data = [];
  for(let i_role of this.roles) {
    role_data.push({
      'value': i_role.value,
      'name': this.resources.getResource('fa.users.role.' + i_role.name)
    });
  }
  let roleInfo = {
    require: true,
    requireText: this.resources.getResource('fa.common.required'),
    datas: role_data,
    hint: '*'
  };
  let perimeter_list = <Array<any>> await ModelPerimeter.getPerimeterList(this.conf.http, this.licenseId).toPromise(); 
  let perimeter_data = [];
  for(let i_perimeter of perimeter_list) {
    perimeter_data.push({
      'value': i_perimeter.id,
      'name': i_perimeter.name
    });
  }
  let perimeterInfo = {
    require: true,
    requireText: this.resources.getResource('fa.common.required'),
    datas: perimeter_data,
    hint: '*'
  };
  let pictureInfo = {
    require: true,
    requireText: this.resources.getResource('fa.common.required'),
    datas: [],
    hint: this.resources.getResource('fa.users.picture.title'),
    iconName: 'camera_alt',
    orientation: orientationType.vertical,
    actionmode: actionMode.upload
  };
  this.datas.push(new Formdata('Image', this.resources.getResource('fa.users.picture'), this.currentUser['picture'], formdataType.image, pictureInfo, false));
  this.datas.push(new Formdata('login', this.resources.getResource('fa.users.login'), this.currentUser['login'], formdataType.mail, requiredInfo, false));
  this.datas.push(new Formdata('firstname', this.resources.getResource('fa.users.firstname'), this.currentUser['firstname'], formdataType.text, requiredInfo, false));
  this.datas.push(new Formdata('lastname', this.resources.getResource('fa.users.lastname'), this.currentUser['lastname'], formdataType.text, requiredInfo, false));
  this.datas.push(new Formdata('role', this.resources.getResource('fa.users.roleName'), this.currentUser['role']+'', formdataType.dropdown, roleInfo, false));
  this.datas.push(new Formdata('perimeters', this.resources.getResource('fa.users.perimeters'), this.currentUser['perimeters'], formdataType.multipledropdown, perimeterInfo, false));
  this.c_form.ngOnInit();
 }
 /**
  * Picture profile url
  */
 pictureSrc(c_user) {
   return ModelUser.getPictureUser(c_user,this.licenseId);
 }
 /**
  * CheckDetail
  */
 checkDetails() {
   this.sync = false;
   this.syncId = 0;
   for(let c_detail of this.currentUser.details) {
     if(c_detail.name == this.SYNC_KEY) {
       this.syncId = c_detail.id;
       if(c_detail.value == 'true') {
         this.sync = true;
       }
     }
   }

 }
 /**
  * Save playlist
  */
 async save() {
    if(this.c_form.isValid()) {
      try {      
        let c_data = {
          'id': this.currentUser.id,
          'login': this.c_form.currentForm.value.login,
          'firstname': this.c_form.currentForm.value.firstname,
          'lastname': this.c_form.currentForm.value.lastname,
          'role': this.c_form.currentForm.value.role,
          'perimeters': this.c_form.currentForm.value.perimeters,
          'license_id': this.licenseId
        };
        let res = await ModelUser.updateUser(this.conf.http, c_data).toPromise();
        let c_user_a = JSON.parse(res['message']);
        if(this.c_form.pictures) {
          let formData = this.c_form.pictures['Image'];
          if(formData instanceof FormData) {
            let ret = await ModelUser.uploadUserPicture(this.conf.http, c_user_a['Id'], this.licenseId, formData).toPromise();
          }      
        }
        this.saved.emit();
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }
 }
 /**
  * Sync changed
  */
 async syncChanged() {
   let temp_sync: boolean = this.sync;
   this.checkDetails();
   if(!this.updating) {
     this.updating = true;
     if(temp_sync) {
       if(this.syncId > 0){
         await ModelUser.updateUserDetail(this.conf.http, this.currentUser.id, this.syncId +'', 'true').toPromise();
       } else {
         await ModelUser.createUserDetail(this.conf.http, this.currentUser.id, this.SYNC_KEY, 'true').toPromise();
       }
     } else if(this.syncId > 0){
       await ModelUser.updateUserDetail(this.conf.http, this.currentUser.id, this.syncId +'', 'false').toPromise();
     }
     this.saved.emit(true);
     this.sync = temp_sync;
     this.updating = false;
   }
 }

 /**
  * Back to users
  */
 back() {
   this.endEdit.emit(true);
 }

}
