import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule  } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClickOutsideModule } from 'ng-click-outside';

import { 
  MatButtonModule, MatCheckboxModule, MatSelectModule, MatSlideToggleModule, 
  MatDialogModule, MatSnackBarModule, MatProgressSpinnerModule, MatInputModule, MatRadioModule,
  MatDatepickerModule, MatSliderModule, MatNativeDateModule,
  MatFormFieldModule, MatCardModule, MatListModule, MatSidenavModule, MatIconModule, MatChipsModule,
  MatTableModule, MatSortModule,MatPaginatorModule,MatTabsModule, MatTreeModule, MatAutocompleteModule
      } from '@angular/material';

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { NgxAudioPlayerModule } from 'ngx-audio-player';

import { AppComponent } from './app.component';
import { ErrorHandler } from './tools/error_handler';
import { RequestInterceptor } from './tools/http_interceptor';

import { HeaderComponent } from './controllers/header/header.component';
import { DialogComponent } from './controllers/dialog/dialog/dialog.component';
import { FormdialogComponent } from './controllers/dialog/formdialog/formdialog.component';
import { IconbuttonComponent } from './controllers/iconbutton/iconbutton.component';
import { FormComponent } from './controllers/form/form.component';
import { MultiAutocompleteChipsComponent } from './controllers/form/multi-autocomplete-chips/multi-autocomplete-chips.component';
import { ObjectDisplayerComponent } from './controllers/form/object-displayer/object-displayer.component';

import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { HomeComponent } from './pages/home/home.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CabComponent } from './pages/cab/cab.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { LicensesComponent } from './pages/admin/licenses/licenses.component';
import { LicenseComponent } from './pages/admin/license/license.component';
import { UsersComponent } from './pages/admin/license/users/users.component';
import { PerimetersComponent } from './pages/admin/license/perimeters/perimeters.component';
import { ProductsComponent } from './pages/admin/license/products/products.component';
import { CabDetailComponent } from './pages/cab/cab-detail/cab-detail.component';
import { MenuComponent } from './controllers/menu/menu.component';
import { CategoriesComponent } from './pages/admin/license/categories/categories.component';
import { PlaylistsComponent } from './pages/admin/license/playlists/playlists.component';
import { CategoryComponent } from './pages/admin/license/categories/category/category.component';
import { PlaylistComponent } from './pages/admin/license/playlists/playlist/playlist.component';
import { CablistComponent } from './pages/cablist/cablist.component';
import { ProductComponent } from './pages/admin/license/products/product/product.component';
import { InformationsComponent } from './pages/admin/license/informations/informations.component';
import { UserComponent } from './pages/admin/license/users/user/user.component';
import { AuthorsComponent } from './pages/admin/license/authors/authors.component';
import { AuthorComponent } from './pages/admin/license/authors/author/author.component';

/**
 * Module
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@NgModule({
  declarations: [
    AppComponent,
    
    HeaderComponent,
    DialogComponent,
    FormdialogComponent,
    IconbuttonComponent,
    FormComponent,
    MultiAutocompleteChipsComponent,
    ObjectDisplayerComponent,
    
    PagenotfoundComponent,
    HomeComponent,
    AdminComponent,
    CabComponent,
    LoginComponent,
    ProfileComponent,
    ResetpasswordComponent,
    LicensesComponent,
    LicenseComponent,
    UsersComponent,
    PerimetersComponent,
    ProductsComponent,
    CategoriesComponent,
    CategoryComponent,
    PlaylistsComponent,
    CabDetailComponent,
    MenuComponent,
    PlaylistComponent,
    CablistComponent,
    ProductComponent,
    InformationsComponent,
    UserComponent,
    AuthorsComponent,
    AuthorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ClickOutsideModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSliderModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatCardModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatChipsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTabsModule,
    MatTreeModule,
    MatAutocompleteModule,
    NgxUiLoaderModule,//.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule, // import this module for showing loader automatically when navigating between app routes
    NgxUiLoaderHttpModule, // import this module for showing loader automatically when calling xhr    
    SwiperModule,//Carousel
    NgxAudioPlayerModule,//Audio player
  ],
  entryComponents: [
    DialogComponent,
    FormdialogComponent
  ],
  providers: [
    ErrorHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
