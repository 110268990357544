import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModelTool } from './model-tool';

/**
 * ModelAuthor
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelAuthor {
  /**
   * Get author list url
   */
  static getAuthorListUrl: string = "/api/author/getList?keyword={0}&offset={1}&limit={2}&sort={3}&order={4}&l_id={5}";
  /**
   * Get author list url
   */
  static getAuthorUrl: string = "/api/author/getAuthor?id={0}";
  /**
   * Picture author url empty
   */
  static pictureAuthorUrlEmpty: string = '/assets/images/empty_product.jpg';
  /**
   * Picture author url setted
   */
  static pictureAuthorUrlSetted: string = '/api/author/{0}/getPictureAuthor?t={1}&l_id={2}';  
  /**
   * Create author url
   */
  static createAuthorUrl: string = "/api/author/createAuthor";
  /**
   * Edit author url
   */
  static editAuthorUrl: string = "/api/author/editAuthor";    
  /**
   * Upload picture author url
   */
  static uploadPictureAuthorUrl: string = "/api/author/{0}/uploadPictureAuthor?l_id={1}";
  
    /**
     * ctr
     */
    constructor(){

    }
    public static getAuthorList(http: HttpClient, keyword: string, offset: number, limit: number, sort: string, order: string, license_id: string): Observable<any> {
        return http.get(
            environment.prefixURLXHR + ModelAuthor.getAuthorListUrl
            .replace("{0}", keyword)
            .replace("{1}", offset + '')
            .replace("{2}", limit + '')
            .replace("{3}", sort)
            .replace("{4}", order)
            .replace("{5}", license_id)
        );
    }
    public static createAuthor(http: HttpClient, data_author: {
        'firstname': string,
        'lastname': string,
        'description': string,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelAuthor.createAuthorUrl, data_author);
    }
    public static getAuthor(http: HttpClient, id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelAuthor.getAuthorUrl.replace("{0}", id));
    }
    public static getPictureAuthor(current_author: any, license_id: string): string {        
        if(current_author && current_author.imageToken && current_author.imageToken.trim().length > 2) {
        return environment.prefixURLXHR + this.pictureAuthorUrlSetted.replace('{0}', current_author.id).replace('{1}', current_author.lastUpdate).replace("{2}", license_id);
      } else {
        return environment.prefixFileXHR + this.pictureAuthorUrlEmpty;
      }
    }
    public static uploadPictureAuthor(http: HttpClient, id: string, license_id: string, formData: any): Observable<any> {
        let url_upload = ModelAuthor.uploadPictureAuthorUrl.replace('{0}', id).replace('{1}', license_id+'');
        return ModelTool.upload(http, environment.prefixURLXHR + url_upload, formData);
    }
    public static editAuthor(http: HttpClient, data_product: {
        'id': number,
        'firstname': string,
        'lastname': string,
        'description': string,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelAuthor.editAuthorUrl, data_product);
    }    
}