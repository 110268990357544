import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * ModelCategory
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelCategory {
  /**
   * Get category list url
   */
  static getCategoryListUrl: string = "/api/category/getList?keyword={0}&offset={1}&limit={2}&sort={3}&order={4}&l_id={5}";
  /**
   * Create category url
   */
  static createCategoryUrl: string = "/api/category/createCategory";
  /**
   * Edit category url
   */
  static editCategoryUrl: string = "/api/category/editCategory";    
  /**
   * Get category url
   */
  static getCategoryUrl: string = "/api/category/getcategory?id={0}";    
  /**
   * Delete category url
   */
  static deleteCategoryUrl: string = "/api/category/deleteCategory";
    /**
     * ctr
     */
    constructor(){

    }
    public static getCategoryList(http: HttpClient, keyword: string, offset: number, limit: number, sort: string, order: string, license_id: string): Observable<any> {
      return http.get(
          environment.prefixURLXHR + ModelCategory.getCategoryListUrl
          .replace("{0}", keyword)
          .replace("{1}", offset + '')
          .replace("{2}", limit + '')
          .replace("{3}", sort)
          .replace("{4}", order)
          .replace("{5}", license_id)
      );
    }
    public static createCategory(http: HttpClient, data_category: {
        'title': string,
        'desc': string,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelCategory.createCategoryUrl, data_category);
    }
    public static editCategory(http: HttpClient, data_category: {
        'id': number,
        'title': string,
        'desc': string,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelCategory.editCategoryUrl, data_category);
    }
    public static getCategory(http: HttpClient, id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelCategory.getCategoryUrl.replace("{0}", id));
    }
    public static deleteCategory(http: HttpClient, data_category: {
        'id': number,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelCategory.deleteCategoryUrl, data_category);
    }
}