import { Component, ViewEncapsulation } from '@angular/core';
import { page } from '../page';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Md5 } from 'ts-md5';
import { ModelUser } from 'src/app/DAL/model-user';
import { ModelLicense } from 'src/app/DAL/model-license';
import { environment } from 'src/environments/environment';

/**
 * Reset password
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
  host: {'class': 'page'},
  encapsulation: ViewEncapsulation.None
})
export class ResetpasswordComponent  extends page {
  licenseLogoUrl: string = ModelLicense.logoDefaultSrc;

  password: string = "";
  confirm: string = "";

  /**
   * Creates an instance of about component.
   * @param resources Resources Service
   * @param conf Configuration Service
   * @param db Database Service
   * @param route Current route
   * @param router Router Service
   * @param snackBar SnackBar
   */
  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar) {
    super(_resources, _conf, _route, _router, _snackBar);
    this.licenseLogoUrl = environment.prefixFileXHR + ModelLicense.getPictureLicense({});
  }

  async resetPassword() {
    if(!this.passwordCondition()) {
      return;
    }
    try{
      let token = this.route.snapshot.paramMap.get('token');
      const md5 = new Md5();
      const md5_pwd = md5.appendStr(this.password).end();
      let value = await ModelUser.resetPassword(this.conf.http, token, md5_pwd).toPromise();
      
      this.snackBar.open(this.resources.getResource("fa.profile.resetPassword.success"),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
        this.router.navigate(['/login']);
    } catch(e) {
      this.snackBar.open(this.resources.getResource("fa.profile.resetPassword.error"),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }

  passwordCondition() {
    const PWD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return PWD_REGEXP.test(this.password) && this.password == this.confirm;
  }


}
