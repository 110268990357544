import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/tools/config.service';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ISubscription } from 'rxjs-compat/Subscription';
import { environment } from 'src/environments/environment';
import { ModelUser } from 'src/app/DAL/model-user';
import { ModelLicense } from 'src/app/DAL/model-license';

/**
 * Menu
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  /**
   * License logo
   */
  licenseLogoUrl: string = ModelLicense.logoDefaultSrc;
  /**
   * Logo title
   */
  logoTitle: string = '';
  /**
   * Picture profile url empty
   */
  pictureProfileUrl: string = '';
  /**
   * User title
   */
  userTitle: string = '';
  /**
   * Page title
   */
  pageTitle: string = '';
  /**
   * List of page available
   */
  Pages: Array<{name: string, url: Array<any>, icon: string}>;
  /**
   * Loaded subscription
   */
  private loadedSubscription: ISubscription;
  /**
   * Loaded subscription
   */
  private pageConfSubscription: ISubscription;

  /**
   * Contractutor
   */
  constructor(public conf: ConfigService, public resources: ResourcesService) { }

  /**
   * Init
   */
  ngOnInit() {
    let self = this;
    this.loadedSubscription = this.conf.isLoaded().subscribe(
      doInit => {
        if(doInit) {
          self.init();
        }
      }
    );
    this.pageConfSubscription = this.conf.pageConf().subscribe(
      doPageConf => {
        if(doPageConf) {
          self.pageTitle = doPageConf['title'];
        }
      }
    );
  }
  /**
   * Do init
   */
  init() {
    this.licenseLogoUrl = './assets/images/logoTransparent.png';//environment.prefixFileXHR + Model.getPictureLicense({});
    this.logoTitle = this.resources.getResource('fa.common.appname');
    if(this.conf && this.conf.current_conf && this.conf.current_conf.currentUser) {
      this.pictureProfileUrl = ModelUser.getPictureUser(this.conf.current_conf.currentUser, this.conf.current_conf.currentUser.licenseId);
      this.userTitle = this.conf.current_conf.currentUser.firstname + ' ' + this.conf.current_conf.currentUser.lastname;
    } else {      
      this.pictureProfileUrl = ModelUser.getPictureUser({}, '0');
      this.userTitle = '';
    }
    this.Pages = new Array<{name: string, url: Array<any>, params: any, icon: string}>();
    this.Pages.push({name: 'fa.page.home', url: ['/home'], icon: 'home'});
    this.Pages.push({name: 'fa.page.podcasts', url: ['/home',{kind: 'podcast'} ], icon: 'podcasts'});    
    this.Pages.push({name: 'fa.page.search', url: ['/cab'], icon: 'search'});
    this.Pages.push({name: 'fa.page.profile', url: ['/profile'], icon: 'account_circle'});
    if(this.conf.current_conf.currentUser.roleName == "super_admin") {
      this.Pages.push({name: 'fa.page.admin', url: ['/admin'], icon: 'settings'});
    }
  }
}
