import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModelTool } from './model-tool';

/**
 * ModelProduct
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelProduct {
  /**
   * Get product list url
   */
  static getProductListUrl: string = "/api/product/getList?keyword={0}&offset={1}&limit={2}&sort={3}&order={4}&l_id={5}";
  /**
   * Get product list url
   */
  static getProductByPlaylistUrl: string = "/api/product/getProductByPlaylist?keyword={0}&offset={1}&limit={2}&sort={3}&order={4}&playlist_id={5}&l_id={6}";    
  /**
   * Get product list url
   */
  static getProductUrl: string = "/api/product/getProduct?id={0}";
  /**
   * Picture product url empty
   */
  static pictureProductUrlEmpty: string = '/assets/images/empty_product.jpg';
  /**
   * Picture product url setted
   */
  static pictureProductUrlSetted: string = '/api/product/{0}/getPictureProduct?t={1}&l_id={2}';
  /**
   * Audio product url setted
   */
  static audioProductUrlSetted: string = '/api/product/{0}/getAudioProduct?t={1}&l_id={2}';
  /**
   * Gallery url setted
   */
  static pictureGalleryUrlSetted: string = '/api/product/{0}/getPictureGallery?gallery_id={1}&t={2}&l_id={3}';
  
  /**
   * Create product url
   */
  static createProductrUrl: string = "/api/product/createProduct";
  /**
   * Delete product url
   */
  static deleteProductUrl: string = "/api/product/deleteProduct";
  /**
   * Edit product url
   */
  static editProductUrl: string = "/api/product/editProduct";    
  /**
   * Upload picture product url
   */
  static uploadPictureProductUrl: string = "/api/product/{0}/uploadPictureProduct?l_id={1}";
  /**
   * Upload audio product url
   */
  static uploadAudioProductUrl: string = "/api/product/{0}/uploadAudioProduct?l_id={1}";
  /**
   * Add product to playlist
   */
  static addProductToPlaylistUrl: string = "/api/product/{0}/addProductToPlaylist?playlist_id={1}&l_id={2}";
  /**
   * Remove product from playlist
   */
  static removeProductFromPlaylistUrl: string = "/api/product/{0}/removeProductFromPlaylist?playlist_id={1}&l_id={2}";
  
  /**
   * Add product gallery url
   */
  static addProductGalleryUrl: string = "/api/product/{0}/addProductGallery?l_id={1}";
  
  /**
   * Remove product gallery url
   */
  static removeProductGalleryUrl: string = "/api/product/{0}/removeProductGallery?gallery_id={1}&l_id={2}";
  
  /**
   * Add favorite url
   */
  static addFavoriteUrl: string = "/api/product/{0}/addFavorite";
  
  /**
   * Remove favorite url
   */
  static removeFavoriteUrl: string = "/api/product/{0}/removeFavorite";
  
    /**
     * ctr
     */
    constructor(){

    }
    public static getProductList(http: HttpClient, keyword: string, offset: number, limit: number, sort: string, order: string, license_id: string): Observable<any> {
        return http.get(
            environment.prefixURLXHR + ModelProduct.getProductListUrl
            .replace("{0}", keyword)
            .replace("{1}", offset + '')
            .replace("{2}", limit + '')
            .replace("{3}", sort)
            .replace("{4}", order)
            .replace("{5}", license_id)
        );
    }
    public static getProductByPlaylist(http: HttpClient, keyword: string, offset: number, limit: number, sort: string, order: string, playlist_id: string, license_id: string): Observable<any> {
        return http.get(
            environment.prefixURLXHR + ModelProduct.getProductByPlaylistUrl
            .replace("{0}", keyword)
            .replace("{1}", offset + '')
            .replace("{2}", limit + '')
            .replace("{3}", sort)
            .replace("{4}", order)
            .replace("{5}", playlist_id)            
            .replace("{6}", license_id)
        );
    }    
    public static createProduct(http: HttpClient, data_product: {
        'title': string,
        'summary': string,
        'desc': string,
        'author_id': number,
        'perimeter_id': number,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelProduct.createProductrUrl, data_product);
    }
    public static getProduct(http: HttpClient, id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelProduct.getProductUrl.replace("{0}", id));
    }
    public static getPictureProduct(current_product: any, license_id: string): string {        
        if(current_product && current_product.imageToken && current_product.imageToken.trim().length > 2) {
        return environment.prefixURLXHR + this.pictureProductUrlSetted.replace('{0}', current_product.id).replace('{1}', current_product.lastUpdate).replace("{2}", license_id);
      } else {
        return environment.prefixFileXHR + this.pictureProductUrlEmpty;
      }
    }
    public static getAudioProduct(current_product: any, license_id: string): string {     
        if(current_product && current_product.audioToken && current_product.audioToken.trim().length > 2) {
            return environment.prefixURLXHR + this.audioProductUrlSetted.replace('{0}', current_product.id).replace('{1}', current_product.lastUpdate).replace("{2}", license_id);
        } else {
            return '';
        }
    }
    public static uploadProductPicture(http: HttpClient, id: string, license_id: string, formData: any): Observable<any> {
        let url_upload = ModelProduct.uploadPictureProductUrl.replace('{0}', id).replace('{1}', license_id+'');
        return ModelTool.upload(http, environment.prefixURLXHR + url_upload, formData);
    }
    public static uploadProductAudio(http: HttpClient, id: string, license_id: string, formData: any): Observable<any> {
        let url_upload = ModelProduct.uploadAudioProductUrl.replace('{0}', id).replace('{1}', license_id+'');
        return ModelTool.upload(http, environment.prefixURLXHR + url_upload, formData);
    }
    public static deleteProduct(http: HttpClient, data_product: {
        'id': number,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelProduct.deleteProductUrl, data_product);
    }
    public static editProduct(http: HttpClient, data_product: {
        'id': number,
        'title': string,
        'summary': string,
        'desc': string,
        'author_id': number,
        'perimeter_id': number,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelProduct.editProductUrl, data_product);
    }
    
    public static addProductToPlaylist(http: HttpClient, product_id: string, playlist_id: string, license_id: string ): Observable<any> {
        console.log(environment.prefixURLXHR + ModelProduct.addProductToPlaylistUrl.replace("{0}", product_id).replace("{1}", playlist_id).replace("{2}", license_id));
        return http.get(environment.prefixURLXHR + ModelProduct.addProductToPlaylistUrl.replace("{0}", product_id).replace("{1}", playlist_id).replace("{2}", license_id));
    }
    public static removeProductFromPlaylist(http: HttpClient, product_id: string, playlist_id: string, license_id: string ): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelProduct.removeProductFromPlaylistUrl.replace("{0}", product_id).replace("{1}", playlist_id).replace("{2}", license_id));
    }
    public static getPictureGallery(product_id: string, current_gallery: any, license_id: string): string {     
        if(current_gallery && current_gallery.imageToken && current_gallery.imageToken.trim().length > 2) {
            return environment.prefixURLXHR + this.pictureGalleryUrlSetted.replace('{0}', product_id).replace('{1}', current_gallery.id).replace('{2}', current_gallery.lastUpdate).replace("{3}", license_id);
        } else {
            return '';
        }
    }    
    public static addProductGallery(http: HttpClient, product_id: string, license_id: string, form_data: FormData): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelProduct.addProductGalleryUrl.replace("{0}", product_id).replace("{1}", license_id), form_data);
    }    
    public static removeProductGallery(http: HttpClient, product_id: string, gallery_id: string, license_id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelProduct.removeProductGalleryUrl.replace("{0}", product_id).replace("{1}", gallery_id).replace("{2}", license_id));
    }    
    public static addFavorite(http: HttpClient, product_id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelProduct.addFavoriteUrl.replace("{0}", product_id));
    }    
    public static removeFavorite(http: HttpClient, product_id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelProduct.removeFavoriteUrl.replace("{0}", product_id));
    }
    
}