import { Component, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { page } from '../page';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { Md5 } from 'ts-md5';
import { ModelCommon } from 'src/app/DAL/model-common';
import { ModelUser } from 'src/app/DAL/model-user';
/**
 * Profile
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  host: {'class': 'page'},
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent extends page {
  /**
   * dragclass allow to set a class on main element to change color on drag
   */
  dragclass: string = '';
  /**
   * Picture profile url empty
   */
  pictureProfileUrl: string = '';
  /**
   * Old password
   */
  oldPassword: string = '';
  /**
   * New password
   */
  newPassword: string = '';
  /**
   * Confirm password
   */
  confirmPassword: string = '';
  
  /**
   * View child of file input
   */
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  /**
   * Creates an instance of about component.
   * @param resources Resources Service
   * @param conf Configuration Service
   * @param db Database Service
   * @param route Current route
   * @param router Router Service
   * @param snackBar SnackBar
   */
  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar, public dialog: MatDialog) {
    super(_resources, _conf, _route, _router, _snackBar);
  }

  /**
   * Child init
   */
  async childInit() {
    this.title = this.resources.getResource('fa.page.profile');
    this.pageTitle = this.resources.getResource('fa.page.profile');
    this.conf.setPageConf({
      title: this.title,
      pageTitle: this.pageTitle,
      gotsubheader: true
    });
    this.setPictureProfileUrl();
  }
  /**
   * Set picture profile url
   */
  setPictureProfileUrl() {    
    if(this.conf && this.conf.current_conf && this.conf.current_conf.currentUser) {
      this.pictureProfileUrl = ModelUser.getPictureUser(this.conf.current_conf.currentUser, this.conf.current_conf.currentUser.licenseId);
    } else {      
      this.pictureProfileUrl = ModelUser.getPictureUser({}, '0');
    }
  }
  /**
   * Sign out action
   */
  async signOut() {
    let value = await ModelCommon.signOut(this.conf.http, this.conf.current_conf.currentUser.id).toPromise();
    document.location.reload();
  }

  /**
   * Change password
   */
  async changePassword() {
    let datas: Array<Formdata> = new Array<Formdata>();
    let emptyInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let requireInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*' + this.resources.getResource('fa.common.password.pattern')
    };
    let data = {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword
    };
    datas.push(new Formdata('oldPassword', this.resources.getResource('fa.profile.oldPassword'), data.oldPassword, formdataType.hiddentext, emptyInfo, false));
    datas.push(new Formdata('newPassword', this.resources.getResource('fa.profile.newPassword'), data.newPassword, formdataType.password, requireInfo, false));
    datas.push(new Formdata('confirmPassword', this.resources.getResource('fa.profile.confirmPassword'), data.confirmPassword, formdataType.password, requireInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
        title: this.resources.getResource('fa.profile.changepassword'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.OK'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      if(result.newPassword != result.confirmPassword) {
        this.snackBar.open(this.resources.getResource("fa.component.login.different"),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });        
      } else {
        try{
          const md5 = new Md5();
          const md5_pwd = md5.appendStr(result.oldPassword).end();
          const md5_new = new Md5();
          const md5_newpwd = md5_new.appendStr(result.newPassword).end();
          let value = await ModelUser.changePassword(this.conf.http, this.conf.current_conf.currentUser.id, md5_pwd, md5_newpwd).toPromise();
          
          this.snackBar.open(this.resources.getResource("fa.profile.resetPassword.success"),
            this.resources.getResource('fa.common.OK'),
            {
              duration: 5000,
              panelClass: 'primary'
            });
        } catch(e) {
          this.snackBar.open(this.resources.getResource("fa.profile.forgetpassword.error"),
            this.resources.getResource('fa.common.OK'),
            {
              duration: 5000,
            });
        }
      }
    }
  }

  /**
   * Uploads file
   * @param files 
   */
  async uploadProfilePicture(files: FileList): Promise<void> {
    //prepare post
    var formData = new FormData();
    for(let i =0; i < files.length; i++){
      formData.append("files", files[i], files[i]['name']);
    }

    //after post
    try {
      let ret = await ModelUser.uploadUserPicture(this.conf.http, this.conf.current_conf.currentUser.id,this.conf.current_conf.currentUser.licenseId, formData).toPromise();
      this._conf.current_conf.currentUser = await ModelCommon.loadConfig(this.conf.http, false).toPromise();
      this.setPictureProfileUrl();
    } catch(e) {
      var error_text = 'fa.profile.uploadpicture.error';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
    //reset input file
    if(this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
   * Ondragenters PDF upload zone
   * @param ev 
   */
  ondragenter(ev: DragEvent){
    this.dragclass = 'dragging';
  }

  /**
   * Ondragleaves PDF upload zone
   * @param ev 
   */
  ondragleave(ev: DragEvent){
    this.dragclass = '';
  }

}
