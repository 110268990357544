import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Browser } from './browser';
import { DateManager } from './date-manager';
import { LoaderConfig } from './loader-config';
import { environment } from 'src/environments/environment';
import { ModelCommon } from '../DAL/model-common';


/**
 * ConfigServiceTest
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Loader
   */
  loader: NgxUiLoaderConfig;
  /**
   * If component is loaded
   */
  private _loaded = new Subject<boolean>();
  /**
   * Configuration for page
   */
  private _pageConf = new Subject<any>();
  /**
   * Try load config
   */
  private tryLoadConfig: boolean;
  /**
   * Fail to load conf
   */
  public confFail: boolean;
  /**
   * Data store
   */
  public dataStore = {
    loaded: false
  };
  /**
   * If component is loading
   */
  loading = false;
  /**
   * Current configuration
   */
  current_conf = null;
  /**
   * Current lang
   */
  current_lang: string = 'fr';
  /**
   * Browser
   */
  browser: Browser;
  /**
   * Date manager instance
   */
  date_manager: DateManager;

  /**
   * Creates an instance of config service.
   * @param http Call xhr
   * @param db Call database
   * @param ngxUiLoaderService Loader
   */
  constructor(public http: HttpClient/*, public db: DBService*/, public ngxUiLoaderService: NgxUiLoaderService) {
    this.loader = LoaderConfig.ngxUiLoaderConfig;//this.ngxUiLoaderService.getDefaultConfig();
    this._loaded = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this._pageConf = <BehaviorSubject<any>>new BehaviorSubject(false);
    this.browser = new Browser();
    this.current_conf = {};
    this.confFail = false;
  }

  /**
   * Determines whether loaded is
   * @returns loaded 
   */
  isLoaded(): Observable<boolean> {
    return this._loaded.asObservable();
  }

  /**
   * Page configuration
   * @returns loaded 
   */
  pageConf(): Observable<any> {
    return this._pageConf.asObservable();
  }

  /**
   * Set page conf
   * @param data 
   */
  setPageConf(data: any) {
    this._pageConf.next(data);
  }

  /**
   * Gets config
   * @returns config 
   */
  async getConfig(): Promise<void> {
    if (!this.dataStore.loaded && !this.loading) {
      try {
        this.loading = true;
        this.tryLoadConfig = true;
        await this.loadResources();
        await this.loadConfig();
        this.tryLoadConfig = false;
        if(document.location.href.indexOf('/login') != -1 && !this.confFail) {
          let i_index_refback = document.location.search.indexOf('?href_back=');
          if(i_index_refback != -1) {
            document.location.href = decodeURIComponent(document.location.search.substr(('?href_back=').length, document.location.search.length));
          } else {
            document.location.href = '/#/home';
          }
        }
        if (this.current_conf && this.current_conf.currentUser && this.current_conf.currentUser.language) {
          this.current_lang = this.current_conf.currentUser.language.substr(0, 2).toLowerCase();
        } else {
          this.current_lang = 'fr';
        }
        this.date_manager = new DateManager(this.current_lang);
      } catch (e) {
        if(this.tryLoadConfig) {

        }
        console.log('Cannot load configuration.', e.message);
      }
      this.loading = false;
      this.dataStore.loaded = true;
      this._loaded.next(this.dataStore.loaded);
    } else if (this.dataStore.loaded) {
      this._loaded.next(this.dataStore.loaded);
    }
  }

  /**
   * Load config
   */
  async loadConfig() {
    try{
      if(!this.current_conf.currentUser) {
        this.current_conf.currentUser = {};
      }
      this.current_conf.currentUser = await ModelCommon.loadConfig(this.http, false).toPromise();
    }
    catch(e) {
      this.confFail = true;
      console.log('Cannot load config');
    }
  }

  /**
   * Load resources
   */
  async loadResources() {
    try{
      if(!this.current_conf.resources) {
        this.current_conf.resources = {};
      }
      this.current_conf.resources[this.current_lang] = await ModelCommon.loadResources(this.http, this.current_lang).toPromise();
    }
    catch(e) {
      console.log('Cannot load ressources');
    }
  }

  /**
   * Displays waiter
   */
  displayWaiter() {
    this.ngxUiLoaderService.startLoader(this.ngxUiLoaderService.getLoader().loaderId, 'a');
  }

  /**
   * Hides waiter
   */
  hideWaiter() {
    this.ngxUiLoaderService.stopLoader(this.ngxUiLoaderService.getLoader().loaderId, 'a');
  }
}
