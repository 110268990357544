import { FormControl, FormGroup } from '@angular/forms';

/**
 * Form data
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
/**
 * Form data type
 */
export enum formdataType {
    text = 'text',
    number = 'number',
    textarea = 'textarea',
    date = 'date',
    single = 'single',
    multi = 'multi',
    dropdown = 'dropdown',
    multipledropdown = 'multipledropdown',
    switch = 'switch',
    slider = 'slider',
    image = 'image',
    openchips = 'openchips',
    phone = 'phone',
    mail = 'mail',
    bic = 'bic',
    iban = 'iban',
    numsecu = 'numsecu',
    password = 'password',
    hiddentext = 'hiddentext'
  }

  /*SMARTFIELDS  v2
•	COLLECTE PDF ADHOC : COLLECTE des Smartfields (case à cocher) + COLLECTE des annexes (liste)
•	CASE A COCHER : idée : [x Homme/] [x Femme/] => case à cocher et smartfield remplacée par <case cochée> + texte ou <case non cochée> + texte
•	RADIO : idée similaire : [o Homme/] [o Femme/]. Si plusieurs groupes : [o1 Homme/] [o1 Femme/] [oSexe Homme/] [oSexe Femme/] [o2 Régime général/] [o2 Alsace Moselle/]
PAR MOTS CLES
•	Principe : c'est "smart" => ce n'est pas de la logique stricte : le système se comporte comme s'il comprenait ce qu'on veut faire même si on ne connaît pas le langage
•	Le nom du champ peut commencer par un mot-clé de type 
•	Si le mot clé est en double [date Date de naissance/] on ignore le premier pour le label et le nom du champ
•	DATE : [date ../] si le smartfield commence par "date" => calendrier (mais texte reste éditable si besoin)
•	IBAN : [iban ../] => vérif iban
•	BIC : [bic ../] => vérif bic 8 ou 11 car + ajout XXX si 8
•	TEL : [tel ../]
•	EMAIL : [email ../]
•	PLUGINS  : possibilité de plugger de nouveaux mots-clés avec leur handlers
•	TEXT : pour annuler le typage [text Date et lieu de signature/]
 
PROPERTIES SIGNERS :
•	[sc_sign1.toto/] => ajoute et renseigne une property toto sur signer 1
•	[sc_sign1.x Homme/] => property case à cocher Homme sur signer 1
•	[sc_sign1.oSexe Homme/] => property radio "Sexe" option Homme 
*/
export class Formdata {
    /**
     * Name
     */
    name: string;
    /**
     * Label
     */
    label: string;
    /**
     * Default value
     */
    defaultValue: any;
    /**
     * Type
     */
    type: formdataType;
    /**
     * Disable
     */
    disable: boolean;
    /*datas: Array<{name: string, value: string}>;
    regex: string;
    require: boolean;
    regexError: string;
    requireError: string;*/
    /**
     * Options : 
     * datas => Array<{name: string, value: string}> 
     * invert => slider
     * max(+Text) => slider
     * min(+Text) => slider
     * step => slider
     * tickInterval => slider
     * vertical => slider
     * require(+Text) =>
     * pattern(+Text) =>
     * email(+Text) =>
     * maxLength(+Text) =>
     * minLength(+Text) =>
     * hint => description of field
     * displayConditions => function(data){
        let values_type = ['dropdown', 'single', 'multi', 'multipledropdown'];
        return values_type.indexOf(data.type.value) != -1;
      }
     */
    options: any;
    /**
     * List of error, filled by formcomponent init
     */
    errors: any;
    /**
     * Size  of element in its container
     */
    size: string;

    constructor(
        _name: string,
        _label: string,
        _defaultValue: any,
        _type: formdataType,
        _options: any,
        _disable: boolean ) {
            this.name = _name;
            this.label = _label;
            this.defaultValue = _defaultValue;
            this.type = _type;
            this.options = _options;
            this.errors = [];
            this.disable = _disable;
            this.size = '100';
    }

    static fieldsInError(current_form: FormGroup): Array<string>{        
        let fields_in_error: Array<string> = new Array<string>();
        let i_control: string;
        let c_control;
        for(i_control in current_form.controls) {
            c_control = current_form.controls[i_control];
            if(c_control && c_control.errors) {
                fields_in_error.push(i_control);
            }
        }
        return fields_in_error;
    }

    static validatPattern(c: FormControl, pattern: any, fail: any) {        
        let inValid = null;
        if (!pattern.test(c.value)) {
            inValid = fail;
        }
        return  inValid;
    }

    static validatePassword(c: FormControl) {
        //Minimum eight characters, at least one letter, one number and one special character:
        let value_temp = c.value;
        if(value_temp && value_temp.trim().length > 0 ){
            const PWD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            return Formdata.validatPattern(c, PWD_REGEXP, {password: true});
        }
        return null;
    }

    static validateEmail(c: FormControl) {
        let value_temp = c.value;
        if(value_temp && value_temp.trim().length > 0 ){
            const EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            return Formdata.validatPattern(c, EMAIL_REGEXP, {mail: true});
        }
        return null;
    }

    static validatePhone(c: FormControl) {
        let value_temp = c.value;
        if(value_temp && value_temp.trim().length > 0 ){
            const EMAIL_REGEXP = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
            return Formdata.validatPattern(c, EMAIL_REGEXP, {phone: true});
        }
        return null;
    }

    static validateIBAN(c: FormControl) {
        let ret = null;
        let value_temp = c.value;
        if(value_temp && value_temp.trim().length > 0 ){
            try {
                value_temp = value_temp.toUpperCase().replace(/ /g, '').replace(/-/g, '').replace(/_/g, '');
                if(value_temp.length > 4) {
                    let value_temp_start = value_temp.substr(0,4);
                    let value_temp_end = value_temp.substr(4,value_temp.length - 1);
                    value_temp = value_temp_end + value_temp_start;
                    let i_value = 0;
                    let current_char = '';
                    let value_long = '';
                    for(i_value = 0 ; i_value < (value_temp.length); i_value++) {
                        current_char = value_temp[i_value];
                        if(current_char && current_char.charCodeAt(0) >= 65 && current_char.charCodeAt(0) <= 122) {
                            value_long += (current_char.charCodeAt(0) - 55)+'';
                        } else {
                            value_long += current_char;
                        }
                    }
                    let calculCle=0;
                    let pos=0;
                    while (pos<value_long.length) {
                        calculCle=parseInt(calculCle.toString()+value_long.substr(pos,9),10) % 97;
                        pos+=9;
                    }
                    ret = (calculCle % 97) == 1 ? null : {iban: true};
                } else {
                    ret = {iban: true};
                }

            } catch(exp) {
                console.log('iban problem: '  + exp);
                ret = {iban: true};
            }
        }else{
            ret = null;
        }
        return ret;
    }

    static validateBIC(c: FormControl) {
        let ret = null;
        let value_temp = c.value;
        if(value_temp && value_temp.trim().length > 0 ){
            let regSWIFT = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
            ret = regSWIFT.test(value_temp) ? null : {bic: true};
        }
        return ret;
    }

    static validateNumSecu(c: FormControl) {
        var ret = null;
        var value_temp = c.value;
        if(value_temp && value_temp.trim().length > 0 ){
            value_temp = value_temp.replace(/ /g,'');
            try {
                if(value_temp && value_temp.trim().length == 15) {
                    let genre = value_temp[0];
                    let annee = value_temp[1] + '' + value_temp[2];
                    let mois = value_temp[3] + '' + value_temp[4];
                    let departement_commune = value_temp[5] + '' + value_temp[6] + '' + value_temp[7] + '' + value_temp[8] + '' + value_temp[9];
                    let departement_commune_number = departement_commune.replace('A', '0').replace('a', '0').replace('B', '0').replace('b', '0');
                    let naissance_ordre = value_temp[10] + '' + value_temp[11] + '' + value_temp[12];
                    let check_sum = value_temp[13] + '' + value_temp[14];
                    if(new RegExp(/[123478]/g).test(genre)) {
                        if(new RegExp(/[0-9]{2}/g).test(annee)){
                            if(new RegExp(/0[1-9]|1[0-2]|20/g).test(mois)){
                                let first = genre + '' + annee + '' + mois + '' + departement_commune_number + '' + naissance_ordre + '';
                                let first_int = parseInt(first);
                                ret = parseInt(check_sum) == (97 - first_int % 97) ? null : {numsecu: true};
                            }
                        }
                    }
                } else {
                    ret = {numsecu: true};
                }
            } catch(e) {
                console.log(e);
                ret = {numsecu: true};
            }
        }
        return ret;
    }
}
