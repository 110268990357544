import * as moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/pl';

/**
 * Error Handler
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class DateManager {

  /**
   * Creates an instance of date manager.
   * @param current_lang Current language
   */
  constructor(private current_lang: string) {
  }

  /**
   * Gets full date as dddd DD MMM YYYY hh:mm
   * @param c_date Current date as long UNIX Date
   * @returns full date String date
   */
  getFullDate(c_date: number): string {
    moment.locale(this.current_lang);
    const c_moment = moment(c_date);
    return c_moment.format("dddd DD MMM YYYY hh:mm");
  }
}
