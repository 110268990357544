import { Component, OnInit, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { MatSort, MatDialog, MatSnackBar, MatTableDataSource, Sort } from '@angular/material';
import { ConfigService } from 'src/app/tools/config.service';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { DialogComponent } from 'src/app/controllers/dialog/dialog/dialog.component';
import { ModelCategory } from 'src/app/DAL/model-category';

/**
 * Admin categories
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  host: {'class': 'categories'},
  encapsulation: ViewEncapsulation.None
})
export class CategoriesComponent implements OnInit {
  /**
   * Columns displayable
   */
  displayedColumns: Array<string> = ['id', 'title', 'desc', 'actions'];
  /**
   * Columns displayable alias
   */
  displayedColumnsAlias: any = {'id': 'id', 'title': 'title', 'desc': 'desc'};
  /**
   * Page length
   */
  pageLength: number = 20;
  /**
   * Page index
   */
  pageIndex: number = 0;
  /**
   * Page length
   */
  pageSize: number = 1;
  /**
   * Sort column
   */
  sortColumn = 'id';
  /**
   * Sort Order
   */
  sortOrder = 'asc';
  /**
   * List of categoryList
   */
  categoryList: any;
  /**
   * Current category for edit
   */
  currentCategory: any = null;
  /**
   * Mat sort
   */
  sort: Sort;
  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;

  /**
   * ctr
   * @param resources 
   * @param http 
   * @param dialog 
   * @param snackBar 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  /**
   * On init
   */
  async ngOnInit() {
  }
  /**
   * On change
   * @param change 
   */
  async ngOnChanges(change) {
    if(change.licenseId && change.licenseId.currentValue && change.licenseId.currentValue != '0') {
      await this.loadCategories(this.pageIndex);
    }
  }
  /**
   * Sort data
   */
  sortData(_sort: Sort) {
    this.sort = _sort;
    this.loadCategories(0);
  }
  /**
   * Load categories
   * @param _index 
   */
  async loadCategories(_index: number) {
    if(this.sort && this.sort.direction) {
      this.sortOrder = this.sort.direction;
    }
    if(this.sort && this.sort.active) {
      this.sortColumn = this.sort.active;
    }

    this.pageIndex = _index;
    let category_list = <Array<any>> await ModelCategory.getCategoryList(
      this.http, "", this.pageIndex * this.pageLength, this.pageLength, this.displayedColumnsAlias[this.sortColumn], this.sortOrder, this.licenseId
      ).toPromise();
    this.categoryList = new MatTableDataSource(category_list['list']);
    this.pageSize = category_list['size'];
    this.categoryList.sort = this.sort;
  }
  /**
   * Add user
   */
  async add() {
    let data: {title: string, desc: string} = {title: '', desc: ''};

    let datas: Array<Formdata> = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    datas.push(new Formdata('title', this.resources.getResource('fa.categories.title'), data['title'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('desc', this.resources.getResource('fa.categories.desc'), data['desc'], formdataType.text, requiredInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
        title: this.resources.getResource('fa.categories.actions.add'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      console.log(result);
      //after post
      try {              
        let c_data = {
          'title': result.title,
          'desc': result.desc,
          'license_id': this.licenseId
        };
        let res = await ModelCategory.createCategory(this.conf.http, c_data).toPromise();
        let c_user_a = JSON.parse(res['message']);
        //RELOAD
        await this.loadCategories(this.pageIndex);
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }

  }
  /**
   * Edit category
   * @param c_category
   */
  edit(c_category) {
    this.currentCategory = c_category;
  }
  /**
   * Category Saved
   * @param ev 
   */
  async saved(ev) {
    await this.loadCategories(this.pageIndex);
    for(let c_cat of this.categoryList.data) {
      if(c_cat.id == this.currentCategory.id){
        this.currentCategory = c_cat;
        break;
      }
    }
  }
  /**
   * Delete category
   * @param c_category 
   */
  async delete(c_category) {
    try {      
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
          title: this.resources.getResource('fa.common.information'), 
          text: this.resources.getResource('fa.categories.actions.delete.confirm'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.OK')
          } },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
        await ModelCategory.deleteCategory(this.conf.http, {
          'id': c_category.id,
          'license_id': this.licenseId
        }).toPromise();
        //RELOAD
        await this.loadCategories(this.pageIndex);
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * End edit
   * @param ev 
   */
  endEdit(ev) {
    this.currentCategory = null;
  }
  
  /**
   * Get pages
   * @returns 
   */
  getPages(): number{
    return Math.ceil(this.pageSize/this.pageLength);
  }
  /**
   * Get pagination text
   * @returns 
   */
  getPaginationText(): string{
    return (this.pageIndex + 1) +'/' + this.getPages();
  }
  /**
   * Get next page
   * @returns
   */
  getNextPage(): number{
    return this.pageIndex+1<=this.getPages()-1 ? this.pageIndex+1 : this.getPages()-1; 
  }
  /**
   * Get last page
   * @return
   */
  getLastPage(): number{
    return this.getPages() - 1;
  }
}
