import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModelTool } from './model-tool';

/**
 * ModelUser
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelUser {

  /**
   * Forget password url
   */
  static forgetPasswordUrl: string = "/api/user/forgetPassword?email={0}&is_welcome_pack={1}";
  /**
   * Reset password url
   */
  static resetPasswordUrl: string = "/api/user/resetPassword?token={0}&password={1}";
  /**
   * Change password profile url
   */
  static changepasswordUrl: string = "/api/user/{0}/changePassword?oldPassword={1}&newPassword={2}";
  /**
   * Picture profile url empty
   */
  static pictureProfileUrlEmpty: string = '/assets/images/empty_profile.png';
  /**
   * Picture profile url setted
   */
  static pictureProfileUrlSetted: string = '/api/user/{0}/getPictureProfile?t={1}&l_id={2}';
  /**
   * Upload picture profile url
   */
  static uploadProfilePictureUrl: string = "/api/user/{0}/uploadPictureProfile?l_id={1}";    
  /**
   * CreateUser url
   */
  static createUserUrl: string = "/api/user/createUser";
  /**
   * Get user list url
   */
  static getUserListUrl: string = "/api/user/getList?keyword={0}&offset={1}&limit={2}&sort={3}&order={4}&l_id={5}";   
  /**
   * update User url
   */
  static updateUserUrl: string = "/api/user/updateUser";   
  /**
   * delete User url
   */
  static deleteUserUrl: string = "/api/user/deleteUser";
  /**
   * create User Detail Url
   */
  static createUserDetailUrl: string = "/api/user/createUserDetail?user_id={0}&name={1}&value={2}";
  /**
   * update User Detail Url
   */
  static updateUserDetailUrl: string = "/api/user/updateUserDetail?user_id={0}&id={1}&value={2}";
  /**
   * delete User Detail Url
   */
  static deleteUserDetailUrl: string = "/api/user/deleteUserDetail?user_id={0}&id={1}";
  /**
   * get User Detail List Url
   */
  static getUserDetailListUrl: string = "/api/user/getUserDetailList?user_id={0}";

    /**
     * ctr
     */
    constructor(){

    }
    public static resetPassword(http: HttpClient, token: string, md5_pwd: any ): Observable<any> {
      return http.get(environment.prefixURLXHR + ModelUser.resetPasswordUrl.replace('{0}', token).replace('{1}', md5_pwd+''));
  }
  public static changePassword(http: HttpClient, user_id: string, md5_pwd: any, md5_newpwd: any ): Observable<any> {
      return http.get(environment.prefixURLXHR + ModelUser.changepasswordUrl.replace('{0}', user_id).replace('{1}', md5_pwd+'').replace('{2}', md5_newpwd+''));
  }
  public static forgetPassword(http: HttpClient, email: string, welcome_pack: boolean): Observable<any> {
    return http.get(environment.prefixURLXHR + this.forgetPasswordUrl.replace('{0}', email).replace('{1}', welcome_pack+''));
  }
  public static getPictureUser(current_user: any,license_id: string): string {        
      if(current_user && current_user.imageToken && current_user.imageToken.trim().length > 2) {
          return environment.prefixURLXHR + ModelUser.pictureProfileUrlSetted.replace('{0}', current_user.id).replace('{1}', current_user.lastUpdate).replace('{2}', license_id);
      } else {
          return environment.prefixFileXHR + ModelUser.pictureProfileUrlEmpty;
      }
  }
  public static createUser(http: HttpClient, data_user: {
      'login': string,
      'firstname': string,
      'lastname': string,
      'role': number,
      'perimeters': Array<number>,
      'license_id': string,
      'pwd': string
    }): Observable<any> {
      return http.post(environment.prefixURLXHR + ModelUser.createUserUrl, data_user);
  }
  public static uploadUserPicture(http: HttpClient, id: string, license_id: string, formData: any): Observable<any> {
      let url_upload = ModelUser.uploadProfilePictureUrl.replace('{0}', id).replace('{1}', license_id);        
      return ModelTool.upload(http, environment.prefixURLXHR + url_upload, formData);
  }
  public static getUserList(http: HttpClient, keyword: string, offset: number, limit: number, sort: string, order: string, license_id: string): Observable<any> {
      return http.get(
          environment.prefixURLXHR + ModelUser.getUserListUrl
        .replace("{0}", keyword)
        .replace("{1}", offset + '')
        .replace("{2}", limit + '')
        .replace("{3}", sort)
        .replace("{4}", order)
        .replace("{5}", license_id)
      );
  }
  public static updateUser(http: HttpClient, data_user: {
      'id': number,
      'login': string,
      'firstname': string,
      'lastname': string,
      'role': number,
      'perimeters': Array<number>,
      'license_id': string
    }): Observable<any> {
      return http.post(environment.prefixURLXHR + ModelUser.updateUserUrl, data_user);
  }
  public static deleteUser(http: HttpClient, data_user: {
      'id': number,
      'license_id': string
    }): Observable<any> {
      return http.post(environment.prefixURLXHR + ModelUser.deleteUserUrl, data_user);
  }
  public static createUserDetail(http: HttpClient, id_user: string, name: string, value: string): Observable<any> {
      return http.get(environment.prefixURLXHR + ModelUser.createUserDetailUrl.replace("{0}", id_user).replace("{1}", name).replace("{2}", value));
  }
  public static updateUserDetail(http: HttpClient, id_user: string, id: string, value: string): Observable<any> {
      return http.get(environment.prefixURLXHR + ModelUser.updateUserDetailUrl.replace("{0}", id_user).replace("{1}", id).replace("{2}", value));
  }
  public static deleteUserDetail(http: HttpClient, id_user: string, id: string): Observable<any> {
      return http.get(environment.prefixURLXHR + ModelUser.deleteUserDetailUrl.replace("{0}", id_user).replace("{1}", id));
  }
  public static getUserDetailList(http: HttpClient, id_user: string): Observable<any> {
      return http.get(environment.prefixURLXHR + ModelUser.getUserDetailListUrl.replace("{0}", id_user));
  }
}