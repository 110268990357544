import { Component, ViewEncapsulation  } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import {Md5} from 'ts-md5/dist/md5';
import { page } from '../page';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { environment } from 'src/environments/environment';
import { ModelCommon } from 'src/app/DAL/model-common';
import { ModelUser } from 'src/app/DAL/model-user';
import { ModelLicense } from 'src/app/DAL/model-license';

/**
 * Login
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {'class': 'page'},
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent extends page {
  licenseLogoUrl: string = ModelLicense.logoDefaultSrc;
  email: string = '';//"nicolas.alcina@gmail.com";
  password: string = '';//"po";

  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar, public dialog: MatDialog) {
    super(_resources, _conf, _route, _router, _snackBar);
    this.licenseLogoUrl = environment.prefixFileXHR + ModelLicense.getPictureLicense({});
  }

  async childInit() {
    this.title = this.resources.getResource('fa.page.login');
    this.pageTitle = this.resources.getResource('fa.page.login');
    this.conf.setPageConf({
      title: this.title,
      pageTitle: this.pageTitle,
      gotsubheader: false
    });
  }

  async login() {
    let got_error = false;
    if(this.email && this.email.trim().length > 0 && this.password && this.password.trim().length > 0) {
      const md5 = new Md5();
      const md5_pwd = md5.appendStr(this.password).end();
      const data_Login = {
        Id: -1,
        Login: this.email,
        Pwd: md5_pwd,
        Firstname: '',
        Lastname: '',
        Language: '',
        DefaultLicense: -1,
        UserLicenseRole: []
      };
      let ret_Login; 
      try {
        ret_Login = await ModelCommon.login(this.conf.http, data_Login).toPromise();
        let i_index_refback = document.location.search.indexOf('?href_back=');
        if(environment.mobile) {
          console.log(ret_Login.message);
          window.localStorage.setItem('jwt_token', ret_Login.message);
          this.router.navigateByUrl('/home', {skipLocationChange: true}).then(
            ()=> this.router.navigate(['/#/home'])
            );
        } else {
          var self = this;
          setTimeout(async function() {
            self.conf.dataStore.loaded = false;            
            self.conf.loading = false;
            await self.conf.getConfig();
          }, 200);
        }
      } catch(e) {
        ret_Login = JSON.stringify(e);
        got_error = true;
      }
    } else {
      got_error = true;
    }
    if(got_error) {      
      this.snackBar.open(
        this.resources.getResource('fa.component.login.error'),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 50000,
        });
    }
  }

  async forgetPassword() {
    let datas: Array<Formdata> = new Array<Formdata>();
    let requireInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let data = {
      email: this.email
    };
    datas.push(new Formdata('email', this.resources.getResource('fa.component.login.email'), data.email, formdataType.mail, requireInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
        title: this.resources.getResource('fa.profile.forgetpassword'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.OK'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      try{
        let value = await ModelUser.forgetPassword(this.conf.http, encodeURIComponent(result.email), false).toPromise();
        
        this.snackBar.open(this.resources.getResource("fa.profile.forgetpassword.success"),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
            panelClass: 'primary'
          });
      } catch(e) {
        this.snackBar.open(this.resources.getResource("fa.profile.forgetpassword.error"),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }
  }

}
