import { Component, ViewEncapsulation } from '@angular/core';
import { ConfigService } from 'src/app/tools/config.service';
import { ResourcesService } from 'src/app/tools/resources.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Track } from 'ngx-audio-player';
import { page } from '../page';
import { Subscription } from 'rxjs';
import { ModelPlaylist } from 'src/app/DAL/model-playlist';
import { ModelProduct } from 'src/app/DAL/model-product';

/**
 * Cab list
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-cablist',
  templateUrl: './cablist.component.html',
  styleUrls: ['./cablist.component.scss'],
  host: {'class': 'cablist'},
  encapsulation: ViewEncapsulation.None
})
export class CablistComponent  extends page {
  /**
   * Product id
   */
  id: string;
  /**
   * List of product
   */
  productList: any;
  /**
   * Cab list information
   */
  cabListInformation: any;
  /**
   * Swiper configuration
   */
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };
  /**
   * Player AutoPlay
   */
  autoPlay: boolean = false;
  /**
   * Player Muted
   */
  muted: boolean = false;
  /**
   * Player Volume display
   */
  volumeDisplay: boolean = true;
  /**
   * Player displayTitle
   */
  titleDisplay: boolean = false;
  /**
   * Player Display list
   */
  displayList: boolean = true;
  /**
   * Player Audio size option
   */
  audioSizeOptions: Array<number> = [3];
  /**
   * Player Expanded
   */
  expanded: boolean = true;
  /**
   * Playlist
   */
  playList: Array<Track> = [];
  /**
   * Current HTTPRequest
   */
  currentRequest: Subscription;
  /**
   * Sendinf favorite
   */
  sendingFavorite: boolean = false;

  /**
   * Creates an instance of cablist component.
   * @param resources Resources Service
   * @param conf Configuration Service
   * @param db Database Service
   * @param route Current route
   * @param router Router Service
   * @param snackBar SnackBar
   */
  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public domS: DomSanitizer, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar) {
    super(_resources, _conf, _route, _router, _snackBar);
  }

  /**
   * Child init
   */
  async childInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.title = this.resources.getResource('fa.page.cablist');
    this.pageTitle = this.resources.getResource('fa.page.cablist');
    this.conf.setPageConf({
      title: this.title,
      pageTitle: this.pageTitle,
      gotsubheader: true
    });
    await this.displayProduct("");
  }
  async displayProduct(keyword: string) {
    if(this.currentRequest) {
      this.currentRequest.unsubscribe();
    }
    this.cabListInformation = await ModelPlaylist.getPlaylist(this.conf.http, this.id).toPromise();
    this.currentRequest = ModelProduct.getProductByPlaylist(this._conf.http, keyword, 0, 100, 'id', 'desc', this.id, "0").subscribe((res)=> {
        this.productList = res;
        this.playList = new Array<Track>();
        let i_track = 0;
        for(let c_product of this.productList.list) {
          let c_track: Track = new Track();
          c_track.title = c_product.title + ' - ' + c_product.summary;
          c_track.link = ModelProduct.getAudioProduct(c_product, '0');
          c_track.index = i_track;          
          this.playList.push(c_track);
          i_track++;
        }
      });
  }
  /**
   * Picture product url
   */
  pictureSrc(c_product) {
    return ModelProduct.getPictureProduct(c_product, '0');
  }
  /**
   * Get Style
   * @param c_product Style of image
   */
  getStyle(c_product) {
    return {
      'background-size': 'cover', 
      'background-image': "url('"+this.pictureSrc(c_product) + "')"
    };
  }
  /**
   * Toggle favorite
   * @param c_product 
   */
  async toggleFavorite(c_product) {
    try{
      if(!this.sendingFavorite) {
        this.sendingFavorite = true;
        if(c_product.favorite) {
          await ModelProduct.removeFavorite(this._conf.http, c_product.id).toPromise();
          c_product.favorite = false;
        } else {
          await ModelProduct.addFavorite(this._conf.http, c_product.id).toPromise();
          c_product.favorite = true;
        }
        this.sendingFavorite = false;
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Go to detail
   * @param c_product 
   */
  goDetail(c_product) {
    this.router.navigate(['/cab/' + c_product.id, { cb_id: this.id}]);
  }
  /**
   * On index change
   */
  onIndexChange(e) {

  }
}
