import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * ModelCommon
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelCommon {
  /**
   * Config url
   */
  static configUrl: string = '/api/conf';
  /**
   * Login url
   */
  static loginUrl: string = "/api/auth";
  /**
   * Sign out url
   */
  static signoutUrl: string = "/api/auth/signout";
  /**
   * Notification url
   */
  static notificationUrl: string = "/api/fcmnotifications/sendNotifications";
  /**
   * Resource url
   */
  static resourceUrl = '/assets/i18n/{0}.json';

    /**
     * ctr
     */
    constructor(){

    }
    public static loadConfig(http: HttpClient, need_credential: boolean): Observable<any> {
      if(!need_credential) {
          return http.get(environment.prefixURLXHR + ModelCommon.configUrl);
      } else {
          return http.post(environment.prefixURLXHR + ModelCommon.configUrl , {}, { withCredentials: true });
      }       
    }
    public static loadResources(http: HttpClient, current_lang: string ): Observable<any> {
        return http.get(environment.prefixFileXHR + ModelCommon.resourceUrl.replace('{0}', current_lang));
    }    
    public static signOut(http: HttpClient, user_id: string): Observable<any> {
      return http.get(environment.prefixURLXHR + ModelCommon.signoutUrl.replace('{0}', user_id));
    }
    public static login(http: HttpClient, data_Login: 
        { Id: number, Login: string, Pwd: any, Firstname: string, Lastname: string, Language: string, DefaultLicense: number, UserLicenseRole: Array<any> }
        ): Observable<any> {
      return http.post(environment.prefixURLXHR + ModelCommon.loginUrl, data_Login);
    }
    public static sendNotification(http: HttpClient, data_notification: {
      'title': string,
      'body': string,
      'product_id': number,
      'playlist_id': number,
      'perimeter_id': number,
      'license_id': number
    }
        ): Observable<any> {
      return http.post(environment.prefixURLXHR + ModelCommon.notificationUrl, data_notification);
    }
}