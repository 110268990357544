import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * ModelPerimeter
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelPerimeter {
  /**
   * Get perimeter list url
   */
  static getPerimeterListUrl: string = "/api/perimeter/perimeterForAdmin?license_id={0}";
  /**
   * Create perimeter url
   */
  static createPerimeterUrl: string = "/api/perimeter/createPerimeter?name={0}&perimeter_id={1}&license_id={2}";
  /**
   * Edit perimeter url
   */
  static editPerimeterUrl: string = "/api/perimeter/editPerimeter?name={0}&id={1}&license_id={2}";    
  /**
   * Delete perimeter url
   */
  static deletePerimeterUrl: string = "/api/perimeter/deletePerimeter?id={0}&license_id={1}";
    /**
     * ctr
     */
    constructor(){

    }
    public static getPerimeterList(http: HttpClient, license_id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelPerimeter.getPerimeterListUrl.replace("{0}", license_id));
    }
    public static createPerimeter(http: HttpClient, name: string, parent_id: string, license_id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelPerimeter.createPerimeterUrl.replace("{0}", name).replace("{1}", parent_id).replace("{2}", license_id));
    }
    public static editPerimeter(http: HttpClient, name: string, parent_id: string, license_id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelPerimeter.editPerimeterUrl.replace("{0}", name).replace("{1}", parent_id).replace("{2}", license_id));
    }
    public static deletePerimeter(http: HttpClient, perimeter_id: string, license_id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelPerimeter.deletePerimeterUrl.replace("{0}", perimeter_id).replace("{1}", license_id));
    }
}