import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModelTool } from './model-tool';

/**
 * Kind of playlist
 */
export enum kind{
  REGULAR='regular',
  PODCAST='podcast'
};
/**
 * ModelPlaylist
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelPlaylist {
  /**
   * Get playlist list by category url
   */
  static getListByCategoryUrl: string = "/api/playlist/getListByCategory?category_id={0}&kind={1}&l_id={2}";
  /**
   * Get playlist list url
   */
  static getPlaylistListUrl: string = "/api/playlist/getList?keyword={0}&offset={1}&limit={2}&sort={3}&order={4}&l_id={5}";
  /**
   * Picture Playlist url empty
   */
  static picturePlaylistUrlEmpty: string = '/assets/images/empty_product.jpg';
  /**
   * Picture Playlist url setted
   */
  static picturePlaylistUrlSetted: string = '/api/playlist/{0}/getPicturePlaylist?t={1}&l_id={2}';
  /**
   * Create Playlist url
   */
  static createPlaylistUrl: string = "/api/playlist/createPlaylist";
  /**
   * Edit Playlist url
   */
  static editPlaylistUrl: string = "/api/playlist/editPlaylist";
  /**
   * Get Playlist url
   */
  static getPlaylistUrl: string = "/api/playlist/getPlaylist?id={0}";
  /**
   * Upload picture Playlist url
   */
  static uploadPicturePlaylistUrl: string = "/api/playlist/{0}/uploadPicturePlaylist?l_id={1}";
  /**
   * Deactivate playlist
   */
  static deactivatePlaylistUrl: string = "/api/playlist/deactivatePlaylist";   
  /**
   * Delete Playlist url
   */
  static deletePlaylistUrl: string = "/api/playlist/deletePlaylist";   
    /**
     * ctr
     */
    constructor(){

    }
    public static getPlaylistByCategory(http: HttpClient, category_id: string, kind: string, license_id: string): Observable<any> {
        return http.get(
            environment.prefixURLXHR + ModelPlaylist.getListByCategoryUrl
            .replace("{0}", category_id)
            .replace("{1}", kind)
            .replace("{2}", license_id)
        );
    }
    public static getPlaylistList(http: HttpClient, keyword: string, offset: number, limit: number, sort: string, order: string, license_id: string): Observable<any> {
        return http.get(
            environment.prefixURLXHR + ModelPlaylist.getPlaylistListUrl
            .replace("{0}", keyword)
            .replace("{1}", offset + '')
            .replace("{2}", limit + '')
            .replace("{3}", sort)
            .replace("{4}", order)
            .replace("{5}", license_id)
        );
    }
    public static createPlaylist(http: HttpClient, data_playlist: {
        'title': string,
        'desc': string,
        'kind': string,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelPlaylist.createPlaylistUrl, data_playlist);
    }
    public static editPlaylist(http: HttpClient, data_playlist: {
        'id': number,
        'title': string,
        'desc': string,
        'kind': string,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelPlaylist.editPlaylistUrl, data_playlist);
    }
    public static getPlaylist(http: HttpClient, id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelPlaylist.getPlaylistUrl.replace("{0}", id));
    }
    public static getPicturePlaylist(current_playlist: any, license_id: string): string {        
        if(current_playlist && current_playlist.imageToken && current_playlist.imageToken.trim().length > 2) {
        return environment.prefixURLXHR + this.picturePlaylistUrlSetted.replace('{0}', current_playlist.id).replace('{1}', current_playlist.lastUpdate).replace("{2}", license_id);
      } else {
        return environment.prefixFileXHR + this.picturePlaylistUrlEmpty;
      }
    }
    public static uploadPlaylistPicture(http: HttpClient, id: string, license_id: string, formData: any): Observable<any> {
        let url_upload = ModelPlaylist.uploadPicturePlaylistUrl.replace('{0}', id).replace('{1}', license_id+'');
        return ModelTool.upload(http, environment.prefixURLXHR + url_upload, formData);
    }
    public static deactivatePlaylist(http: HttpClient, data_playlist: {
        'id': number,
        'notactive': boolean,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelPlaylist.deactivatePlaylistUrl, data_playlist);
    }
    public static deletePlaylist(http: HttpClient, data_playlist: {
        'id': number,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelPlaylist.deletePlaylistUrl, data_playlist);
    }
    
}