import { Component, OnInit, Input, ViewChild, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { ConfigService } from 'src/app/tools/config.service';
import { MatSnackBar } from '@angular/material';
import { ResourcesService } from 'src/app/tools/resources.service';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { orientationType, actionMode } from 'src/app/controllers/iconbutton/iconbutton.component';
import { FormComponent } from 'src/app/controllers/form/form.component';
import { ModelLicense } from 'src/app/DAL/model-license';
import { ModelCategory } from 'src/app/DAL/model-category';

/**
 * Admin license
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss'],
  host: {'class': 'informations'},
  encapsulation: ViewEncapsulation.None
})
export class InformationsComponent implements OnInit {
  /**
   * Sync key
   */
  SYNC_KEY: string = 'coacheer.sync';
  /**
   * Updating details
   */
  updating: boolean = false;
  /**
   * License informations Form name
   */
  licenseInformationsFormName: string = "license_informations_form";
  /**
   * List of form data
   */
  licenseInformationsDatas: Array<Formdata>;
  /**
   * Sync
   */
  sync: boolean;
  /**
   * Sync Id
   */
  syncId: number;

  /**
   * License id
   */
  @Input() currentLicense: any;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;
  /**
   * 
   */
  @Output() updated: EventEmitter<any> = new EventEmitter();

  /**
   * View child of the form
   */
  @ViewChild('license_informations_form', {static: false}) licenseInformationsForm: FormComponent;

  constructor(public resources: ResourcesService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.prepareFormLicenseDetails();
    this.checkDetails();
  }
  /**
   * Logo url
   * @param c_lic 
   */
  logoSrc() {
    return ModelLicense.getPictureLicense(this.currentLicense);
  }
  /**
   * CheckDetail
   */
  checkDetails() {
    this.sync = false;
    this.syncId = 0;
    for(let c_detail of this.currentLicense.licenseDetail) {
      if(c_detail.name == this.SYNC_KEY) {
        this.syncId = c_detail.id;
        if(c_detail.value == 'true') {
          this.sync = true;
        }
      }
    }

  }

  /**
   * Prepare form license detail
   */
  prepareFormLicenseDetails() {

    this.licenseInformationsDatas = new Array<Formdata>();
    let requireInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let alphaNumInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*',
      pattern: /^\w+$/,
      patternText: 'fa.licenses.name.pattern'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.licenses.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    this.licenseInformationsDatas.push(new Formdata('licenseImage', this.resources.getResource('fa.licenses.picture'), this.logoSrc(), formdataType.image, pictureInfo, false));
    this.licenseInformationsDatas.push(new Formdata('licenseName', this.resources.getResource('fa.licenses.name'), this.currentLicense.name, formdataType.text, alphaNumInfo, true));
    this.licenseInformationsDatas.push(new Formdata('licenseExpiration', this.resources.getResource('fa.licenses.expiration'), new Date(this.currentLicense.validTime), formdataType.date, requireInfo, false));
  }
  /**
   * Sync changed
   */
  async syncChanged() {
    let temp_sync: boolean = this.sync;
    this.checkDetails();
    if(!this.updating) {
      this.updating = true;
      if(temp_sync) {
        if(this.syncId > 0){
          await ModelLicense.updateLicenseDetail(this.conf.http, this.currentLicense.id, this.syncId +'', 'true').toPromise();
        } else {
          await ModelLicense.createLicenseDetail(this.conf.http, this.currentLicense.id, this.SYNC_KEY, 'true').toPromise();
        }
      } else if(this.syncId > 0){
        await ModelLicense.updateLicenseDetail(this.conf.http, this.currentLicense.id, this.syncId +'', 'false').toPromise();
      }
      this.updated.emit(true);
      this.sync = temp_sync;
      this.updating = false;
    }
  }
  /**
   * licenseDetailsValid
   */
  licenseInformationsValid(): boolean {
    return this.licenseInformationsForm && this.licenseInformationsForm.isValid();
  }
  /**
   * post License detail
   */
  async postLicenseDetails(): Promise<void> {
    //after post
    try {
      if(this.licenseInformationsValid()) {
        let doRefresh = false;
        if(this.licenseInformationsForm.currentForm.value.licenseExpiration.getTime() != this.currentLicense.validTime) {
          await ModelLicense.updateLicense(this.conf.http, this.currentLicense.id, this.licenseInformationsForm.currentForm.value.licenseExpiration.getTime()).toPromise();
          doRefresh = true;
        }

        let formData = this.licenseInformationsForm.pictures['licenseImage'];
        if(formData != this.logoSrc()) {
          let ret = await ModelLicense.uploadLicensePicture(this.conf.http, this.currentLicense.id, formData).toPromise();
            doRefresh = true;
        }
        if(doRefresh) {          
          this.currentLicense = await ModelLicense.getLicense(this.conf.http, this.currentLicense.id).toPromise();
        }
      }
    } catch(e) {
      var error_text = 'fa.licenses.picture.error';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }

}
