import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './pages/admin/admin.component';
import { CabComponent } from './pages/cab/cab.component';
import { HomeComponent } from './pages/home/home.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { LicensesComponent } from './pages/admin/licenses/licenses.component';
import { LicenseComponent } from './pages/admin/license/license.component';
import { CabDetailComponent } from './pages/cab/cab-detail/cab-detail.component';
import { CablistComponent } from './pages/cablist/cablist.component';

/**
 * Routes
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
const routes: Routes = [
  { path: 'admin', component: AdminComponent },
  { path: 'admin/licenses', component: LicensesComponent },
  { path: 'admin/license/:l_id', component: LicenseComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'cab', component: CabComponent },
  { path: 'cab/:id', component: CabDetailComponent },
  { path: 'cablist/:id', component: CablistComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'pagenotfound', component: PagenotfoundComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: '404', component: PagenotfoundComponent },
  { path: '', redirectTo: '/#/home', pathMatch: 'full' },
  { path: '**', component: PagenotfoundComponent}];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { enableTracing: false, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
