import { Component, ViewEncapsulation } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { page } from '../page';

/**
 * Page not found
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss'],
  host: {'class': 'page'},
  encapsulation: ViewEncapsulation.None
})
export class PagenotfoundComponent extends page {
  /**
   * Creates an instance of about component.
   * @param resources Resources Service
   * @param conf Configuration Service
   * @param db Database Service
   * @param route Current route
   * @param router Router Service
   * @param snackBar SnackBar
   */
  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar) {
    super(_resources, _conf, _route, _router, _snackBar);
  }

  /**
   * Child init
   */
  async childInit() {
    this.title = this.resources.getResource('fa.page.404');
    this.pageTitle = this.resources.getResource('fa.page.404');
    this.conf.setPageConf({
      title: this.title,
      pageTitle: this.pageTitle,
      gotsubheader: true
    });
  }

}
