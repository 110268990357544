import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { ISubscription } from 'rxjs-compat/Subscription';
import { ModelLicense } from 'src/app/DAL/model-license';

/**
 * Header
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  /**
   * License logo
   */
  licenseLogoUrl: string = ModelLicense.logoDefaultSrc;
  /**
   * Page configuration subscription
   */
  protected pageConfSubscription: ISubscription;
  /**
   * Title
   */
  title: string = '';
  /**
   * Page title
   */
  pageTitle: string = '';
  /**
   * Got sub header
   */
  gotsubheader: boolean = false;
  /**
   * Action fired
   */
  @Output() menu: EventEmitter<any> = new EventEmitter();
  //@Input() Pages: Array<{name: string, url: Array<string>}>;

  /**
   * Constructor
   * @param conf 
   * @param resources 
   */
  constructor(public conf: ConfigService, public resources: ResourcesService) {
  }
  /**
   * Init
   */
  ngOnInit() {
    let self = this;
    this.pageConfSubscription = this.conf.pageConf().subscribe(
      pageConf => {
        if(pageConf) {
          self.title = pageConf.title;
          self.pageTitle = pageConf.pageTitle;
          self.gotsubheader = pageConf.gotsubheader;
          self.licenseLogoUrl = './assets/images/logoTransparent.png';//environment.prefixFileXHR + Model.getPictureLicense({});
        }
      }
    );
    
  }
  /**
   * On changes
   * @param changes 
   */  
  ngOnChanges(changes: SimpleChanges): void {
  }
  /**
   * Click on logo
   * @param ev event
   */
  logoClick(ev) {
    this.menu.emit();
  }
}

/*

    <div class="nav"
        fxLayout="row wrap"
        fxLayoutAlign="flex-end"
        fxFlex="80">
        
        <button mat-button color="{{Title == resources.getResource(c_page.name) ? 'accent' : 'warn'}}" *ngFor="let c_page of Pages"  [routerLink]="c_page.url" title="{{resources.getResource(c_page.name)}}">
                {{resources.getResource(c_page.name)}}
        </button> 
    </div>

*/
