import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { ResourcesService } from 'src/app/tools/resources.service';

/**
 * Display Mode
 */
export enum Mode {
  OBJECT = 'OBJECT'
}
/**
 * Multi Autocomplete Chips
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-multi-autocomplete-chips',
  templateUrl: './multi-autocomplete-chips.component.html',
  styleUrls: ['./multi-autocomplete-chips.component.scss']
})
export class MultiAutocompleteChipsComponent implements OnInit, OnChanges {
  /**
   * Separator keys codes
   */
  separatorKeysCodes: number[] = [ENTER, COMMA];
  /**
   * Chip ctrl
   */
  ChipCtrl = new FormControl();
  /**
   * Filtered element
   */
  filteredElement: Array<any>;
  /**
   * AutoCompleting
   */
  autoCompleting: boolean;
  /**
   * Selectable
   */
  selectable: any;
  /**
   * Element list from chips
   */
  @Input() currentMode: Mode = Mode.OBJECT;
  /**
   * Element list from chips
   */
  @Input() elementChipList: Array<any> = [];
  /**
   * Element completion list
   */
  @Input() elementList: Array<any> = [];
  /**
   * Placeholder
   */
  @Input() placehoder: string = '';
  /**
   * Element name
   */
  @Input() elementName: string = '';
  /**
   * Action fired
   */
  @Output() loadInformation: EventEmitter<any> = new EventEmitter();
  /**
   * Action fired
   */
  @Output() loadElements: EventEmitter<any> = new EventEmitter();
  /**
   * Element Input
   */
  @ViewChild('elementInput', {static: false}) elementInput: ElementRef;

  /**
   * Creates an instance of multi autocomplete chips component.
   * @param resources 
   */
  constructor(public resources: ResourcesService) { }

  /**
   * on init
   */
  async ngOnInit() {
    this.filteredElement = await this.ChipCtrl.valueChanges.pipe(
      startWith(null),
      map(async (element: string | null) => this.filterElement(element))).toPromise();
  }
  /**
   * on changes
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    
  }
  /**
   * Filters element
   * @param value 
   * @returns element 
   */
  private async filterElement(value: string): Promise<any[]> {
    //in case of select it call this function with the object ContractDefinition so escape
    if(!this.autoCompleting) {
      this.autoCompleting = true;
      if(value && !value['http']) {        
        const filterValue = value.toLowerCase();
        this.elementList = [];
        this.loadElements.emit({value: filterValue});
      } else {
        this.elementList = [];
        this.loadElements.emit({value: ''});
      }
      this.autoCompleting = false;
    }
    return this.elementList;
  }
  /**
   * Adds element
   * @param event 
   */
  addElement(event: MatChipInputEvent): void {
    const input = event.input;    

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.ChipCtrl.setValue(null);
  }
  /**
   * Removes Element
   * @param fruit 
   */
  async removeElement(c_element: any): Promise<void> {
    const index = this.elementChipList.indexOf(c_element);
    if (index >= 0) {
      this.elementChipList.splice(index, 1);
    }    
    await this.loadInformation.emit({value: ''});
  }
  /**
   * Selected element
   * @param event 
   */
  async selectedElement(event: MatAutocompleteSelectedEvent): Promise<void> {
    this.elementChipList.push(event.option.value);
    this.elementInput.nativeElement.value = '';
    this.ChipCtrl.setValue(null);
    await this.loadInformation.emit({value: ''});
  }
  addOnBlur() {

  }
}
