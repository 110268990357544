import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild, Input } from '@angular/core';

export enum orientationType {
  vertical = 'vertical',
  horizontal = 'horizontal',
}
export enum actionMode {
  button = 'button',
  upload = 'upload',
}

/**
 * Icon button
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-iconbutton',
  templateUrl: './iconbutton.component.html',
  styleUrls: ['./iconbutton.component.scss']
})
export class IconbuttonComponent implements OnInit {
  /**
   * dragclass allow to set a class on main element to change color on drag
   */
  dragclass: string = '';
  /**
   * Upload param
   */
  @Input() uploadParam: any = null;
  /**
   * font-size of the icon
   */
  @Input() fontSizeIcon: string = '23px';
  /**
   * Name of the icon
   */
  @Input() iconName: string = 'add';
  /**
   * Orientation of the component
   */
  @Input() orientation: orientationType = orientationType.horizontal;
  /**
   * Action mode of the component
   */
  @Input() actionmode: actionMode = actionMode.button;
  /**
   * Title of the icon
   */
  @Input() iconTitle: string ='';
  /**
   * Main text
   */
  @Input() text: string ='';
  /**
   * Sub text
   */
  @Input() subText: string ='';
  /**
   * Action fired
   */
  @Output() action: EventEmitter<any> = new EventEmitter();
  /**
   * Action fired
   */
  @Output() actionWithParam: EventEmitter<any> = new EventEmitter();
  /**
   * View child of file input
   */
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  
  constructor() { }

  ngOnInit() {
  }

  /**
   * Uploads file
   * @param files 
   */
  async uploadFile(files: FileList): Promise<void> {
    this.action.emit(files);
    this.actionWithParam.emit({files: files, uploadParam: this.uploadParam});
    //reset input file
    if(this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  async clickButton() {
    if(this.actionmode == actionMode.button) {
      this.action.emit(); 
    }
  }

  /**
   * Ondragenters PDF upload zone
   * @param ev 
   */
  ondragenter(ev: DragEvent){
    this.dragclass = 'dragging';
  }

  /**
   * Ondragleaves PDF upload zone
   * @param ev 
   */
  ondragleave(ev: DragEvent){
    this.dragclass = '';
  }

}
