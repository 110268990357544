import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { ConfigService } from 'src/app/tools/config.service';
import { FormComponent } from 'src/app/controllers/form/form.component';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatSnackBar } from '@angular/material';
import { orientationType, actionMode } from 'src/app/controllers/iconbutton/iconbutton.component';
import { ModelPerimeter } from 'src/app/DAL/model-perimeter';
import { ModelProduct } from 'src/app/DAL/model-product';
import { ModelAuthor } from 'src/app/DAL/model-author';
import { DialogComponent } from 'src/app/controllers/dialog/dialog/dialog.component';
import { ModelTag } from 'src/app/DAL/model-tag';
import { ModelCategory } from 'src/app/DAL/model-category';

/**
 * Admin product
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  host: {'class': 'product'},
  encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit {
  /**
   * Form name
   */
  formName: string;
  /**
   * List of form data  of dialog component
   */
  datas: Array<Formdata>;
  /**
   * Categories
   */
  categories: any;
  /**
   * Tag in categories
   */
  tagCategories: any;
  /**
   * my tags
   */
  myTags: any;

  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;
  /**
   * current product id
   */
  @Input() currentProduct: any;
  /**
   * Action fired
   */
  @Output() endEdit: EventEmitter<any> = new EventEmitter();
  /**
   * Action fired
   */
  @Output() saved: EventEmitter<any> = new EventEmitter();
  /**
   * View child of the form
   */
  @ViewChild('c_form', {static: false}) c_form: FormComponent;

  /**
   * 
   * @param resources 
   * @param http 
   * @param dialog 
   * @param snackBar 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  async ngOnInit() {
    await this.getProductInfo();
    let data: {title: string, summary: string, desc: string, author_id: number, perimeter: number, picture: string, audio: string} = 
      {title: this.currentProduct.title, summary: this.currentProduct.summary, desc: this.currentProduct.desc, author_id: this.currentProduct.authorId, perimeter: this.currentProduct.perimeterId, picture: this.pictureProductSrc(this.currentProduct), audio: this.audioProductSrc(this.currentProduct)};
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let perimeter_list = <Array<any>> await ModelPerimeter.getPerimeterList(this.conf.http, this.licenseId).toPromise(); 
    let perimeter_data = [];
    for(let i_perimeter of perimeter_list) {
      perimeter_data.push({
        'value': i_perimeter.id,
        'name': i_perimeter.name
      });
    }
    let perimeterInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: perimeter_data,
      hint: '*'
    };
    let author_list = await ModelAuthor.getAuthorList(this.conf.http, '', 0, 1000, 'id', 'asc', this.licenseId).toPromise(); 
    let author_data = [];    
    if(author_list && author_list.list && author_list.list.length > 0) {
      for(let i_author of author_list.list) {
        author_data.push({
          'value': i_author.id,
          'name': i_author.firstname + " " + i_author.lastname
        });
      }
    }
    let authorInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: author_data,
      hint: '*'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.products.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    let audioInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.products.audio.title'),
      iconName: 'audiotrack',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload,
      hide: true
    };
    //desc.size = '45';
    this.datas = new Array<Formdata>();
    this.datas.push(new Formdata('Image', this.resources.getResource('fa.products.picture'), data['picture'], formdataType.image, pictureInfo, false));
    this.datas.push(new Formdata('title', this.resources.getResource('fa.products.title'), data['title'], formdataType.text, requiredInfo, false));
    this.datas.push(new Formdata('summary', this.resources.getResource('fa.products.summary'), data['summary'], formdataType.text, requiredInfo, false));
    this.datas.push(new Formdata('desc', this.resources.getResource('fa.products.desc'), data['desc'], formdataType.textarea, requiredInfo, false));
    this.datas.push(new Formdata('author_id', this.resources.getResource('fa.products.author'), data['author_id'], formdataType.dropdown, authorInfo, false));
    this.datas.push(new Formdata('perimeter', this.resources.getResource('fa.products.perimeter'), data['perimeter'], formdataType.dropdown, perimeterInfo, false));
    this.datas.push(new Formdata('Audio', this.resources.getResource('fa.products.audio'), data['audio'], formdataType.image, audioInfo, false));
    
    await this.loadTags();
  }
  async getProductInfo() {
    let c_product =  await ModelProduct.getProduct(this.conf.http, this.currentProduct.id).toPromise();
    this.currentProduct.galleries = c_product.galleries;    
  }
  /**
   * Picture product url
   */
  pictureProductSrc(c_product) {
    return ModelProduct.getPictureProduct(c_product, this.licenseId);
  }
  /**
   * Audio product url
   */
  audioProductSrc(c_product) {
    return ModelProduct.getAudioProduct(c_product, this.licenseId);
  }
  /**
   * Picture gallery url
  */
  pictureGallerySrc(c_gal) {
    return ModelProduct.getPictureGallery(this.currentProduct.id, c_gal, this.licenseId);
  }
  /**
   * Save playlist
   */
  async save() {
    if(this.c_form.isValid()) {
      try{
        await ModelProduct.editProduct(this.http, {
          'id': this.currentProduct.id,
          'title': this.c_form.currentForm.value.title,
          'summary': this.c_form.currentForm.value.summary,
          'desc': this.c_form.currentForm.value.desc,
          'author_id': this.c_form.currentForm.value.author_id,
          'perimeter_id': this.c_form.currentForm.value.perimeter,
          'license_id': this.licenseId
        }).toPromise();
        let form_data_picture = this.c_form.pictures['Image'];
        if(form_data_picture != this.pictureProductSrc(this.currentProduct)) {
          let ret = await ModelProduct.uploadProductPicture(this.conf.http, this.currentProduct.id, this.licenseId, form_data_picture).toPromise();
        }   
        let form_data_audio = this.c_form.pictures['Audio'];
        if(form_data_audio != this.audioProductSrc(this.currentProduct)) {
          let ret = await ModelProduct.uploadProductAudio(this.conf.http, this.currentProduct.id, this.licenseId, form_data_audio).toPromise();
        }        
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
      this.saved.emit();
    }
  }
  /**
   * get Gallery orientation
   */
  getGalleryOrientation(): orientationType {
    return orientationType.vertical;
  }
  /**
   * get Gallery action mode
   */
  getGalleryActionMode(): actionMode {
    return actionMode.upload;
  }
  /**
   * Uploads file gallery
   * @param files 
   * @returns file 
   */
  async uploadFileGallery(data: any): Promise<void> {
    //prepare post
    try{
      let formData: FormData = new FormData();
      for(let i =0; i < data.files.length; i++){    
        let mimeType = data.files[0].type;
        if (mimeType.match(/image\/*/) != null) {
          formData.append("files", data.files[i], data.files[i]['name']);
        }
      }
      let ret = await ModelProduct.addProductGallery(this.conf.http, this.currentProduct.id, this.licenseId, formData).toPromise();
      if(ret && ret.message) {
        this.snackBar.open(this.resources.getResource(ret.message),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
      await this.getProductInfo();
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Remove gallery
   */
  async removeGallery(c_gal) {
    try{
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
          title: this.resources.getResource('fa.common.information'), 
          text: this.resources.getResource('fa.product.gallery.remove.confirm'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.OK')
          } },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
        await ModelProduct.removeProductGallery(this.conf.http, this.currentProduct.id, c_gal.id, this.licenseId).toPromise();
        await this.getProductInfo();
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Load tags
   * @param _index 
   */
  async loadTags() {
    try{
      this.categories = <Array<any>> await ModelCategory.getCategoryList(this.http, '%', 0, 10000, 'id', 'desc', this.licenseId).toPromise();
      let tags = null;
      this.tagCategories = {};
      for(let c_cat of this.categories.list) {
        tags = <Array<any>> await ModelTag.getTagList(this.http, '%', 0, 1000, 'id', 'desc', c_cat.id, this.licenseId).toPromise();
        this.tagCategories[c_cat.id] = tags;
      }
      let my_tags = <Array<any>> await ModelTag.getTagForProduct(this.http, this.currentProduct.id, this.licenseId).toPromise();
      this.myTags = {};
      for(let c_my_tags of my_tags) {
        this.myTags[c_my_tags.id] = true;
      }
      
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }

  /**
   * Tag is in product
   * @param tag_id 
   */
  tagIsInProduct(tag_id: number) {
    if(this.myTags) {
      return this.myTags[tag_id];
    }
    return false;
  }

  /**
   * chips for tag is clicked
   * @param c_tag 
   */
  async chipClicked(c_tag) {
    try{
      if(this.tagIsInProduct(c_tag.id)) {
        await ModelTag.removeTagFromProduct(this.http, c_tag.id, this.currentProduct.id, this.licenseId).toPromise();
      } else {
        await ModelTag.addTagToProduct(this.http, c_tag.id, this.currentProduct.id, this.licenseId).toPromise();
      }
      await this.loadTags();
      
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }

  /**
   * Back to products
   */
  back() {
    this.endEdit.emit(true);
  }

}
