import { NgxUiLoaderConfig } from 'ngx-ui-loader';

/**
 * LoaderConfig
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class LoaderConfig {
  /**
   * Ngx ui loader config
   */
  static ngxUiLoaderConfig: NgxUiLoaderConfig = {
    "bgsColor": "#ff4757",
    "bgsOpacity": 0.8,
    "bgsPosition": "center-center",
    "bgsSize": 60,
    "bgsType": "ball-spin-clockwise",
    "blur": 5,
    "delay": 0,
    "fgsColor": "#ff4757",
    "fgsPosition": "center-center",
    "fgsSize": 60,
    "fgsType": "ball-spin-clockwise",
    "gap": 24,
    "logoPosition": "center-center",
    "logoSize": 60,
    "logoUrl": "",
    "masterLoaderId": "master",
    "overlayBorderRadius": "0",
    "overlayColor": "rgba(40, 40, 40, 0.8)",
    "pbColor": "red",
    "pbDirection": "ltr",
    "pbThickness": 3,
    "hasProgressBar": true,
    "text": "Loading",
    "textColor": "#FFFFFF",
    "textPosition": "center-center",
    "maxTime": -1,
    "minTime": 500
  };
}
