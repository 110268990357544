import { Component, OnInit, Input } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';

/**
 * Object Displayer
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-object-displayer',
  templateUrl: './object-displayer.component.html',
  styleUrls: ['./object-displayer.component.scss']
})
export class ObjectDisplayerComponent implements OnInit {

  /**
   * Element name
   */
  @Input() elementName: string = '';
  /**
   * Element name
   */
  @Input() element: any = {};
  

  constructor(private resources: ResourcesService) { }

  ngOnInit() {
  }

  /**
   * Gets element name
   * @param c_element 
   * @returns item name 
   */
  getElementName(c_element: any): string {
    if(this.elementName == '') {
      return this.resources.getResource(c_element);
    } else {
      return this.resources.getResource(c_element[this.elementName]);
    }
  };

}
