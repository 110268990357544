import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource, Sort } from '@angular/material';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { actionMode, orientationType } from 'src/app/controllers/iconbutton/iconbutton.component';
import { ModelAuthor } from 'src/app/DAL/model-author';
import { ConfigService } from 'src/app/tools/config.service';
import { ResourcesService } from 'src/app/tools/resources.service';

/**
 * Admin authors
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-authors',
  templateUrl: './authors.component.html',
  styleUrls: ['./authors.component.scss'],
  host: {'class': 'authors'},
  encapsulation: ViewEncapsulation.None
})
export class AuthorsComponent implements OnInit {
  /**
   * Columns displayable
   */
  displayedColumns: Array<string> = ['id', 'firstname', 'lastname', 'actions'];
  /**
   * Columns displayable alias
   */
  displayedColumnsAlias: any = {'id': 'id', 'firstname': 'firstname', 'lastname':'lastname'};
  /**
   * Page length
   */
  pageLength: number = 20;
  /**
   * Page index
   */
  pageIndex: number = 0;
  /**
   * Page length
   */
  pageSize: number = 1;
  /**
   * Sort column
   */
  sortColumn = 'id';
  /**
   * Sort Order
   */
  sortOrder = 'asc';
  /**
   * List of author
   */
  authorList: any;
  /**
   * Current author for edit
   */
  currentAuthor: any = null;
  /**
   * Mat sort
   */
  sort: Sort;
  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;

  /**
   * ctr
   * @param resources 
   * @param http 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  /**
   * On init
   */
  async ngOnInit() {
  }
  /**
   * On change
   * @param change 
   */
  async ngOnChanges(change) {
    if(change.licenseId && change.licenseId.currentValue && change.licenseId.currentValue != '0') {
      await this.loadAuthors(this.pageIndex);
    }
  }
  /**
   * Sort data
   */
  sortData(_sort: Sort) {
    this.sort = _sort;
    this.loadAuthors(0);
  }
  /**
   * Load authors
   * @param _index 
   */
  async loadAuthors(_index: number) {
    if(this.sort && this.sort.direction) {
      this.sortOrder = this.sort.direction;
    }
    if(this.sort && this.sort.active) {
      this.sortColumn = this.sort.active;
    }

    this.pageIndex = _index;
    let author_list = <Array<any>> await ModelAuthor.getAuthorList(
      this.http, "", this.pageIndex * this.pageLength, this.pageLength, this.displayedColumnsAlias[this.sortColumn], this.sortOrder, this.licenseId
      ).toPromise();
    this.authorList = new MatTableDataSource(author_list['list']);
    this.pageSize = author_list['size'];
    this.authorList.sort = this.sort;
  }
  /**
   * Picture author url
   */
  pictureSrc(c_author) {
    return ModelAuthor.getPictureAuthor(c_author, this.licenseId);
  }
  /**
   * Add product
   */
  async add() {
    let data: {firstname: string, lastname: string, description: string} = {firstname: '', lastname: '', description: ''};

    let datas: Array<Formdata> = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.authors.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    datas.push(new Formdata('Image', this.resources.getResource('fa.authors.picture'), ModelAuthor.pictureAuthorUrlEmpty, formdataType.image, pictureInfo, false));
    datas.push(new Formdata('firstname', this.resources.getResource('fa.authors.firstname'), data['firstname'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('lastname', this.resources.getResource('fa.authors.lastname'), data['lastname'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('description', this.resources.getResource('fa.authors.description'), data['description'], formdataType.textarea, requiredInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
        title: this.resources.getResource('fa.authors.actions.add'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      console.log(result);
      //after post
      try {              
        let c_data = {
          'firstname': result.firstname,
          'lastname': result.lastname,
          'description': result.description,
          'license_id': this.licenseId
        };
        let res = await ModelAuthor.createAuthor(this.conf.http, c_data).toPromise();
        let c_author_a = JSON.parse(res['message']);

        //PICTURE
        let formData = result.pictures['Image'];
        if(formData != ModelAuthor.pictureAuthorUrlEmpty) {
          let ret = await ModelAuthor.uploadPictureAuthor(this.conf.http, c_author_a['Id'], this.licenseId, formData).toPromise();
        }
        //RELOAD
        await this.loadAuthors(this.pageIndex);
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }

  }
  /**
   * Edit author
   * @param c_author 
   */
  async edit(c_author) {
    this.currentAuthor = c_author;
  }
  /**
   * Author Saved
   * @param ev 
   */
  async saved(ev) {
    await this.loadAuthors(this.pageIndex);
    for(let c_author of this.authorList.data) {
      if(c_author.id == this.currentAuthor.id){
        this.currentAuthor = c_author;
        break;
      }
    }
  }
  /**
   * End edit
   * @param ev 
   */
  endEdit(ev) {
    this.currentAuthor = null;
  }
  /**
   * Get pages
   * @returns 
   */
  getPages(): number{
    return Math.ceil(this.pageSize/this.pageLength);
  }
  /**
   * Get pagination text
   * @returns 
   */
  getPaginationText(): string{
    return (this.pageIndex + 1) +'/' + this.getPages();
  }
  /**
   * Get next page
   * @returns
   */
  getNextPage(): number{
    return this.pageIndex+1<=this.getPages()-1 ? this.pageIndex+1 : this.getPages()-1; 
  }
  /**
   * Get last page
   * @return
   */
  getLastPage(): number{
    return this.getPages() - 1;
  }
}
