import { Component, OnInit, ViewEncapsulation, Input, ViewChild, OnChanges } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { MatSort, MatTableDataSource, MatDialog, MatSnackBar, Sort } from '@angular/material';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { ConfigService } from 'src/app/tools/config.service';
import { orientationType, actionMode } from 'src/app/controllers/iconbutton/iconbutton.component';
import { DialogComponent } from 'src/app/controllers/dialog/dialog/dialog.component';
import { ModelUser } from 'src/app/DAL/model-user';
import { ModelPerimeter } from 'src/app/DAL/model-perimeter';
import { Md5 } from 'ts-md5';

/**
 * Admin users
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  host: {'class': 'users'},
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, OnChanges {
  /**
   * Columns displayable
   */
  displayedColumns: Array<string> = ['id', 'login', 'firstname', 'lastname', 'roleName', 'perimeters', 'actions'];
  /**
   * Columns displayable alias
   */
  displayedColumnsAlias: any = {'id': 'id', 'login': 'login', 'firstname':'firstname', 'lastname':'lastname'};
  /**
   * Page length
   */
  pageLength: number = 20;
  /**
   * Page index
   */
  pageIndex: number = 0;
  /**
   * Page length
   */
  pageSize: number = 1;
  /**
   * Sort column
   */
  sortColumn = 'id';
  /**
   * Sort Order
   */
  sortOrder = 'asc';
  /**
   * List of user
   */
  userList: any;
  /**
   * Current user for edit
   */
  currentUser: any = null;
  /**
   * Role icons
   */
  roleIcons: any = {
    'super_admin': 'account_balance',
    'admin': 'verified_user',
    'super_user': 'record_voice_over',
    'user': 'person',
  };
  /**
   * Roles
   */
  roles: Array<{'value': string,'name': string}> = [
    {
      'value': '1',
      'name': 'super_admin'
    },
    {
      'value': '2',
      'name': 'admin'
    },
    {
      'value': '3',
      'name': 'super_user'
    },
    {
      'value': '4',
      'name': 'user'
    }
  ];

  /**
   * Mat sort
   */
  sort: Sort;
  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;

  /**
   * ctr
   * @param resources 
   * @param http 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  /**
   * On init
   */
  async ngOnInit() {
  }
  /**
   * On change
   * @param change 
   */
  async ngOnChanges(change) {
    if(change.licenseId && change.licenseId.currentValue && change.licenseId.currentValue != '0') {
      await this.loadUsers(this.pageIndex);
    }
  }
  /**
   * Sort data
   */
  sortData(_sort: Sort) {
    this.sort = _sort;
    this.loadUsers(0);
  }
  /**
   * Load users
   * @param _index 
   */
  async loadUsers(_index: number) {
    if(this.sort && this.sort.direction) {
      this.sortOrder = this.sort.direction;
    }
    if(this.sort && this.sort.active) {
      this.sortColumn = this.sort.active;
    }

    this.pageIndex = _index;
    let user_list = <Array<any>> await ModelUser.getUserList(
      this.http, "", this.pageIndex * this.pageLength, this.pageLength, this.displayedColumnsAlias[this.sortColumn], this.sortOrder, this.licenseId
      ).toPromise();
    this.userList = new MatTableDataSource(user_list['list']);
    this.pageSize = user_list['size'];
    this.userList.sort = this.sort;
  }
  /**
   * Picture profile url
   */
  pictureSrc(c_user) {
    return ModelUser.getPictureUser(c_user,this.licenseId);
  }
  /**
   * Get perimeters name
   * @param perimeter_list 
   */
  getPerimetersName(perimeter_list) {
    let perimeter_string: string = "";
    for(let c_perimeter of perimeter_list) {
      perimeter_string += c_perimeter.name + ";";
    }
    return perimeter_string;
  }
  /**
   * Add user
   */
  async add() {
    let data: {login: string, firstname: string, lastname: string, role: number, perimeters: Array<number>, picture: string, pwd: string} = {login: '', firstname: '', lastname: '', role: 0, perimeters: [], picture:ModelUser.pictureProfileUrlEmpty, pwd: ''};
    let result = await this.displayDialogUser(data);
    if(result) {
      //console.log(result);
      const md5: Md5 = new Md5();
      const md5_pwd: string = md5.appendStr(result.pwd).end() + '';
      //after post
      try {      
        let c_data = {
          'login': result.login,
          'firstname': result.firstname,
          'lastname': result.lastname,
          'role': result.role,
          'perimeters': result.perimeters,
          'license_id': this.licenseId,
          'pwd': md5_pwd
        };
        let res = await ModelUser.createUser(this.conf.http, c_data).toPromise();
        let c_user_a = JSON.parse(res['message']);
        let formData = result.pictures['Image'];
        if(formData != ModelUser.pictureProfileUrlEmpty) {
          let ret = await ModelUser.uploadUserPicture(this.conf.http, c_user_a['Id'], this.licenseId, formData).toPromise();
        }
        await this.loadUsers(this.pageIndex);
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }
  }
  /**
   * Display Dialog User
   * @param data 
   */
  async displayDialogUser(data: {login: string, firstname: string, lastname: string, role: number, perimeters: Array<number>, picture: string}): Promise<any> {
    
    let datas: Array<Formdata> = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let role_data = [];
    for(let i_role of this.roles) {
      role_data.push({
        'value': i_role.value,
        'name': this.resources.getResource('fa.users.role.' + i_role.name)
      });
    }
    let roleInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: role_data,
      hint: '*'
    };
    let perimeter_list = <Array<any>> await ModelPerimeter.getPerimeterList(this.conf.http, this.licenseId).toPromise(); 
    let perimeter_data = [];
    for(let i_perimeter of perimeter_list) {
      perimeter_data.push({
        'value': i_perimeter.id,
        'name': i_perimeter.name
      });
    }
    let perimeterInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: perimeter_data,
      hint: '*'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.users.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    datas.push(new Formdata('Image', this.resources.getResource('fa.users.picture'), data['picture'], formdataType.image, pictureInfo, false));
    datas.push(new Formdata('login', this.resources.getResource('fa.users.login'), data['login'], formdataType.mail, requiredInfo, false));
    datas.push(new Formdata('pwd', this.resources.getResource('fa.users.pwd'), data['pwd'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('firstname', this.resources.getResource('fa.users.firstname'), data['firstname'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('lastname', this.resources.getResource('fa.users.lastname'), data['lastname'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('role', this.resources.getResource('fa.users.roleName'), data['role']+'', formdataType.dropdown, roleInfo, false));
    datas.push(new Formdata('perimeters', this.resources.getResource('fa.users.perimeters'), data['perimeters'], formdataType.multipledropdown, perimeterInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
        title: this.resources.getResource('fa.users.actions.add'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    return await dialogRef.afterClosed().toPromise();
  }
  /**
   * Welcome
   * @param c_user 
   */
  async welcome(c_user) {
    try{
      let value = await ModelUser.forgetPassword(this.conf.http, encodeURIComponent(c_user.login), true).toPromise();
      
      this.snackBar.open(this.resources.getResource("fa.users.welcome.success"),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
          panelClass: 'primary'
        });
    } catch(e) {
      this.snackBar.open(this.resources.getResource("fa.users.welcome.error"),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Edit user
   * @param c_user 
   */
  async edit(c_user) {
    let data: {id: number, login: string, firstname: string, lastname: string, role: number, perimeters: Array<any>, picture: string, imageToken: string, details: Array<any>} = 
    {id: c_user.id, login: c_user.login, firstname: c_user.firstname, lastname: c_user.lastname, role: c_user.roleId, perimeters: new Array<number>(), picture: this.pictureSrc(c_user), imageToken: c_user.imageToken, details: []};
    for(let c_per of c_user.perimeterList) {
      data.perimeters.push(c_per.id+'');
    }
    let u_details: any = await ModelUser.getUserDetailList(this.conf.http, c_user.id).toPromise();
    data.details = u_details;
    this.currentUser = data;
  }
  /**
   * Delete user
   * @param c_user 
   */
  async delete(c_user) {
    try {      
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
          title: this.resources.getResource('fa.common.information'), 
          text: this.resources.getResource('fa.users.actions.delete.confirm'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.OK')
          } },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
        let c_data = {
          'id': c_user.id,
          'license_id': this.licenseId
        };
        await ModelUser.deleteUser(this.conf.http, c_data).toPromise();
        await this.loadUsers(this.pageIndex);
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * User Saved
   * @param ev 
   */
  async saved(ev) {
    await this.loadUsers(this.pageIndex);
    for(let c_user of this.userList.data) {
      if(c_user.id == this.currentUser.id){
        await this.edit(c_user);
        break;
      }
    }
  }
  /**
   * End edit
   * @param ev 
   */
  endEdit(ev) {
    this.currentUser = null;
  }
  
  /**
   * Get pages
   * @returns 
   */
  getPages(): number{
    return Math.ceil(this.pageSize/this.pageLength);
  }
  /**
   * Get pagination text
   * @returns 
   */
  getPaginationText(): string{
    return (this.pageIndex + 1) +'/' + this.getPages();
  }
  /**
   * Get next page
   * @returns
   */
  getNextPage(): number{
    return this.pageIndex+1<=this.getPages()-1 ? this.pageIndex+1 : this.getPages()-1; 
  }
  /**
   * Get last page
   * @return
   */
  getLastPage(): number{
    return this.getPages() - 1;
  }
}
