import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Minimumdialog } from './../../minimumdialog';
import { ConfigService } from './../../../tools/config.service';
import { ResourcesService } from './../../../tools/resources.service';

/**
 * Component Dialog<br/>
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {
  /**
  * Resources service of dialog component
  */
  resources: ResourcesService;
  /**
  * Config service of dialog component
  */
  conf: ConfigService;
  /**
   * Db service of dialog component
   */
  //db: DBService;

  /**
   * Title of dialog component
   */
  title: string;
  /**
   * Text  of dialog component
   */
  text: string;
  /**
   * Ok button  of dialog component
   */
  OKButton: string;
  /**
   * Cancel button of dialog component
   */
  cancelButton: string;

  /**
   * Creates an instance of dialog component.
   * @param dialogRef 
   * @param data Information to give to the dialog
   */
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Minimumdialog) {    
    this.resources = data.resources;
    this.conf = data.conf;
    //this.db = data.db;    

    this.title = this.getResource('fa.common.information');
    this.text = '';
    this.OKButton = this.getResource('fa.common.validate');
    this.cancelButton = this.getResource('fa.common.cancel');

    if (data.arg && data.arg['title']) {
      this.title = data.arg['title'];
    }
    if (data.arg && data.arg['text']) {
      this.text = data.arg['text'];
    }
    if (data.arg && data.arg['OKButton']) {
      this.OKButton = data.arg['OKButton'];
    }
    if (data.arg && data.arg['cancelButton']) {
      this.cancelButton = data.arg['cancelButton'];
    }
  }  
  
  /**
   * Gets resource (due to test/karma, data do not carry resources, we have to manage a null resources attributes)
   * @param key The key
   * @returns resource The translated resource
   */
  getResource(key: string): string {
    if(this.resources) {
      return this.resources.getResource(key);
    }
    return key;
  }

  ///SAMPLE
    /*const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px',
      data: {
        http: this.http, resources: this.resources, conf: this.conf, db: this.db, arg: {
          text: 'test'
        }
      }
    });
    this.dialogAfter = dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      //don't forget to destroy the subscription
    });*/

  /**
   * on init
   */
  ngOnInit() {
  }
}
