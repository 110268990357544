import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { ConfigService } from 'src/app/tools/config.service';
import { FormComponent } from 'src/app/controllers/form/form.component';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatSnackBar, MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { orientationType, actionMode } from 'src/app/controllers/iconbutton/iconbutton.component';
import { ModelPlaylist, kind } from 'src/app/DAL/model-playlist';
import { ModelProduct } from 'src/app/DAL/model-product';
import { ModelCategory } from 'src/app/DAL/model-category';
import { ModelTag } from 'src/app/DAL/model-tag';
import { DialogComponent } from 'src/app/controllers/dialog/dialog/dialog.component';

/**
 * Admin playlist
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
  host: {'class': 'playlist'},
  encapsulation: ViewEncapsulation.None
})
export class PlaylistComponent implements OnInit {
  /**
   * Products
   */
  productList: any;
  /**
   * Categories
   */
  categories: any;
  /**
   * Tag in categories
   */
  tagCategories: any;
  /**
   * my tags
   */
  myTags: any;
  /**
   * Form name
   */
  formName: string;
  /**
   * List of form data  of dialog component
   */
  datas: Array<Formdata>;
  
  /**
   * Separator keys codes
   */
  separatorKeysCodes: number[] = [ENTER, COMMA];
  /**
   * Product ctrl
   */
  addProductCtrl = new FormControl();
  /**
   * Filtered element
   */
  filteredAddProduct: Array<any>;
  /**
   * AutoCompleting
   */
  autoCompleting: boolean;
  /**
   * add product list
   */
  addProductList: Array<any>;
  /**
   * Element Input
   */
  @ViewChild('addProductInput', {static: false}) addProductInput: ElementRef;

  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;
  /**
   * current playlist
   */
  @Input() currentPlaylist: any;
  /**
   * Action fired
   */
  @Output() endEdit: EventEmitter<any> = new EventEmitter();
  /**
   * Action fired
   */
  @Output() saved: EventEmitter<any> = new EventEmitter();
  /**
   * View child of the form
   */
  @ViewChild('c_form', {static: false}) c_form: FormComponent;

  /**
   * ctr
   * @param resources 
   * @param http 
   * @param dialog 
   * @param snackBar 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  async ngOnInit() {
    let data: {title: string, desc: string, kind: string, picture: string} = {title: this.currentPlaylist.title, desc: this.currentPlaylist.desc, kind: this.currentPlaylist.kind, picture: this.picturePlaylistSrc(this.currentPlaylist)};

    this.datas = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.users.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    let kind_data = [];
    for(let i_role in kind) {
      kind_data.push({
        'value': kind[i_role],
        'name': this.resources.getResource('fa.playlist.kind.' + (kind[i_role] +'').toLowerCase())
      });
    }
    let kindInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: kind_data,
      hint: '*'
    };
    let pict: Formdata = new Formdata('Image', this.resources.getResource('fa.playlist.picture'), data['picture'], formdataType.image, pictureInfo, false);
    let title: Formdata = new Formdata('title', this.resources.getResource('fa.playlist.title'), data['title'], formdataType.text, requiredInfo, false);
    let desc: Formdata = new Formdata('desc', this.resources.getResource('fa.playlist.desc'), data['desc'], formdataType.text, requiredInfo, false);
    let kind_formdata: Formdata = new Formdata('kind', this.resources.getResource('fa.playlist.kind'), data['kind'], formdataType.dropdown, kindInfo, false);
    title.size = '60';
    desc.size = '60';
    this.datas.push(pict);
    this.datas.push(title);
    this.datas.push(desc);
    this.datas.push(kind_formdata);
    await this.loadTags();
    await this.loadProducts();
    this.filteredAddProduct = await this.addProductCtrl.valueChanges.pipe(
      startWith(null),
      map(async (element: string | null) => this.filterElement(element))).toPromise();
  }
  /**
   * Picture playlist url
   */
  picturePlaylistSrc(c_playlist) {
    return ModelPlaylist.getPicturePlaylist(c_playlist, this.licenseId);
  }
  /**
   * Save playlist
   */
  async save() {
    if(this.c_form.isValid()) {
      try{
        await ModelPlaylist.editPlaylist(this.http, {
          'id': this.currentPlaylist.id,
          'title': this.c_form.currentForm.value.title,
          'desc': this.c_form.currentForm.value.desc,
          'kind': this.c_form.currentForm.value.kind,
          'license_id': this.licenseId
        }).toPromise();
        let formData = this.c_form.pictures['Image'];
        if(formData != this.picturePlaylistSrc(this.currentPlaylist)) {
          let ret = await ModelPlaylist.uploadPlaylistPicture(this.conf.http, this.currentPlaylist.id, this.licenseId, formData).toPromise();
        }        
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
      this.saved.emit();
    }
  }
  /**
   * Filters element
   * @param value 
   * @returns element 
   */
  private async filterElement(value: string): Promise<any[]> {
    try{
      if(!this.autoCompleting && value && !!value.toLowerCase) {
        this.autoCompleting = true;
        let ret: any = await ModelProduct.getProductList(this.http, value.toLowerCase(), 0, 5, 'title', 'asc', this.licenseId).toPromise();
        if(ret && ret.list) {
          this.addProductList = ret['list'];
        }
        this.autoCompleting = false;
      }
      
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
    return this.addProductList;
  }
  /**
   * Adds element
   * @param event 
   */
  addProduct(event: MatChipInputEvent): void {
    const input = event.input;    

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.addProductCtrl.setValue(null);
  }
  /**
   * Selected element
   * @param event 
   */
  async selectedProduct(event: MatAutocompleteSelectedEvent): Promise<void> {
    try{
      let c_product = event.option.value;
      await ModelProduct.addProductToPlaylist(this.http, c_product.id, this.currentPlaylist.id, this.licenseId).toPromise();
      this.addProductInput.nativeElement.value = '';
      this.addProductCtrl.setValue(null);
      await this.loadProducts();
      
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Load tags
   * @param _index 
   */
  async loadTags() {
    try{
      this.categories = <Array<any>> await ModelCategory.getCategoryList(this.http, '%', 0, 10000, 'id', 'desc', this.licenseId).toPromise();
      let tags = null;
      this.tagCategories = {};
      for(let c_cat of this.categories.list) {
        tags = <Array<any>> await ModelTag.getTagList(this.http, '%', 0, 1000, 'id', 'desc', c_cat.id, this.licenseId).toPromise();
        this.tagCategories[c_cat.id] = tags;
      }
      let my_tags = <Array<any>> await ModelTag.getTagForPlaylist(this.http, this.currentPlaylist.id, this.licenseId).toPromise();
      this.myTags = {};
      for(let c_my_tags of my_tags) {
        this.myTags[c_my_tags.id] = true;
      }
      
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }

  /**
   * Tag is in playlist
   * @param tag_id 
   */
  tagIsInPlaylist(tag_id: number) {
    if(this.myTags) {
      return this.myTags[tag_id];
    }
    return false;
  }

  /**
   * chips for tag is clicked
   * @param c_tag 
   */
  async chipClicked(c_tag) {
    try{
      if(this.tagIsInPlaylist(c_tag.id)) {
        await ModelTag.removeTagFromPlaylist(this.http, c_tag.id, this.currentPlaylist.id, this.licenseId).toPromise();
      } else {
        await ModelTag.addTagToPlaylist(this.http, c_tag.id, this.currentPlaylist.id, this.licenseId).toPromise();
      }
      await this.loadTags();
      
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Load products
   */
  async loadProducts() {
    try{
      this.productList = await ModelProduct.getProductByPlaylist(this.http, '%', 0, 10000, 'id', 'desc', this.currentPlaylist.id, this.licenseId).toPromise();
      
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Remove product
   * @param c_product 
   */
  async removeProduct(c_product: any) {
    try{
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
          title: this.resources.getResource('fa.common.information'), 
          text: this.resources.getResource('fa.playlist.products.delete.confirm'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.OK')
          } },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
        await ModelProduct.removeProductFromPlaylist(this.http, c_product.id, this.currentPlaylist.id, this.licenseId).toPromise();
        await this.loadProducts();
      }
      
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Picture product url
   */
  pictureProductSrc(c_product) {
    return ModelProduct.getPictureProduct(c_product, this.licenseId);
  }
  /**
   * 
   * @param c_product Style of image
   */
  getStyle(c_product) {
    return {
      'background-size': 'cover', 
      'background-image': "url('"+this.pictureProductSrc(c_product) + "')"
    };
  }

  /**
   * Back to playlist
   */
  back() {
    this.endEdit.emit(true);
  }
  /**
   * Add On blur
   */
  addOnBlur() {

  }
}
