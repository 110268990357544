import { Component, OnInit, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { MatSort, MatDialog, MatSnackBar, MatTableDataSource, Sort } from '@angular/material';
import { ConfigService } from 'src/app/tools/config.service';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { orientationType, actionMode } from 'src/app/controllers/iconbutton/iconbutton.component';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { DialogComponent } from 'src/app/controllers/dialog/dialog/dialog.component';
import { ModelPerimeter } from 'src/app/DAL/model-perimeter';
import { ModelProduct } from 'src/app/DAL/model-product';
import { ModelCommon } from 'src/app/DAL/model-common';
import { ModelAuthor } from 'src/app/DAL/model-author';

/**
 * Admin products
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  host: {'class': 'products'},
  encapsulation: ViewEncapsulation.None
})
export class ProductsComponent implements OnInit {
  /**
   * Columns displayable
   */
  displayedColumns: Array<string> = ['id', 'title', 'summary', 'audio', 'perimeter', 'actions'];
  /**
   * Columns displayable alias
   */
  displayedColumnsAlias: any = {'id': 'id', 'title': 'title', 'summary':'summary'};
  /**
   * Page length
   */
  pageLength: number = 20;
  /**
   * Page index
   */
  pageIndex: number = 0;
  /**
   * Page length
   */
  pageSize: number = 1;
  /**
   * Sort column
   */
  sortColumn = 'id';
  /**
   * Sort Order
   */
  sortOrder = 'asc';
  /**
   * List of product
   */
  productList: any;
  /**
   * Current product for edit
   */
  currentProduct: any = null;
  /**
   * Mat sort
   */
  sort: Sort;
  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;

  /**
   * ctr
   * @param resources 
   * @param http 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  /**
   * On init
   */
  async ngOnInit() {
  }
  /**
   * On change
   * @param change 
   */
  async ngOnChanges(change) {
    if(change.licenseId && change.licenseId.currentValue && change.licenseId.currentValue != '0') {
      await this.loadProducts(this.pageIndex);
    }
  }
  /**
   * Sort data
   */
  sortData(_sort: Sort) {
    this.sort = _sort;
    this.loadProducts(0);
  }

  /**
   * Playing
   * @param c_audio 
   */
  playing(c_audio: any): void{
    const audios: HTMLCollectionOf<HTMLAudioElement> = document.getElementsByTagName("audio");
    let current_audio: HTMLAudioElement;
    let me = this;
    for(let i_audio = 0 ; i_audio < audios.length ; i_audio++) {
      current_audio = audios[i_audio];
      if(current_audio.getAttribute('id') != ('audio' + c_audio.id)) {
        current_audio.pause();        
      }
    }
  }
  /**
   * Load products
   * @param _index 
   */
  async loadProducts(_index: number) {
    if(this.sort && this.sort.direction) {
      this.sortOrder = this.sort.direction;
    }
    if(this.sort && this.sort.active) {
      this.sortColumn = this.sort.active;
    }

    this.pageIndex = _index;
    let product_list = <Array<any>> await ModelProduct.getProductList(
      this.http, "", this.pageIndex * this.pageLength, this.pageLength, this.displayedColumnsAlias[this.sortColumn], this.sortOrder, this.licenseId
      ).toPromise();
    this.productList = new MatTableDataSource(product_list['list']);
    this.pageSize = product_list['size'];
    this.productList.sort = this.sort;
  }
  /**
   * Picture product url
   */
  pictureSrc(c_product) {
    return ModelProduct.getPictureProduct(c_product, this.licenseId);
  }
  /**
   * Audio product url
   */
  audioSrc(c_product) {
    if(c_product && c_product.audioToken && c_product.audioToken.trim().length > 2) {
      let c_v: HTMLAudioElement = <HTMLAudioElement>document.getElementById("audio" + c_product.id);      
      if(c_v) {
        c_v.addEventListener("seeking", function(e) { 
          c_v.play(); 
          c_v.currentTime = e.timeStamp;
        }, true);
      }      
    }
    return ModelProduct.getAudioProduct(c_product, this.licenseId);
  }
  /**
   * Get perimeter name
   * @param perimeter
   */
  getPerimeterName(element) {
    return element.perimeterName && element.perimeterName.trim().length > 0 ? element.perimeterName : '';
  }
  /**
   * Add product
   */
  async add() {
    let data: {title: string, summary: string, desc: string, perimeter: number, author_id: number} = {title: '', summary: '', desc: '', perimeter: 0, author_id: 0};

    let datas: Array<Formdata> = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let perimeter_list = <Array<any>> await ModelPerimeter.getPerimeterList(this.conf.http, this.licenseId).toPromise(); 
    let perimeter_data = [];
    for(let i_perimeter of perimeter_list) {
      perimeter_data.push({
        'value': i_perimeter.id,
        'name': i_perimeter.name
      });
    }
    let perimeterInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: perimeter_data,
      hint: '*'
    };
    let author_list = await ModelAuthor.getAuthorList(this.conf.http, '', 0, 1000, 'id', 'asc', this.licenseId).toPromise(); 
    let author_data = [];
    if(author_list && author_list.list && author_list.list.length > 0) {
      for(let i_author of author_list.list) {
        author_data.push({
          'value': i_author.id,
          'name': i_author.firstname + " " + i_author.lastname
        });
      }
    }
    let authorInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: author_data,
      hint: '*'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.products.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    let audioInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.products.audio.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    datas.push(new Formdata('Image', this.resources.getResource('fa.products.picture'), ModelProduct.pictureProductUrlEmpty, formdataType.image, pictureInfo, false));
    datas.push(new Formdata('title', this.resources.getResource('fa.products.title'), data['title'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('summary', this.resources.getResource('fa.products.summary'), data['summary'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('desc', this.resources.getResource('fa.products.desc'), data['desc'], formdataType.textarea, requiredInfo, false));
    datas.push(new Formdata('author_id', this.resources.getResource('fa.products.author'), data['author_id'], formdataType.dropdown, authorInfo, false));
    datas.push(new Formdata('perimeter', this.resources.getResource('fa.products.perimeter'), data['perimeter'], formdataType.dropdown, perimeterInfo, false));
    datas.push(new Formdata('Audio', this.resources.getResource('fa.products.audio'), '', formdataType.image, audioInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
        title: this.resources.getResource('fa.products.actions.add'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      console.log(result);
      //after post
      try {              
        let c_data = {
          'title': result.title,
          'summary': result.summary,
          'desc': result.desc,
          'author_id': result.author_id,
          'perimeter_id': result.perimeter,
          'license_id': this.licenseId
        };
        let res = await ModelProduct.createProduct(this.conf.http, c_data).toPromise();
        let c_product_a = JSON.parse(res['message']);

        //PICTURE
        let formData = result.pictures['Image'];
        if(formData != ModelProduct.pictureProductUrlEmpty) {
          let ret = await ModelProduct.uploadProductPicture(this.conf.http, c_product_a['Id'], this.licenseId, formData).toPromise();
        }
        //AUDIO
        formData = result.pictures['Audio'];
        if(formData != '') {
          
          let ret = await ModelProduct.uploadProductAudio(this.conf.http, c_product_a['Id'], this.licenseId, formData).toPromise();
        }
        //RELOAD
        await this.loadProducts(this.pageIndex);
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }

  }
  /**
   * Edit product
   * @param c_product 
   */
  async edit(c_product) {
    this.currentProduct = c_product;
  }
  /**
   * Delete product
   * @param c_product 
   */
  async delete(c_product) {
    try {      
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
          title: this.resources.getResource('fa.common.information'), 
          text: this.resources.getResource('fa.products.actions.delete.confirm'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.OK')
          } },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
        await ModelProduct.deleteProduct(this.conf.http, {
          'id': c_product.id,
          'license_id': this.licenseId
        }).toPromise();
        //RELOAD
        await this.loadProducts(this.pageIndex);
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Product Saved
   * @param ev 
   */
  async saved(ev) {
    await this.loadProducts(this.pageIndex);
    for(let c_product of this.productList.data) {
      if(c_product.id == this.currentProduct.id){
        this.currentProduct = c_product;
        break;
      }
    }
  }
  /**
   * End edit
   * @param ev 
   */
  endEdit(ev) {
    this.currentProduct = null;
  }
  /**
   * send notification
   * @param c_product 
   */
  async notifications(c_product) {
    try {
      let data: {title: string, body: string} = {
        title: this.resources.getResource('fa.products.actions.notifications.title.default'),
        body: this.resources.getResource('fa.products.actions.notifications.body.default').replace('{0}', c_product.title)
      };
      let datas: Array<Formdata> = new Array<Formdata>();
      let requiredInfo = {
        require: true,
        requireText: this.resources.getResource('fa.common.required'),
        datas: [],
        hint: '*'
      };
      let perimeter_list = <Array<any>> await ModelPerimeter.getPerimeterList(this.conf.http, this.licenseId).toPromise(); 
      let perimeter_data = [];
      for(let i_perimeter of perimeter_list) {
        perimeter_data.push({
          'value': i_perimeter.id,
          'name': i_perimeter.name
        });
      }
      let perimeterInfo = {
        require: true,
        requireText: this.resources.getResource('fa.common.required'),
        datas: perimeter_data,
        hint: '*'
      };
      datas.push(new Formdata('title', this.resources.getResource('fa.products.actions.notifications.title'), data['title'], formdataType.text, requiredInfo, false));
      datas.push(new Formdata('body', this.resources.getResource('fa.products.actions.notifications.body'), data['body'], formdataType.text, requiredInfo, false));
      datas.push(new Formdata('perimeter', this.resources.getResource('fa.products.perimeter'), data['perimeter'], formdataType.dropdown, perimeterInfo, false));

      const dialogRef = this.dialog.open(FormdialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
          title: this.resources.getResource('fa.products.actions.notifications'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.validate'),
          datas: datas} },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
          await ModelCommon.sendNotification(this.conf.http, {
            'title': result.title,
            'body': result.body,
            'product_id': c_product.id,
            'playlist_id': 0,
            'perimeter_id': parseInt(result.perimeter),
            'license_id': parseInt(this.licenseId)
          }).toPromise();
          
        var ok_text = 'fa.playlist.actions.notifications.sent';
        this.snackBar.open(this.resources.getResource(ok_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  
  /**
   * Get pages
   * @returns 
   */
  getPages(): number{
    return Math.ceil(this.pageSize/this.pageLength);
  }
  /**
   * Get pagination text
   * @returns 
   */
  getPaginationText(): string{
    return (this.pageIndex + 1) +'/' + this.getPages();
  }
  /**
   * Get next page
   * @returns
   */
  getNextPage(): number{
    return this.pageIndex+1<=this.getPages()-1 ? this.pageIndex+1 : this.getPages()-1; 
  }
  /**
   * Get last page
   * @return
   */
  getLastPage(): number{
    return this.getPages() - 1;
  }
}
