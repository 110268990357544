import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormComponent } from 'src/app/controllers/form/form.component';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { actionMode, orientationType } from 'src/app/controllers/iconbutton/iconbutton.component';
import { ModelAuthor } from 'src/app/DAL/model-author';
import { ConfigService } from 'src/app/tools/config.service';
import { ResourcesService } from 'src/app/tools/resources.service';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss'],
  host: {'class': 'author'},
  encapsulation: ViewEncapsulation.None
})
export class AuthorComponent implements OnInit {
  /**
   * Form name
   */
  formName: string;
  /**
   * List of form data
   */
  datas: Array<Formdata>;

  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;
  /**
   * current author id
   */
  @Input() currentAuthor: any;
  /**
   * Action fired
   */
  @Output() endEdit: EventEmitter<any> = new EventEmitter();
  /**
   * Action fired
   */
  @Output() saved: EventEmitter<any> = new EventEmitter();
  /**
   * View child of the form
   */
  @ViewChild('c_form', {static: false}) c_form: FormComponent;

  /**
   * 
   * @param resources 
   * @param http 
   * @param dialog 
   * @param snackBar 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  async ngOnInit() {
    let data: {firstname: string, lastname: string, description: string, picture: string} = 
      {firstname: this.currentAuthor.firstname, lastname: this.currentAuthor.lastname, description: this.currentAuthor.description, picture: this.pictureAuthorSrc(this.currentAuthor)};
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.authors.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    //desc.size = '45';
    this.datas = new Array<Formdata>();
    this.datas.push(new Formdata('Image', this.resources.getResource('fa.authors.picture'), data['picture'], formdataType.image, pictureInfo, false));
    this.datas.push(new Formdata('firstname', this.resources.getResource('fa.authors.firstname'), data['firstname'], formdataType.text, requiredInfo, false));
    this.datas.push(new Formdata('lastname', this.resources.getResource('fa.authors.lastname'), data['lastname'], formdataType.text, requiredInfo, false));
    this.datas.push(new Formdata('description', this.resources.getResource('fa.authors.description'), data['description'], formdataType.textarea, requiredInfo, false));
  }
  /**
   * Picture author url
   */
  pictureAuthorSrc(c_author) {
    return ModelAuthor.getPictureAuthor(c_author, this.licenseId);
  }
  /**
   * Save playlist
   */
  async save() {
    if(this.c_form.isValid()) {
      try{
        await ModelAuthor.editAuthor(this.http, {
          'id': this.currentAuthor.id,
          'firstname': this.c_form.currentForm.value.firstname,
          'lastname': this.c_form.currentForm.value.lastname,
          'description': this.c_form.currentForm.value.description,
          'license_id': this.licenseId
        }).toPromise();
        let form_data_picture = this.c_form.pictures['Image'];
        if(form_data_picture != this.pictureAuthorSrc(this.currentAuthor)) {
          let ret = await ModelAuthor.uploadPictureAuthor(this.conf.http, this.currentAuthor.id, this.licenseId, form_data_picture).toPromise();
        }        
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
      this.saved.emit();
    }
  }
  /**
   * Back to products
   */
  back() {
    this.endEdit.emit(true);
  }
}
