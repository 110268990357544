import { OnInit, OnDestroy, HostListener, AfterViewInit, OnChanges } from '@angular/core';
import { ISubscription } from 'rxjs-compat/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../environments/environment';
import { ResourcesService } from '../tools/resources.service';
import { ConfigService } from '../tools/config.service';
/**
 * Page
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class page implements  OnInit, OnChanges, OnDestroy, AfterViewInit {
    //common attributes
    /**
     * Device ready of about component
     */
    deviceReady: boolean = false;
    /**
     * Inited  of about component
     */
    inited: boolean = false;
    /**
     * Title  of about component
     */
    title: string = 'fa.page.login';
    /**
     * Page title of about component
     */
    pageTitle: string = 'fa.page.login';
    /**
     * Load  of about component
     */
    load: string = 'fa.common.loading';
    /**
     * Loaded subscription
     */
    private loadedSubscription: ISubscription;

    /**
     * Service resources
     */
    public resources: ResourcesService;
    /**
     * Service config
     */
    public conf: ConfigService;
    /**
     * Service DB
     */
    /*public db: DBService;*/ 
    /**
     * Service route
     */
    protected route: ActivatedRoute; 
    /**
     * Service route
     */
    protected router: Router;
    /**
     * Service snackbar
     */
    protected snackBar: MatSnackBar

    /**
     * Creates an instance of about component.
     * @param resources Resources Service
     * @param conf Configuration Service
     * @param db Database Service
     * @param route Current route
     * @param router Router Service
     * @param snackBar SnackBar
     */
    constructor(public _resources: any, public _conf: any/*, public db: DBService*/, public _route: any, public _router: any, public _snackBar: any) {
      this.resources = _resources;
      this.conf = _conf;
      this.route = _route;
      this.router = _router;
      this.snackBar = _snackBar;
    }
    /**
     * on init
     */
    ngOnInit() {
      const self = this;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      if (!environment.mobile) {
        console.log('AppComponent not mobile ngOnInit');
        this.init();
      } else {
        console.log('AppComponent mobile ngOnInit');
        document.addEventListener('deviceready', function () {
          self.deviceReady = true;
          if (environment.mobile) {
            console.log('AppComponent mobile deviceready');
            self.init();
          } else {
            console.log('AppComponent not mobile deviceready');
          }
        }, false);
      }
    }
  
    /**
     * Inits selectcontract component
     * @returns init 
     */
    async init(): Promise<void> {
      const self = this;
      this.loadedSubscription = this.conf.isLoaded().subscribe(
        doInit => {          
          self.doInit();
          setTimeout(function() {
            self.conf.hideWaiter();
          }, 500);
        }
      );
      await this.conf.getConfig();
    }
  
    /**
     * on destroy
     */
    @HostListener('window:beforeunload')
    ngOnDestroy() {
      if (this.loadedSubscription) {
        this.loadedSubscription.unsubscribe();
      }
    }
    /**
     * On changes
     * @param changes 
     */
    ngOnChanges(changes) {

    }

    /**
     * After init
     */
    ngAfterViewInit(): void{

    }

    /**
     * Do init
     */
    async doInit() {
      if (this.conf && this.conf.current_conf) {
        if (environment.mobile) {
          //await this.db.init();
        }
      }
      this.load = this.resources.getResource('fa.common.loading');
      this.childInit();
      this.inited = true;
    }

    /**
     * Child init
     */
    protected async childInit() {
    }
}
