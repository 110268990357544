import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfigService } from './config.service';

import { ErrorHandler } from './error_handler';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

/**
 * HTTP Interceptor
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  /**
   * Current version of FormationApp
   */
  version: string = '1.0';
  /**
   * Login url
   */
  static loginUrl: string = '/#/login?href_back=';
  /**
   * Login url mobile
   */
  static loginUrlMobile: string = '.login';
  /**
   * Retry timeout limit
   */
  retryTimeoutLimit: number = 3;
  /**
   * Status that need reload
   */
  needReloadStatus: number[] = [418, 401];
  /**
   * Status that need dialog
   */
  needDialogStatus: number[] = [404, 418, 401, 500, 521, 522, 503];
  /**
   * Invalid browser status
   */
  invalidBrowserStatus: number[] = [412];
  /**
   * Try count of request
   */
  tryCount: number = 1;
  /**
   * Current handle
   */
  current_handle: any = null;
  /**
   * Not json extension
   */
  notJsonExtension: string[] = ['txt', 'sql'];

  /**
   * Creates an instance of request interceptor.
   * @param conf Config service
   * @param errorHandler ErrorHandler
   */
  constructor(private conf: ConfigService, public errorHandler: ErrorHandler, public router: Router) { }

  /**
   * Intercepts request interceptor
   * @param request Current Request
   * @param next Next handler
   * @returns intercept Interception
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const data_header = {
        'X-Requested-With': 'XMLHttpRequest',
        'v': this.version,
        //'jwt_token': '',
        'Authorization': ''
      };
      if(environment.mobile) {
        let jwt_token = window.localStorage.getItem('jwt_token');
        if(jwt_token && jwt_token.trim().length > 0) {
          data_header.Authorization = 'Bearer ' + jwt_token;
        }
      } else {
        let cookies: Array<string> = document.cookie.split(';');
        for(let c_cookie of cookies) {
          if(c_cookie && c_cookie.indexOf('jwt_token') != -1) {
            /*data_header.jwt_token = c_cookie.replace('jwt_token=', '');*/
            data_header.Authorization = 'Bearer ' + c_cookie.replace('jwt_token=', '');
          }
        }
      }
    const urlWithoutParam = request.url.split('?')[0];
    const urlEndPathSplited = request.url.split('.');
    const urlEndPath = urlEndPathSplited[urlEndPathSplited.length - 1];
    if (this.notJsonExtension.indexOf(urlEndPath) === -1) {
        data_header['Content-Type'] = 'application/json';
        data_header['contentType'] = 'application/json;charset=utf-8';
    }
    if(!(request.body instanceof FormData)) {
      request = request.clone({
        setHeaders: data_header,
        withCredentials: true
      });
    }
    this.current_handle = next.handle(request);
    this.current_handle.interceptor = this;
    return this.executeQuery();
  }

  /**
   * Executes query
   * @returns  the request
   */
  executeQuery() {
    return this.current_handle.pipe(catchError(this.callbackIntercept));
  }

  /**
   * Callbacks intercept
   * @param error Current error
   * @param caught 
   * @returns  
   */
  callbackIntercept(error: any, caught: Observable<HttpEvent<any>>) {
    if (error.statusText.toLowerCase().indexOf('timeout') !== -1) {
      if (caught.source['interceptor'].tryCount <= caught.source['interceptor'].retryTimeoutLimit) {
        console.log('retry timeout ' + caught.source['interceptor'].tryCount);
        caught.source['interceptor'].tryCount++;
        return caught.source['interceptor'].executeQuery();
      }
      console.log('error in the callbackIntercept function: ', error);
      throw error;
    } else if (error.status > 200) {
      if (caught.source['interceptor'].needReloadStatus.indexOf(error.status) !== -1) {
        if(document.location.href.indexOf('login') === -1 && document.location.href.indexOf('resetpassword') === -1) {
          if(environment.mobile) {
            this.router.navigate(['/login']);
          } else {
            //this.router.navigate(['/login',{"href_back": encodeURIComponent(document.location.href)}]);
            document.location.href = RequestInterceptor.loginUrl + encodeURIComponent(document.location.href);
            
          }
        } else {
          throw error;
        }
        return;
      } else if (caught.source['interceptor'].invalidBrowserStatus.indexOf(error.status) !== -1) {
        console.warn('wrong browser');
        return;
      } else if (caught.source['interceptor'].needDialogStatus.indexOf(error.status) !== -1) {
        //const message = caught.source['interceptor'].conf.getResource('fa.common.error.' + error.status);
        console.warn('need dialog for error http' + error.status);
        //console.warn('message: ' + message);
      }
      console.error('error: ', error);
    }
    throw error;
  }
}
