import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * ModelTag
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelTag {
  /**
   * Get tag list url
   */
  static getTagListUrl: string = "/api/tag/getList?keyword={0}&offset={1}&limit={2}&sort={3}&order={4}&category_id={5}&l_id={6}";
  /**
   * Create tag url
   */
  static createTagUrl: string = "/api/tag/createTag";
  /**
   * Edit tag url
   */
  static editTagUrl: string = "/api/tag/editTag";
  /**
   * Delete tag url
   */
  static deleteTagUrl: string = "/api/tag/deleteTag";  
    
  /**
   * Get tag url
   */
  static getTagUrl: string = "/api/tag/getTag?id={0}";
  /**
   * Get tag for playlist url
   */
  static getTagForPlaylistUrl: string = "/api/tag/getTagForPlaylist?playlist_id={0}&l_id={1}";
  /**
   * Get tag for product url
   */
  static getTagForProductUrl: string = "/api/tag/getTagForProduct?product_id={0}&l_id={1}";  
  /**
   * Add tag to product url
   */
  static addTagToProductUrl: string = "/api/tag/{0}/addTagToProduct?product_id={1}&l_id={2}";
  /**
   * Add tag to playlist url
   */
  static addTagToPlaylistUrl: string = "/api/tag/{0}/addTagToPlaylist?playlist_id={1}&l_id={2}";
  /**
   * Remove tag from product url
   */
  static removeTagFromProductUrl: string = "/api/tag/{0}/removeTagFromProduct?product_id={1}&l_id={2}";
  /**
   * Remove tag from playlist url
   */
  static removeTagFromPlaylistUrl: string = "/api/tag/{0}/removeTagFromPlaylist?playlist_id={1}&l_id={2}";
    /**
     * ctr
     */
    constructor(){

    }
    public static getTagList(http: HttpClient, keyword: string, offset: number, limit: number, sort: string, order: string, category_id: string, license_id: string): Observable<any> {
        return http.get(
            environment.prefixURLXHR + ModelTag.getTagListUrl
            .replace("{0}", keyword)
            .replace("{1}", offset + '')
            .replace("{2}", limit + '')
            .replace("{3}", sort)
            .replace("{4}", order)
            .replace("{5}", category_id)
            .replace("{6}", license_id)
        );
    }
    public static createTag(http: HttpClient, data_tag: {
        'title': string,
        'desc': string,
        'category_id': string,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelTag.createTagUrl, data_tag);
    }
    public static editTag(http: HttpClient, data_tag: {
        'id': number,
        'title': string,
        'desc': string,
        'category_id': string,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelTag.editTagUrl, data_tag);
    }
    public static deleteTag(http: HttpClient, data_tag: {
        'id': number,
        'license_id': string
      }): Observable<any> {
        return http.post(environment.prefixURLXHR + ModelTag.deleteTagUrl, data_tag);
    }
    public static getTag(http: HttpClient, id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelTag.getTagUrl.replace("{0}", id));
    }
    public static getTagForPlaylist(http: HttpClient,playlist_id: string, license_id: string ): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelTag.getTagForPlaylistUrl.replace("{0}", playlist_id).replace("{1}", license_id));
    }
    public static getTagForProduct(http: HttpClient,product_id: string, license_id: string ): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelTag.getTagForProductUrl.replace("{0}", product_id).replace("{1}", license_id));
    }
    public static addTagToProduct(http: HttpClient, tag_id: string, product_id: string, license_id: string ): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelTag.addTagToProductUrl.replace("{0}", tag_id).replace("{1}", product_id).replace("{2}", license_id));
    }
    public static addTagToPlaylist(http: HttpClient, tag_id: string, playlist_id: string, license_id: string ): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelTag.addTagToPlaylistUrl.replace("{0}", tag_id).replace("{1}", playlist_id).replace("{2}", license_id));
    }
    public static removeTagFromProduct(http: HttpClient, tag_id: string, product_id: string, license_id: string ): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelTag.removeTagFromProductUrl.replace("{0}", tag_id).replace("{1}", product_id).replace("{2}", license_id));
    }
    public static removeTagFromPlaylist(http: HttpClient, tag_id: string,  playlist_id: string, license_id: string ): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelTag.removeTagFromPlaylistUrl.replace("{0}", tag_id).replace("{1}", playlist_id).replace("{2}", license_id));
    }
}