import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ResourcesService } from '../../../tools/resources.service';
import { ConfigService } from '../../../tools/config.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Minimumdialog } from '../../minimumdialog';
import { Formdata } from '../../form/formdata';
import { FormComponent } from '../../form/form.component';

/**
 * Form Dialog
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-formdialog',
  templateUrl: './formdialog.component.html',
  styleUrls: ['./formdialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormdialogComponent implements OnInit, OnChanges, AfterViewInit  {
  /**
  * Resources service of dialog component
  */
  resources: ResourcesService;
  /**
  * Config service of dialog component
  */
  conf: ConfigService;
  /**
   * Db service of dialog component
   */
  //db: DBService;
  /**
   * Title of dialog component
   */
  title: string;
  /**
   * Form name
   */
  formName: string;
  /**
   * List of form data  of dialog component
   */
  datas: Array<Formdata>;
  /**
   * Ok button  of dialog component
   */
  OKButton: string;
  /**
   * Cancel button of dialog component
   */
  cancelButton: string;
  /**
   * View child of the form
   */
  @ViewChild('c_form', {static: false}) c_form: FormComponent;
  /**
   * Creates an instance of dialog component.
   * @param dialogRef 
   * @param data Information to give to the dialog
   */
  constructor(
    public dialogRef: MatDialogRef<FormdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Minimumdialog) {    
    this.resources = data.resources;
    this.conf = data.conf;
    //this.db = data.db;    

    this.title = this.getResource('fa.common.information');
    this.datas = new Array<Formdata>();
    this.OKButton = this.getResource('fa.common.validate');
    this.cancelButton = this.getResource('fa.common.cancel');

    if (data.arg && data.arg['title']) {
      this.title = data.arg['title'];
    }
    if (data.arg && data.arg['datas']) {
      this.datas = data.arg['datas'];
    }
    if (data.arg && data.arg['OKButton']) {
      this.OKButton = data.arg['OKButton'];
    }
    if (data.arg && data.arg['cancelButton']) {
      this.cancelButton = data.arg['cancelButton'];
    }
    if (data.arg && data.arg['cancelButton']) {
      this.cancelButton = data.arg['cancelButton'];
    }
    if (data.arg && data.arg['formName']) {
      this.formName = data.arg['formName'];
    } else {
      this.formName = 'formName' + new Date().getTime();
    }
    
  }  
  
  /**
   * Gets resource (due to test/karma, data do not carry resources, we have to manage a null resources attributes)
   * @param key The key
   * @returns resource The translated resource
   */
  getResource(key: string): string {
    if(this.resources) {
      return this.resources.getResource(key);
    }
    return key;
  }

  ///SAMPLE
    /*const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px',
      data: {
        http: this.http, resources: this.resources, conf: this.conf, db: this.db, arg: {
          text: 'test'
        }
      }
    });
    this.dialogAfter = dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      //don't forget to destroy the subscription
    });*/

  /**
   * on init
   */
  ngOnInit() {
  }
  /**
   * on changes
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
  }
  /**
   * after view init
   */
  ngAfterViewInit(): void {
  }
  /**
   * Determines whether validate on
   */
  async onValidate () {
    let result = this.c_form.currentForm.value;
    result.pictures = this.c_form.pictures;
    this.dialogRef.close(result);
  }
}
