import { Component, ViewEncapsulation } from '@angular/core';
import { page } from '../../page';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ModelProduct } from 'src/app/DAL/model-product';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ModelAuthor } from 'src/app/DAL/model-author';

/**
 * Cab detail
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-cab-detail',
  templateUrl: './cab-detail.component.html',
  styleUrls: ['./cab-detail.component.scss'],
  host: {'class': 'page'},
  encapsulation: ViewEncapsulation.None
})
export class CabDetailComponent extends page {
  /**
   * Product id
   */
  id: string;
  /**
   * Cab list Id
   */
  cablistId: string;
  /**
   * Current product
   */
  currentProduct: any;  
  /**
   * Current author
   */
  currentAuthor: any;
  /**
   * AutoPlay
   */
  autoPlay: boolean = false;
  /**
   * Muted
   */
  muted: boolean = false;
  /**
   * Volume display
   */
  volumeDisplay: boolean = true;
  /**
   * displayTitle
   */
  titleDisplay: boolean = false;
  /**
   * Sendinf favorite
   */
  sendingFavorite: boolean = false;
  /**
   * Swiper configuration
   */
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  /**
   * Creates an instance of cab detail component.
   * @param resources Resources Service
   * @param conf Configuration Service
   * @param db Database Service
   * @param route Current route
   * @param router Router Service
   * @param snackBar SnackBar
   */
  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public domS: DomSanitizer, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar) {
    super(_resources, _conf, _route, _router, _snackBar);
    //if(environment.mobile) {
      this.config.slidesPerView = 3;
    //}
  }

  /**
   * Child init
   */
  async childInit() {
    this.title = this.resources.getResourceArg('fa.page.cabdetail', ['']);
    this.pageTitle = this.resources.getResourceArg('fa.page.cabdetail', ['']);
    if(this.route && this.route.snapshot && this.route.snapshot.paramMap){
      this.id = this.route.snapshot.paramMap.get('id');      
      this.cablistId = this.route.snapshot.paramMap.get('cb_id');
      this.currentProduct = await ModelProduct.getProduct(this._conf.http, this.id).toPromise();
      if(this.currentProduct.authorId && this.currentProduct.authorId > 0){
        this.currentAuthor = await ModelAuthor.getAuthor(this._conf.http, this.currentProduct.authorId).toPromise();
      } else {
        this.currentAuthor = null;
      }
      this.title = this.resources.getResourceArg('fa.page.cabdetail', [this.currentProduct.title]);
      this.pageTitle = this.resources.getResourceArg('fa.page.cabdetail', [this.currentProduct.title]);
    }
    this.conf.setPageConf({
      title: this.title,
      pageTitle: this.pageTitle,
      gotsubheader: true
    });
  }
  /**
   * Picture product url
   */
  pictureSrc(c_product): string {
    return ModelProduct.getPictureProduct(c_product, '0');
  }
  /**
   * Picture author url
   * @returns 
   */
  pictureAuthorSrc(): string {
    if(this.currentAuthor && this.currentAuthor.id && this.currentAuthor.id > 0) {
      return ModelAuthor.getPictureAuthor(this.currentAuthor.id, '0');
    }
    return ModelAuthor.pictureAuthorUrlEmpty;
  }
  /**
   * Author name
   * @returns 
   */
  getAuthorName(): string{
    if(this.currentAuthor && this.currentAuthor.id && this.currentAuthor.id > 0) {
      return this.currentAuthor.firstname + ' ' + this.currentAuthor.lastname;
    }
    return '';
  }
  /**
   * Author description
   * @returns 
   */
  getAuthorDescription(): string{    
    if(this.currentAuthor && this.currentAuthor.id && this.currentAuthor.id > 0) {
      return this.currentAuthor.description;
    }
    return '';
  }
  /**
   * Audio product url
   */
  audioSrc(c_product) {
    return ModelProduct.getAudioProduct(c_product, '0');
  }
  /**
   * Picture gallery url
  */
  pictureGallerySrc(c_gal) {
    return ModelProduct.getPictureGallery(this.currentProduct.id, c_gal, '0');
  }
  /**
   * 
   * @param c_gal Style of image
   */
  getStyle(c_gal) {
    return {       
      'background-image': "url('"+this.pictureGallerySrc(c_gal) + "')"
    };
  }
  /**
   * Toggle favorite
   * @param c_product 
   */
  async toggleFavorite(c_product) {
    try{
      if(!this.sendingFavorite) {
        this.sendingFavorite = true;
        if(c_product.favorite) {
          await ModelProduct.removeFavorite(this._conf.http, c_product.id).toPromise();
          c_product.favorite = false;
        } else {
          await ModelProduct.addFavorite(this._conf.http, c_product.id).toPromise();
          c_product.favorite = true;
        }
        this.sendingFavorite = false;
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  /**
   * Go back
   */
  goBack() {
    if(this.cablistId) {
      this.router.navigate(['/cablist/' + this.cablistId]);
    } else {
      this.router.navigate(['/cab/']);
    }
  }

}
