import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { page } from '../../page';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatTableDataSource, MatSort, MatDialog, Sort } from '@angular/material';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { orientationType, actionMode } from 'src/app/controllers/iconbutton/iconbutton.component';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import * as moment from 'moment';
import 'moment/locale/fr';
import { ModelLicense } from 'src/app/DAL/model-license';

/**
 * Admin license
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
  host: {'class': 'page'},
  encapsulation: ViewEncapsulation.None
})
export class LicensesComponent extends page {
  /**
   * Columns displayable
   */
  displayedColumns: Array<string> = ['id', 'name', 'validTime', 'actions'];
  /**
   * Columns displayable alias
   */
  displayedColumnsAlias: any = {'id': 'id', 'name': 'name', 'validTime':'valid_time'};
  /**
   * Page length
   */
  pageLength: number = 20;
  /**
   * Page index
   */
  pageIndex: number = 0;
  /**
   * Page length
   */
  pageSize: number = 1;
  /**
   * Sort column
   */
  sortColumn = 'id';
  /**
   * Sort Order
   */
  sortOrder = 'asc';
  /**
   * List of license
   */
  licenseList: any;

  /**
   * Mat sort
   */
  sort: Sort;

  /**
   * Creates an instance of about component.
   * @param resources Resources Service
   * @param conf Configuration Service
   * @param db Database Service
   * @param route Current route
   * @param router Router Service
   * @param snackBar SnackBar
   */
  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar, public dialog: MatDialog) {
    super(_resources, _conf, _route, _router, _snackBar);
  }

  /**
   * Child init
   */
  async childInit() {
    if(this.conf && this._conf.current_conf && this._conf.current_conf.currentUser) {
      if(this._conf.current_conf.currentUser.roleName !== 'super_admin' ) {
        this.router.navigate(['/404']);
        return;
      }
      this.title = this.resources.getResource('fa.page.licenses');
      this.pageTitle = this.resources.getResource('fa.page.licenses');
      this.conf.setPageConf({
        title: this.title,
        pageTitle: this.pageTitle,
        gotsubheader: true
      });
    }
    await this.loadLicenses(this.pageIndex);
  }
  /**
   * Load licenses
   * @param _index 
   */
  async loadLicenses(_index: number) {
    if(this.sort && this.sort.direction) {
      this.sortOrder = this.sort.direction;
    }
    if(this.sort && this.sort.active) {
      this.sortColumn = this.sort.active;
    }

    this.pageIndex = _index;
    let lic_list = <Array<any>> await ModelLicense.getLicenseList(
        this._conf.http, this.pageIndex * this.pageLength, this.pageLength, this.displayedColumnsAlias[this.sortColumn], this.sortOrder
      ).toPromise();
    this.licenseList = new MatTableDataSource(lic_list['list']);
    this.pageSize = lic_list['size'];
    this.licenseList.sort = this.sort;
  }
  /**
   * Logo url
   * @param c_lic 
   */
  logoSrc(c_lic) {
    return ModelLicense.getPictureLicense(c_lic);
  }

  /**
   * Display date
   * @param c_date Current date
   */
  getDate(c_date: number){
    return moment(c_date).format("DD/MM/YYYY");
  }

  /**
   * Edit license
   * @param c_lic current_license 
   */
  edit(c_lic) {
    this.router.navigate(['/admin/license/' + c_lic.id]);
  }
  /**
   * Add license
   */
  async add() {
    let data: {licenseImage: string, licenseName: string, licenseExpiration: string} = {licenseImage: ModelLicense.logoDefaultSrc, licenseName: '', licenseExpiration: ''};

    let datas: Array<Formdata> = new Array<Formdata>();
    let requireInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let alphaNumInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*',
      pattern: /^\w+$/,
      patternText: 'fa.licenses.name.pattern'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.licenses.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    datas.push(new Formdata('licenseImage', this.resources.getResource('fa.licenses.picture'), data['licenseImage'], formdataType.image, pictureInfo, false));
    datas.push(new Formdata('licenseName', this.resources.getResource('fa.licenses.name'), data['licenseName'], formdataType.text, alphaNumInfo, false));
    datas.push(new Formdata('licenseExpiration', this.resources.getResource('fa.licenses.expiration'), data['licenseExpiration'], formdataType.date, requireInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null/*this.db*/, arg: {
        title: this.resources.getResource('fa.licenses.actions.add'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      //after post
      try {        
        let c_lic_a = await ModelLicense.createLicense(this._conf.http, result.licenseName.toLowerCase(), result.licenseExpiration.getTime()).toPromise();
        let formData = result.pictures['licenseImage'];        
        if(formData != ModelLicense.logoDefaultSrc) {
          let ret = await ModelLicense.uploadLicensePicture(this.conf.http, c_lic_a['id'], formData).toPromise();
        }
        await this.loadLicenses(0);
      } catch(e) {
        var error_text = 'fa.licenses.picture.error';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }
  }
  /**
   * Sort data
   */
  sortData(_sort: Sort) {
    this.sort = _sort;
    this.loadLicenses(0);
  }
  
  /**
   * Get pages
   * @returns 
   */
  getPages(): number{
    return Math.ceil(this.pageSize/this.pageLength);
  }
  /**
   * Get pagination text
   * @returns 
   */
  getPaginationText(): string{
    return (this.pageIndex + 1) +'/' + this.getPages();
  }
  /**
   * Get next page
   * @returns
   */
  getNextPage(): number{
    return this.pageIndex+1<=this.getPages()-1 ? this.pageIndex+1 : this.getPages()-1; 
  }
  /**
   * Get last page
   * @return
   */
  getLastPage(): number{
    return this.getPages() - 1;
  }
}
