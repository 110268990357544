import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Formdata, formdataType } from './formdata';
import { FormGroup, FormControl, Validators, ValidatorFn, FormBuilder } from '@angular/forms';
import { ResourcesService } from '../../tools/resources.service';
import { MatChipInputEvent } from '@angular/material';

/**
 * Form
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  host: {'class': 'appforms'},
  encapsulation: ViewEncapsulation.None
})
export class FormComponent implements OnInit, OnChanges {
  /**
   * Separator keys codes for openchips
   */
  separatorKeysCodes: number[] = [ENTER, COMMA];
  /**
   * List of uploaded pictures
   */
  pictures: any;
  /**
   * List of required pictures
   */
  picturesRequired: Array<string>;
  /**
   * Current form
   */
  currentForm: FormGroup;
  /**
   * Errors list
   */
  errors: any;
  /**
   * Form name
   */
  @Input() formName: string;
  /**
   * List of form data
   */
  @Input() datas: Array<Formdata>;

  /**
   * Creates an instance of form component.
   */
  constructor(private resources: ResourcesService, private fb: FormBuilder) { }

  /**
   * on init
   */
  ngOnInit() {
    let controls = {};
    let _validators: Array<ValidatorFn>;
    this.picturesRequired = new Array<string>();
    if(!this.datas) {
      return;
    }
    for(let c_data of this.datas) {
      if(!c_data.options.displayConditions){
        c_data.options.displayConditions = function(){return true;}
      }
      _validators = Array<ValidatorFn>();
      c_data.errors = {};
      c_data.errors[c_data.name] = [];
      if(c_data.options) {
        if(c_data.type === 'image') {
          if(!!c_data.options['require']) {
            this.picturesRequired.push(c_data.name);
          }
        } else {
          if(!c_data.options.datas) {
            c_data.options.datas = [];
          }
          if(!!c_data.options['require']) {
            _validators.push(Validators.required);
            c_data.errors[c_data.name].push({name: c_data.name, type: 'require', label: c_data.options['requireText']});
          }
          if(!!c_data.options['pattern']) {
            _validators.push(Validators.pattern(c_data.options['pattern']));
            c_data.errors[c_data.name].push({name: c_data.name, type: 'pattern', label: c_data.options['patternText']});
          }
          if(!!c_data.options['email']) {
            _validators.push(Validators.email);
            c_data.errors[c_data.name].push({name: c_data.name, type: 'email', label: c_data.options['emailText']});
          }
          if(!!c_data.options['max']) {
            _validators.push(Validators.max(c_data.options['max']));
            c_data.errors[c_data.name].push({name: c_data.name, type: 'max', label: c_data.options['maxText']});
          }
          if(!!c_data.options['maxLength']) {
            _validators.push(Validators.maxLength(c_data.options['maxLength']));
            c_data.errors[c_data.name].push({name: c_data.name, type: 'maxLength', label: c_data.options['maxLengthText']});
          }
          if(!!c_data.options['min']) {
            _validators.push(Validators.min(c_data.options['min']));
            c_data.errors[c_data.name].push({name: c_data.name, type: 'min', label: c_data.options['minText']});
          }
          if(!!c_data.options['minLength']) {
            _validators.push(Validators.minLength(c_data.options['minLength']));
            c_data.errors[c_data.name].push({name: c_data.name, type: 'minLength', label: c_data.options['minLengthText']});
          }
          if(c_data.type === formdataType.mail) {
            _validators.push(Formdata.validateEmail);
            c_data.errors[c_data.name].push({name: c_data.name, type: 'mail', label: this.resources.getResource('fa.common.error.mail')});
          }
          if(c_data.type === formdataType.phone) {
            _validators.push(Formdata.validatePhone);
            c_data.errors[c_data.name].push({name: c_data.name, type: 'phone', label: this.resources.getResource('fa.common.error.phone')});
          }
          if(c_data.type === formdataType.bic) {
            _validators.push(Formdata.validateBIC);
            c_data.errors[c_data.name].push({name: c_data.name, type: 'bic', label: this.resources.getResource('fa.common.error.bic')});
          }
          if(c_data.type === formdataType.iban) {
            _validators.push(Formdata.validateIBAN);
            c_data.errors[c_data.name].push({name: c_data.name, type: 'iban', label: this.resources.getResource('fa.common.error.iban')});
          }
          if(c_data.type === formdataType.numsecu) {
            _validators.push(Formdata.validateNumSecu);
            c_data.errors[c_data.name].push({name: c_data.name, type: 'numsecu', label: this.resources.getResource('fa.common.error.numsecu')});
          }
          if(c_data.type === formdataType.password) {
            _validators.push(Formdata.validatePassword);
            c_data.errors[c_data.name].push({name: c_data.name, type: 'password', label: this.resources.getResource('fa.common.error.password')});
          }
        }
      }
      controls[c_data.name] = new FormControl('', _validators);      
    }
    this.currentForm = new FormGroup(controls);
    for(let c_data of this.datas) {
      if(c_data.type === 'openchips') {
        c_data[c_data.name] = [];
        if(c_data.defaultValue) {
          c_data[c_data.name] = c_data.defaultValue;
        }
        this.currentForm.controls[c_data.name].setValue(c_data[c_data.name]);
      } else if(c_data.type === formdataType.image) {
        if(c_data.defaultValue) {
          if(!this.pictures) {
            this.pictures = {};
          }
          this.pictures[c_data.name] = c_data.defaultValue;
          c_data['pictureUrl'] = c_data.defaultValue;
        }
      } else {
        if(c_data.defaultValue) {
          this.currentForm.controls[c_data.name].setValue(c_data.defaultValue);
        }
      }
    }
  } 
  /**
   * on changes
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
  }
  /**
   * Control got error
   * @param errors 
   * @returns true if got error 
   */
  controlGotError(controlName: string): boolean {
    
    let ret_error: boolean = false;
    try{
      if(this && this.currentForm && this.currentForm.controls && this.currentForm.controls[controlName] && this.currentForm.controls[controlName].errors) {
        let i_error = null;
        let c_error = null;
        for(let i_error in this.currentForm.controls[controlName]['errors']) {
          c_error = this.currentForm.controls[controlName]['errors'][i_error];
          if(c_error && this.currentForm.controls[controlName].touched) {
            return true;
          }
        }
      }
    } catch(e) {
      console.log(e);
    }
    return ret_error;
  }
  /**
   * Is valid
   */
  isValid(): boolean {
    let pictureValid = true;
    for(let c_pict_req of this.picturesRequired) {
      if(!this.pictures || !this.pictures[c_pict_req]) {
        pictureValid = false;
      }
    }
    return this.currentForm.valid && pictureValid;
  }
  /**
   * Determines whether disabled is
   * @param obj 
   * @returns true if disabled 
   */
  isDisabled(obj) : boolean{
    return obj.disable;
  }
  /**
   * Uploads file
   * @param files 
   * @returns file 
   */
  async uploadFile(data: any): Promise<void> {
    //prepare post
    try{      
      if (data.files.length !== 1) {
          return;
      }
      var formData = new FormData();
      for(let i =0; i < data.files.length; i++){
        formData.append("files", data.files[i], data.files[i]['name']);
      }
      if(!this.pictures) {
        this.pictures = {};
      }
      this.pictures[data.uploadParam.name] = formData;
    
        var mimeType = data.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
    
        var reader = new FileReader();
        reader.readAsDataURL(data.files[0]); 
        reader.onload = (_event) => { 
          data.uploadParam.pictureUrl = reader.result; 
        }
    } catch(e) {
      console.log(e);
    }
  }
  /**
   * Add open  chips
   * @param event 
   * @param c_data 
   */
  addOpenchips(event: MatChipInputEvent, c_data: any) {
    const input = event.input;
    const value = event.value;
    if ((value.trim() !== '')) {
      c_data[c_data.name].push(value);
      this.currentForm.controls[c_data.name].markAsDirty();
      input.value = '';
    }
  }

  /**
   * Remove open chips
   * @param value 
   * @param c_data 
   */
  removeOpenchips(value: any, c_data: any) {
    let controller = this.currentForm.controls[c_data.name];
    let index = c_data[c_data.name].indexOf(value, 0);
    if (index > -1) {
      c_data[c_data.name].splice(index, 1);
    }
    controller.markAsDirty();
  }
  /**
   * 
   * @param f1 
   * @param f2 
   */
  compareFn(f1:any, f2:any):boolean{
    return f1 == f2;
  }
  /**
   * Add on blur
   */
  addOnBlur(){

  }
}


/*
HOW TO USE


    const availableTypes: Array<{name: string, value: string}> = new Array<{name: string, value: string}>();
    availableTypes.push({name: 'pdf', value: 'pdf'});
    availableTypes.push({name: 'jpg', value: 'jpg'});
    availableTypes.push({name: 'jpeg', value: 'jpeg'});
    availableTypes.push({name: 'tiff', value: 'tiff'});
    availableTypes.push({name: 'png', value: 'png'});
    availableTypes.push({name: 'doc', value: 'doc'});
    availableTypes.push({name: 'docx', value: 'docx'});
    availableTypes.push({name: 'xls', value: 'xls'});
    availableTypes.push({name: 'xlsx', value: 'xlsx'});
    availableTypes.push({name: 'ppt', value: 'ppt'});
    availableTypes.push({name: 'pptx', value: 'pptx'});
    availableTypes.push({name: '*', value: '*'});

    let datas: Array<Formdata> = new Array<Formdata>();
    let requireInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: 'hint'
    };
    let dropdownInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: availableTypes,
      hint: 'hint'
    };
    let emptyInfo = {
      datas: [],
      hint: 'hint'
    };

    datas.push(new Formdata('text', 'text', '', formdataType.text, requireInfo));
    datas.push(new Formdata('number', 'number', '', formdataType.number, requireInfo));
    datas.push(new Formdata('textarea', 'textarea', '', formdataType.textarea, requireInfo));
    datas.push(new Formdata('date', 'date', '', formdataType.date, requireInfo));
    datas.push(new Formdata('single', 'single', '', formdataType.single, dropdownInfo));
    datas.push(new Formdata('multi', 'multi', '', formdataType.multi, dropdownInfo));
    datas.push(new Formdata('dropdown', 'dropdown', '', formdataType.dropdown, dropdownInfo));
    datas.push(new Formdata('multipledropdown', 'multipledropdown', '', formdataType.multipledropdown, dropdownInfo));
    datas.push(new Formdata('switch', 'switch', '', formdataType.switch, emptyInfo));
    datas.push(new Formdata('slider', 'slider', '', formdataType.slider, requireInfo));
*/