import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/tools/config.service';
import { FormComponent } from 'src/app/controllers/form/form.component';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { DialogComponent } from 'src/app/controllers/dialog/dialog/dialog.component';
import { ModelTag } from 'src/app/DAL/model-tag';
import { ModelCategory } from 'src/app/DAL/model-category';

/**
 * Admin category
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  host: {'class': 'category'},
  encapsulation: ViewEncapsulation.None
})
export class CategoryComponent implements OnInit {
  /**
   * Columns displayable
   */
  displayedColumns: Array<string> = ['id', 'title', 'desc', 'actions'];
  /**
   * Columns displayable alias
   */
  displayedColumnsAlias: any = {'id': 'id', 'title': 'title', 'desc': 'desc'};
  /**
   * Page length
   */
  pageLength: number = 20;
  /**
   * Page index
   */
  pageIndex: number = 0;
  /**
   * Page length
   */
  pageSize: number = 1;
  /**
   * Sort column
   */
  sortColumn = 'id';
  /**
   * Sort Order
   */
  sortOrder = 'asc';
  /**
   * List of tagList
   */
  tagList: any;

  /**
   * Form name
   */
  formName: string;
  /**
   * List of form data  of dialog component
   */
  datas: Array<Formdata>;
  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;
  /**
   * current category
   */
  @Input() currentCategory: any;
  /**
   * Action fired
   */
  @Output() endEdit: EventEmitter<any> = new EventEmitter();
  /**
   * Action fired
   */
  @Output() saved: EventEmitter<any> = new EventEmitter();
  /**
   * View child of the form
   */
  @ViewChild('c_form', {static: false}) c_form: FormComponent;
  /**
   * Mat sort
   */
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  /**
   * ctr
   * @param resources 
   * @param http 
   * @param dialog 
   * @param snackBar 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  ngOnInit() {
    let data: {title: string, desc: string} = {title: this.currentCategory.title, desc: this.currentCategory.desc};

    this.datas = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let title: Formdata = new Formdata('title', this.resources.getResource('fa.categories.title'), data['title'], formdataType.text, requiredInfo, false);
    let desc: Formdata = new Formdata('desc', this.resources.getResource('fa.categories.desc'), data['desc'], formdataType.text, requiredInfo, false);
    title.size = '60';
    desc.size = '60';
    this.datas.push(title);
    this.datas.push(desc);
    this.loadTags(this.pageIndex);
  }
  /**
   * Load tags
   * @param _index 
   */
  async loadTags(_index: number) {
    if(this.sort && this.sort.direction) {
      this.sortOrder = this.sort.direction;
    }
    if(this.sort && this.sort.active) {
      this.sortColumn = this.sort.active;
    }

    this.pageIndex = _index;
    let tag_list = <Array<any>> await ModelTag.getTagList(
      this.http, "", this.pageIndex, this.pageLength, this.displayedColumnsAlias[this.sortColumn], this.sortOrder, this.currentCategory.id, this.licenseId
      ).toPromise();
    this.tagList = new MatTableDataSource(tag_list['list']);
    this.pageSize = tag_list['size'];
    this.tagList.sort = this.sort;
  }
  /**
   * Save category
   */
  async save() {
    if(this.c_form.isValid()) {
      let title : string = this.c_form.currentForm.value.title;
      let desc : string = this.c_form.currentForm.value.desc;
      //after post
      try {              
        let c_data = {
          'id': this.currentCategory.id,
          'title': title,
          'desc': desc,
          'license_id': this.licenseId
        };
        let res = await ModelCategory.editCategory(this.conf.http, c_data).toPromise();
        let c_user_a = JSON.parse(res['message']);
        //RELOAD
        this.saved.emit(true);
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }
  }
  /**
   * Add tag
   */
  async addTag() {
    let data: {title: string, desc: string} = {title: '', desc: ''};

    let datas: Array<Formdata> = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    datas.push(new Formdata('title', this.resources.getResource('fa.categories.title'), data['title'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('desc', this.resources.getResource('fa.categories.desc'), data['desc'], formdataType.text, requiredInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
        title: this.resources.getResource('fa.categories.actions.add.tag'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      console.log(result);
      //after post
      try {              
        let c_data = {
          'title': result.title,
          'desc': result.desc,
          'category_id': this.currentCategory.id,
          'license_id': this.licenseId
        };
        let res = await ModelTag.createTag(this.conf.http, c_data).toPromise();
        let c_user_a = JSON.parse(res['message']);
        //RELOAD
        await this.loadTags(this.pageIndex);
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }

  }
  /**
   * Edit tag
   */
  async editTag(c_tag) {
    let data: {title: string, desc: string} = {title: c_tag.title, desc: c_tag.desc};

    let datas: Array<Formdata> = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    datas.push(new Formdata('title', this.resources.getResource('fa.categories.title'), data['title'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('desc', this.resources.getResource('fa.categories.desc'), data['desc'], formdataType.text, requiredInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
        title: this.resources.getResource('fa.categories.actions.edit.tag'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      console.log(result);
      //after post
      try {              
        let c_data = {
          'id': c_tag.id,
          'title': result.title,
          'desc': result.desc,
          'category_id': this.currentCategory.id,
          'license_id': this.licenseId
        };
        let res = await ModelTag.editTag(this.conf.http, c_data).toPromise();
        let c_user_a = JSON.parse(res['message']);
        //RELOAD
        await this.loadTags(this.pageIndex);
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }
  }
  /**
   * Delete tag
   * @param c_tag
   */
  async deleteTag(c_tag) {
    try {      
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
          title: this.resources.getResource('fa.common.information'), 
          text: this.resources.getResource('fa.categories.actions.delete.tag.confirm'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.OK')
          } },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
        await ModelTag.deleteTag(this.conf.http, {
          'id': c_tag.id,
          'license_id': this.licenseId
        }).toPromise();
        //RELOAD
        await this.loadTags(this.pageIndex);
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  

  /**
   * Back to categories
   */
  back() {
    this.endEdit.emit(true);
  }
}
