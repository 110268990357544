import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { ResourcesService } from './tools/resources.service';
import { ConfigService } from './tools/config.service';
import { ISubscription } from 'rxjs-compat/Subscription';

/**
 * App root
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  /**
   * Back login
   */
  backLogin: boolean = false;
  /**
   * Indicates that somathing is dragging on main component
   */
  dragClass: string = '';
  /**
   * got menu
   */
  gotMenu: boolean = false;
  /**
   * Got sub header
   */
  gotsubheader: any = false;
  /**
   * Page configuration subscription
   */
  protected pageConfSubscription: ISubscription;
  /**
   * Creates an instance of app component.
   * @param route Current route
   * @param router Current router
   * @param resources Resources services
   * @param conf Config Services
   * @param db Database Services
   */
  constructor(public route: ActivatedRoute, public router: Router, public resources: ResourcesService, public conf: ConfigService/*, public db: DBService*/) {
    var self = this;
    this.pageConfSubscription = this.conf.pageConf().subscribe(
      pageConf => {
        if(pageConf) {
          self.gotMenu = true;
          self.gotsubheader = pageConf.gotsubheader;
        }
      });    
  }

  /**
   * Ondragenters PDF upload zone
   * @param ev 
   */
  ondragenter(ev: DragEvent){
    this.dragClass = 'dragging';
  }

  /**
   * Ondragleaves PDF upload zone
   * @param ev 
   */
  ondragleave(ev: DragEvent){
    this.dragClass = '';
  }
}
