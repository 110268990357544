import { environment } from 'src/environments/environment';

/**
 * Browser
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class Browser {
  /**
   * Version  of browser
   */
  version: (string | number);
  /**
   * Is Safari
   */
  safari: boolean;
  /**
   * Is Edge
   */
  edge: boolean;
  /**
   * Is Webkit
   */
  webkit: boolean;
  /**
   * Is Chrome
   */
  chrome: boolean;
  /**
   * Is Opera
   */
  opera: boolean;
  /**
   * Is Msie
   */
  msie: boolean;
  /**
   * Is Mozilla
   */
  mozilla: boolean;
  /**
   * Is mac
   */
  isMac: boolean;
  /**
   * Platform list
   */
  platform = {
    isWeb: <boolean>false,
    isWindows: <boolean>false,
    isIos: <boolean>false,
    isAndroid: <boolean>false
  };
  /**
   * Body class list
   */
  bodyClass = [];
  /**
   * Body class as string
   */
  bodyClassStr: '';
  /**
   * Determines whether mobile is
   */
  isMobile = false;
  /**
   * Cordova infomation
   */
  cordova: any;
  /**
   * Device information
   */
  device: any;

  /**
   * Creates an instance of browser.
   */
  constructor() {
    const userAgent = navigator.userAgent.toLowerCase();
   
    this.version = (userAgent.match(new RegExp('.+(?:rv|it|ra|ie)[\\/: ]([\\d.]+)')) || [0, '0'])[1];
    this.safari = /webkit/.test(userAgent) && !(/edge/.test(userAgent)) && !(/chrome/.test(userAgent));
    this.edge = /edge/.test(userAgent);
    this.webkit = /webkit/.test(userAgent) && !(/edge/.test(userAgent));
    this.chrome = /webkit/.test(userAgent) && !(/edge/.test(userAgent)) && (/chrome/.test(userAgent));
    this.opera = /opera/.test(userAgent);
    this.msie = (/msie/.test(userAgent) && !/opera/.test(userAgent)) || /trident/.test(userAgent);
    this.mozilla = /mozilla/.test(userAgent) && !/(compatible|webkit)/.test(userAgent) && !/trident/.test(userAgent);
    this.isMac = /Macintosh/.test(userAgent);
    
    if (this.msie && document['documentMode'] !== undefined) {
      this.version = parseInt(document['documentMode'], 10);
    }

    this.bodyClass = [];
    if (this.isMobile && this.cordova && this.cordova['platformId'] === 'windows') {
      this.bodyClass.push('appMobile');
      this.bodyClass.push('appWindows');
      this.bodyClass.push('browserMSIE');
      this.platform.isWindows = true;
    } else if (this.isMobile && this.cordova && this.cordova['platformId'] === 'ios') {
      this.bodyClass.push('appMobile');
      this.bodyClass.push('appIos');
      this.platform.isIos = true;
      try {
        if (this.device && this.device.version) {
          const osversion_splitted = this.device.version.split('.');
          if (osversion_splitted[0]) {
            const osversion_main = parseInt(osversion_splitted[0]);
            if (osversion_main < 9) {
              this.bodyClass.push('appIosInf9');
            }
          }
        }
      } catch (e) {
        console.log('cannot get iOS version! ', e);
      }
    } else if (environment.mobile) {
      this.bodyClass.push('appMobile');
      this.platform.isAndroid = true;
      try {
        /*let AndroidVersion: (string | number)[] = userAgent.match(new RegExp('.+(?:android)[\\/: ]([\\d.]+)')) || [0, '0'];
        if (AndroidVersion && AndroidVersion.length > 1 && AndroidVersion[1] && AndroidVersion[1].length > 0) {
            let osversion_splitted = AndroidVersion[1].split('.');
            if (osversion_splitted[0]) {
                let osversion_main = parseInt(osversion_splitted[0]);
                if (osversion_main < 5) {
                    this.bodyClass.push(' appAndroidInf5');
                }
            }
        }*/
      } catch (e) {
        console.log('cannot get Android version! ', e);
      }
    } else {
      this.platform.isWeb = true;
      /*let meta = document.createElement('meta');
      meta.name = "viewport";
      meta.content = "width=device-width, initial-scale=1.0, shrink-to-fit=no";
      document.getElementsByTagName('head')[0].appendChild(meta);*/
      // calinda.jQ('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no">');
      this.bodyClass.push('appWeb');
      if (this.edge) {
        this.bodyClass.push('browserEdge');
      } else if (this.mozilla) {
        this.bodyClass.push('browserMozilla');
      } else if (this.msie) {
        this.bodyClass.push('browserMSIE');
      } else if (this.opera) {
        this.bodyClass.push('browserOpera');
      } else if (this.chrome) {
        this.bodyClass.push('browserChrome');
      } else if (this.safari) {
        this.bodyClass.push('browserChrome');
      } else if (this.isMac) {
        this.bodyClass.push('browserMAC');
      }
    }
    let c_class: string = null;
    this.bodyClassStr = '';
    for (c_class of this.bodyClass) {
      this.bodyClassStr += c_class + ' ';
    }
  }
}
