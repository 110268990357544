import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { page } from '../../page';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ModelLicense } from 'src/app/DAL/model-license';

/**
 * Admin license
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  host: {'class': 'page'},
  encapsulation: ViewEncapsulation.None
})
export class LicenseComponent extends page {
  /**
   * Current license id
   */
  licenseId: string = "0";
  /**
   * Current License
   */
  currentLicense: any;

  /**
   * Creates an instance of about component.
   * @param resources Resources Service
   * @param conf Configuration Service
   * @param db Database Service
   * @param route Current route
   * @param router Router Service
   * @param snackBar SnackBar
   */
  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar, public dialog: MatDialog) {
    super(_resources, _conf, _route, _router, _snackBar);
  }

  /**
   * Child init
   */
  async childInit() {    
    if(this._conf && this._conf.current_conf && this._conf.current_conf.currentUser) {
      if(!(this._conf.current_conf.currentUser.roleName === 'super_admin' 
      || this._conf.current_conf.currentUser.roleName === 'admin' 
      || this._conf.current_conf.currentUser.roleName === 'super_user' )) {
        this.router.navigate(['/404']);
        return;
      }
      this.licenseId = this.route.snapshot.paramMap.get('l_id');
      this.title = this.resources.getResourceArg('fa.page.license', [this.licenseId, ""]);
      this.pageTitle = this.resources.getResourceArg('fa.page.license', [this.licenseId, ""]);
      await this.loadInformations();
    }
  }
  /**
   * 
   */
  async loadInformations() {
    try{
      this.currentLicense = await ModelLicense.getLicense(this._conf.http, this.licenseId).toPromise();
      this.title = this.resources.getResourceArg('fa.page.license', [this.licenseId, this.currentLicense['name']]);
      this.pageTitle = this.resources.getResourceArg('fa.page.license', [this.licenseId, this.currentLicense['name']]);
      this.conf.setPageConf({
        title: this.title,
        pageTitle: this.pageTitle,
        gotsubheader: true
      });
    }
    catch(e) {
      this.title = this.resources.getResource('fa.common.error.403');
    }
  }
  /**
   * get user access
   */
  gotUserAccess() {
    if(this._conf && this._conf.current_conf && this._conf.current_conf.currentUser && this._conf.current_conf.currentUser.roleName
      && (this._conf.current_conf.currentUser.roleName == 'admin' || this._conf.current_conf.currentUser.roleName == 'super_admin')) {
        return true;
    }
    return false;
  }
  /**
   * Logo url
   * @param c_lic 
   */
  logoSrc() {
    return ModelLicense.getPictureLicense(this.currentLicense);
  }

  /**
   * Back to licenses
   */
  back() {
    this.router.navigate(['/admin/licenses/']);
  }

}
