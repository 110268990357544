import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

/**
 * Resources
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  /**
   * Current lang of resources service
   */
  current_lang = 'en';
  /**
   * Resources  of resources service
   */
  resources = [];
  /**
   * Creates an instance of resources service.
   * @param conf 
   */
  constructor(private conf: ConfigService) {
  }

  /**
   * Gets resources
   * @returns resources 
   */
  getResources(): any {
    if (!this.resources || this.resources.length === 0) {
      this.current_lang = this.conf.current_lang;
      if (this.conf.current_conf && this.conf.current_conf.resources) {
        this.resources = this.conf.current_conf.resources;
        this.conf.loader.text = this.getResource('fa.common.loading');
      }
    }
    return this.resources[this.current_lang];
  }

  /**
   * Gets resource
   * @param key 
   * @returns  
   */
  getResource(key: string) {
    if (this.getResources()) {
      let s = this.getResources()[key];
      if(!s) {
        return key;
      }
      return s;
    }
    return key;
  }

  /**
   * Gets resource with arguments
   * @param key 
   * @returns  
   */
  getResourceArg(key: string, args: Array<string>) {
    if (this.getResources()) {
      let s = this.getResources()[key];
      if (s || s === '') {
        s = this.replaceToken(s);
        const reg = new RegExp('{[0-9]*}', 'g');
        let i = null;
        let match = reg.exec(s);
        const map = this.escapeCharEntities();
        let tempValue = null;
        while (match) {
          i = parseInt(match[0].substring(1, match[0].length - 1), 10);
          tempValue = (args[i] + '').replace(new RegExp('[&|<|>|"|\']', 'gm'), function (m) { return map[m]; });
          s = s.replace(match[0], tempValue);
          // take care bug if there are '/{' in the string
          match = reg.exec(s);
        }
        return s;
      }
    }
    return key;
  }

  /**
   * Escape some char '\\[[a-z.]*\\]'
   * @param resource 
   * @returns  
   */
  replaceToken(resource): string {
    const reg = new RegExp('\\[[a-z.]*\\]');
    let resourceKey = '';
    let res = '';
    let match = reg.exec(resource);

    while (match) {
      resourceKey = match[0].substring(1, match[0].length - 1);
      res = this.getResources()[resourceKey];
      resource = resource.replace(match[0], res);
      match = reg.exec(resource);
    }
    return resource;
  }

  /**
   * Escapes char entities
   * @returns  map of char to replace
   */
  escapeCharEntities() {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '\"': '&quot;',
      '\'': '&apos;'
    };
    return map;
  }
}
