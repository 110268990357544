import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModelTool } from './model-tool';

/**
 * ModelLicense
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelLicense {
  /**
   * Get license list url
   */
  static getLicenseListUrl: string = "/api/license/getList?keyword={0}&offset={1}&limit={2}&sort={3}&order={4}";
  /**
   * CreateLicense url
   */
  static createLicenseUrl: string = "/api/license/createLicense?name={0}&valid_time={1}";
  /**
   * Upload picture license url
   */
  static uploadLicensePictureUrl: string = "/api/license/{0}/uploadPictureLicense";
  /**
   * Picture license url setted
   */
  static pictureLicenseUrlSetted: string = '/api/license/{0}/getPictureLicense?t={1}';
  /**
   * Default Logo url
   */
  static logoDefaultSrc = '/assets/images/logoTransparent.png';
  /**
   * Get license by id url
   */
  static getLicenseByIdUrl: string = "/api/license/getLicenseById?id={0}";
  /**
   * UpdateLicense url
   */
  static updateLicenseUrl: string = "/api/license/updateLicense?id={0}&valid_time={1}";
  /**
   * create License Detail Url
   */
  static createLicenseDetailUrl: string = "/api/license/createLicenseDetail?id_license={0}&name={1}&value={2}";
  /**
   * update License Detail Url
   */
  static updateLicenseDetailUrl: string = "/api/license/updateLicenseDetail?id_license={0}&id={1}&value={2}";
  /**
   * delete License Detail Url
   */
  static deleteLicenseDetailUrl: string = "/api/license/deleteLicenseDetail?id_license={0}&id={1}";
  /**
   * get License Detail List Url
   */
  static getLicenseDetailListUrl: string = "/api/license/getLicenseDetailList?id_license={0}";

    /**
     * ctr
     */
    constructor(){

    }

    public static createLicense(http: HttpClient, license_name: string, license_expiration: number): Observable<any> {
      return http.get(environment.prefixURLXHR + ModelLicense.createLicenseUrl.replace("{0}", license_name).replace("{1}", license_expiration+''));
    }
    public static getLicenseList(http: HttpClient, offset: number, limit: number, sort: string, order: string): Observable<any> {
        return http.get(
            environment.prefixURLXHR + this.getLicenseListUrl
              .replace("{0}", "")
              .replace("{1}", offset + '')
              .replace("{2}", limit + '')
              .replace("{3}", sort)
              .replace("{4}", order)
            );
    }
    public static uploadLicensePicture(http: HttpClient, id: string, formData: any): Observable<any> {
        let url_upload = ModelLicense.uploadLicensePictureUrl.replace('{0}', id);        
        return ModelTool.upload(http, environment.prefixURLXHR + url_upload, formData);
    }
    public static updateLicense(http: HttpClient, id: string, license_expiration: number): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelLicense.updateLicenseUrl.replace("{0}", id).replace("{1}", license_expiration+''));
    }
    public static getPictureLicense(current_license: any): string {
        if(current_license && current_license.imageToken && current_license.imageToken.trim().length > 2) {
            return environment.prefixURLXHR + ModelLicense.pictureLicenseUrlSetted.replace('{0}', current_license.id).replace('{1}', current_license.lastUpdate+'');
        } else {
            return environment.prefixFileXHR + ModelLicense.logoDefaultSrc;
        }
    }
    public static getLicense(http: HttpClient, id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelLicense.getLicenseByIdUrl.replace("{0}", id));
    }
    public static createLicenseDetail(http: HttpClient, id_license: string, name: string, value: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelLicense.createLicenseDetailUrl.replace("{0}", id_license).replace("{1}", name).replace("{2}", value));
    }
    public static updateLicenseDetail(http: HttpClient, id_license: string, id: string, value: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelLicense.updateLicenseDetailUrl.replace("{0}", id_license).replace("{1}", id).replace("{2}", value));
    }
    public static deleteLicenseDetail(http: HttpClient, id_license: string, id: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelLicense.deleteLicenseDetailUrl.replace("{0}", id_license).replace("{1}", id));
    }
    public static getLicenseDetailList(http: HttpClient, id_license: string): Observable<any> {
        return http.get(environment.prefixURLXHR + ModelLicense.getLicenseDetailListUrl.replace("{0}", id_license));
    }
}