import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * ModelTool
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class ModelTool {
    /**
     * ctr
     */
    constructor(){

    }

    public static upload(http: HttpClient, url_upload: string, formData: any): Observable<any> {
        let token = '';
        let cookies: Array<string> = document.cookie.split(';');
        for(let c_cookie of cookies) {
          if(c_cookie && c_cookie.indexOf('jwt_token') != -1) {
            token = c_cookie.replace('jwt_token=', '');
          }
        }
        let header = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        const options = {
          headers: header,
        };        
        return http.post(url_upload, formData, options);
    }
}