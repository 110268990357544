import { Component, OnInit, ViewEncapsulation, Input, OnChanges } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { ConfigService } from 'src/app/tools/config.service';
import { DialogComponent } from 'src/app/controllers/dialog/dialog/dialog.component';
import { ModelPerimeter } from 'src/app/DAL/model-perimeter';

/**
 * Perimeter mode
 */
interface PerimeterNode {
  id: number;
  name: string;
  perimeterId: number;
  children?: PerimeterNode[];
}
/**
 * Flat node
 */
interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: number;
  perimeterId: number;
}

/**
 * Admin perimeters
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-perimeters',
  templateUrl: './perimeters.component.html',
  styleUrls: ['./perimeters.component.scss'],
  host: {'class': 'perimeters'},
  encapsulation: ViewEncapsulation.None
})
export class PerimetersComponent implements OnInit, OnChanges {
  /**
   * Transformer
   */
  private _transformer = (node: PerimeterNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      perimeterId: node.perimeterId
    };
  }
  /**
   * Tree control
   */
  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);
  /**
   * Tree flattener
   */
  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);
  /**
   * Has child
   */
  hasChild = (_: number, node: FlatNode) => node.expandable;
  /**
   * Data Source
   */
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;

  constructor(public resources: ResourcesService, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  ngOnInit() {
  }
  /**
   * On change
   * @param change 
   */
  async ngOnChanges(change) {
    if(change.licenseId && change.licenseId.currentValue && change.licenseId.currentValue != '0') {
      await this.loadPerimeters();
    }
  }
  /**
   * Load perimeters
   */
  async loadPerimeters() {
    let perimeter_list = <Array<any>> await ModelPerimeter.getPerimeterList(this.conf.http, this.licenseId).toPromise();
    let tree_perimeter = this.toTree(perimeter_list, 0);
    this.dataSource.data = tree_perimeter;
  }
  /**
   * to Tree
   * @param data 
   * @param root 
   */
  toTree (data, root) {
    var r = [], o = {};
    data.forEach(function (a) {
        if (o[a.id] && o[a.id].children) {
            a.children = o[a.id] && o[a.id].children;
        }
        o[a.id] = a;
        if (a.perimeterId === root) {
            r.push(a);
        } else {
            o[a.perimeterId] = o[a.perimeterId] || {};
            o[a.perimeterId].children = o[a.perimeterId].children || [];
            o[a.perimeterId].children.push(a);
        }
    });
    return r;
  } 

  /**
   * Add perimeter
   * @param c_perimeter 
   */
  async add(c_perimeter) {
    let data: {perimeterName: string} = {perimeterName: ''};

    let datas: Array<Formdata> = new Array<Formdata>();
    let alphaNumInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*',
      pattern: /^\w+$/,
      patternText: 'fa.licenses.name.pattern'
    };    
    datas.push(new Formdata('perimeterName', this.resources.getResource('fa.licenses.name'), data['perimeterName'], formdataType.text, alphaNumInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null/*this.db*/, arg: {
        title: this.resources.getResource('fa.perimeter.actions.add'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      //after post
      try {        
        let c_lic_a = await ModelPerimeter.createPerimeter(this.conf.http, result.perimeterName.toLowerCase(), c_perimeter.id, this.licenseId).toPromise();
        await this.loadPerimeters();
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }
  }

  /**
   * Edit perimeter
   * @param c_perimeter 
   */
  async edit(c_perimeter) {
    let data: {perimeterName: string} = {perimeterName: c_perimeter.name};

    let datas: Array<Formdata> = new Array<Formdata>();
    let alphaNumInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*',
      pattern: /^\w+$/,
      patternText: 'fa.licenses.name.pattern'
    };    
    datas.push(new Formdata('perimeterName', this.resources.getResource('fa.licenses.name'), data['perimeterName'], formdataType.text, alphaNumInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null/*this.db*/, arg: {
        title: this.resources.getResource('fa.perimeter.actions.edit'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      //after post
      try {              
        let c_lic_a = await ModelPerimeter.editPerimeter(this.conf.http, result.perimeterName.toLowerCase(), c_perimeter.id, this.licenseId).toPromise();
        await this.loadPerimeters();
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }
  }
  /**
   * Delete perimeter
   * @param c_perimeter 
   */
  async delete(c_perimeter) {
    try {      
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
          title: this.resources.getResource('fa.common.information'), 
          text: this.resources.getResource('fa.perimeter.actions.delete.confirm'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.OK')
          } },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
        await ModelPerimeter.deletePerimeter(this.conf.http, c_perimeter.id, this.licenseId).toPromise();
        await this.loadPerimeters();
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
}
