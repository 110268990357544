import { HttpClient } from '@angular/common/http';
import { ConfigService } from './../tools/config.service';
import { ResourcesService } from './../tools/resources.service';

/**
 * Minimum dialog information
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
export class Minimumdialog {
  /**
   * HTTP: Call xhr 
   */
  http: HttpClient;
  /**
   * Resources: Resources service
   */
  resources: ResourcesService;
  /**
   * Conf: Config service
   */
  conf: ConfigService;
  /**
   * Db: Call database
   */
  //db: DBService;
  /**
   * Arguments list
   */
  arg: Object;
}

//TO DELETE after notification created in model
export class CalindaPerson {
  civility: string;
  firstname: string;
  lastname: string;
}

export class CalindaNotification {
  filename: string;
  author: CalindaPerson;
  type: string;
  contractId: string;
  transactionId: string;
  datetime: number;
  isNew: boolean;
}
