import { Component, ViewEncapsulation  } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';
import { ConfigService } from 'src/app/tools/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { page } from '../page';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ModelCategory } from 'src/app/DAL/model-category';
import { ModelPlaylist, kind } from 'src/app/DAL/model-playlist';

/**
 * Home
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  host: {'class': 'page'},
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends page {
  /**
   * is inited
   */
  inited: boolean;
  /**
   * List of categories
   */
  categories: any;
  /**
   * Kind of playlist
   */
  kind: kind;
  /**
   * Swiper configuration
   */
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  /**
   * Creates an instance of about component.
   * @param resources Resources Service
   * @param conf Configuration Service
   * @param db Database Service
   * @param route Current route
   * @param router Router Service
   * @param snackBar SnackBar
   */
  constructor(public _resources: ResourcesService, public _conf: ConfigService/*, public db: DBService*/, public domS: DomSanitizer, public _route: ActivatedRoute, public _router: Router, public _snackBar: MatSnackBar) {
    super(_resources, _conf, _route, _router, _snackBar);
  }

  /**
   * Child init
   */
  async childInit() {
    try{
      this.kind = this.route.snapshot.paramMap.get('kind') == kind.PODCAST ? kind.PODCAST : kind.REGULAR;
    } catch(e) {
      this.kind = kind.REGULAR;
    }
    this.title = this.resources.getResource('fa.page.home');
    this.pageTitle = this.resources.getResource('fa.page.home');
    this.conf.setPageConf({
      title: this.title,
      pageTitle: this.pageTitle,
      gotsubheader: true
    });
    if(environment.mobile && this.conf.confFail) {
      this.router.navigate(['/login']);
    }
    if(!this.inited) {
      this.inited = true;
      this.categories = await ModelCategory.getCategoryList(this.conf.http, "", 0, 1000, 'id', 'desc', "0").toPromise();
      for(let c_category of this.categories.list) {
        c_category.playlist = await ModelPlaylist.getPlaylistByCategory(this.conf.http, c_category.id, this.kind, "0").toPromise();
      }
    }
  }
  /**
   * Picture playlist url
   */
  pictureSrc(c_playlist) {
    return ModelPlaylist.getPicturePlaylist(c_playlist, '0');
  }
  /**
   * Get Style
   * @param c_playlist Style of image
   */
  getStyle(c_playlist) {
    return {
      'background-size': 'cover', 
      'background-image': "url('"+this.pictureSrc(c_playlist) + "')"
    };
  }
  /**
   * Go to detail
   * @param c_product 
   */
  goDetail(c_playlist) {
    this.router.navigate(['/cablist/' + c_playlist.id]);
  }
  /**
   * On index changed
   */
  onIndexChange(e){

  }

}
