import { Injectable } from '@angular/core';

/**
 * Error Handler
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Injectable()
export class ErrorHandler {

  /**
   * Creates an instance of error handler.
   */
  constructor( ) {}

  /**
   * Handles error
   * @param err 
   */
  public handleError(err: any) {
  }
}