import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { ResourcesService } from 'src/app/tools/resources.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatSnackBar, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { ConfigService } from 'src/app/tools/config.service';
import { Formdata, formdataType } from 'src/app/controllers/form/formdata';
import { FormdialogComponent } from 'src/app/controllers/dialog/formdialog/formdialog.component';
import { orientationType, actionMode } from 'src/app/controllers/iconbutton/iconbutton.component';
import { DialogComponent } from 'src/app/controllers/dialog/dialog/dialog.component';
import { kind, ModelPlaylist } from 'src/app/DAL/model-playlist';
import { ModelCommon } from 'src/app/DAL/model-common';
import { ModelPerimeter } from 'src/app/DAL/model-perimeter';

/**
 * Admin playlists
 * Author nalcina<br/>
 * Version Coacheer 1.0<br/>
 * Copyright Nicolas Alcina 2019
 */
@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
  host: {'class': 'playlists'},
  encapsulation: ViewEncapsulation.None
})
export class PlaylistsComponent implements OnInit {
  /**
   * Columns displayable
   */
  displayedColumns: Array<string> = ['id', 'title', 'desc', 'kind', 'notActive', 'actions'];
  /**
   * Columns displayable alias
   */
  displayedColumnsAlias: any = {'id': 'id', 'title': 'title', 'desc': 'desc', 'kind': 'kind', 'notActive': 'notActive'};
  /**
   * Page length
   */
  pageLength: number = 20;
  /**
   * Page index
   */
  pageIndex: number = 0;
  /**
   * Page length
   */
  pageSize: number = 1;
  /**
   * Sort column
   */
  sortColumn = 'id';
  /**
   * Sort Order
   */
  sortOrder = 'asc';
  /**
   * List of playlist
   */
  playlistList: any;
  /**
   * Current playlist for edit
   */
  currentPlaylist: any = null;
  /**
   * Mat sort
   */
  sort: Sort;
  /**
   * License id
   */
  @Input() licenseId: string;
  /**
   * current configuration
   */
  @Input() conf: ConfigService;

  /**
   * ctr
   * @param resources 
   * @param http 
   * @param dialog 
   * @param snackBar 
   */
  constructor(public resources: ResourcesService, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  /**
   * On init
   */
  ngOnInit() {
  }
  /**
   * On change
   * @param change 
   */
  async ngOnChanges(change) {
    if(change.licenseId && change.licenseId.currentValue && change.licenseId.currentValue != '0') {
      await this.loadPlaylists(this.pageIndex);
    }
  }
  /**
   * Sort data
   */
  sortData(_sort: Sort) {
    this.sort = _sort;
    this.loadPlaylists(0);
  }
  /**
   * Picture playlist url
   */
  pictureSrc(c_playlist) {
    return ModelPlaylist.getPicturePlaylist(c_playlist, this.licenseId);
  }
  /**
   * Load categories
   * @param _index 
   */
  async loadPlaylists(_index: number) {
    if(this.sort && this.sort.direction) {
      this.sortOrder = this.sort.direction;
    }
    if(this.sort && this.sort.active) {
      this.sortColumn = this.sort.active;
    }

    this.pageIndex = _index;
    let category_list = <Array<any>> await ModelPlaylist.getPlaylistList(
      this.http, "", this.pageIndex * this.pageLength, this.pageLength, this.displayedColumnsAlias[this.sortColumn], this.sortOrder, this.licenseId
      ).toPromise();
    this.playlistList = new MatTableDataSource(category_list['list']);
    this.pageSize = category_list['size'];
    this.playlistList.sort = this.sort;
  }
  /**
   * Add user
   */
  async add() {
    let data: {title: string, summary: string, desc: string, perimeter: number} = {title: '', summary: '', desc: '', perimeter: 0};

    let datas: Array<Formdata> = new Array<Formdata>();
    let requiredInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: '*'
    };
    let pictureInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: [],
      hint: this.resources.getResource('fa.playlist.picture.title'),
      iconName: 'camera_alt',
      orientation: orientationType.vertical,
      actionmode: actionMode.upload
    };
    let kind_data = [];
    for(let i_role in kind) {
      kind_data.push({
        'value': kind[i_role],
        'name': this.resources.getResource('fa.playlist.kind.' + (kind[i_role] +'').toLowerCase())
      });
    }
    let kindInfo = {
      require: true,
      requireText: this.resources.getResource('fa.common.required'),
      datas: kind_data,
      hint: '*'
    };
    datas.push(new Formdata('Image', this.resources.getResource('fa.playlist.picture'), ModelPlaylist.picturePlaylistUrlEmpty, formdataType.image, pictureInfo, false));
    datas.push(new Formdata('title', this.resources.getResource('fa.playlist.title'), data['title'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('desc', this.resources.getResource('fa.playlist.desc'), data['desc'], formdataType.text, requiredInfo, false));
    datas.push(new Formdata('kind', this.resources.getResource('fa.playlist.kind'), data['desc'], formdataType.dropdown, kindInfo, false));

    const dialogRef = this.dialog.open(FormdialogComponent, {
      width: '450px',
      data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
        title: this.resources.getResource('fa.playlist.actions.add'), 
        cancelButton: this.resources.getResource('fa.common.cancel'), 
        OKButton: this.resources.getResource('fa.common.validate'),
        datas: datas} },
        panelClass: 'matDialogNoPadding'
    });    
    let result = await dialogRef.afterClosed().toPromise();
    if(result) {
      console.log(result);
      //after post
      try {              
        let c_data = {
          'title': result.title,
          'desc': result.desc,
          'kind': result.kind,
          'license_id': this.licenseId
        };
        let res = await ModelPlaylist.createPlaylist(this.conf.http, c_data).toPromise();
        let c_user_a = JSON.parse(res['message']);

        //PICTURE
        let formData = result.pictures['Image'];
        if(formData != ModelPlaylist.picturePlaylistUrlEmpty) {
          let ret = await ModelPlaylist.uploadPlaylistPicture(this.conf.http, c_user_a['Id'], this.licenseId, formData).toPromise();
        }
        //RELOAD
        await this.loadPlaylists(this.pageIndex);
      } catch(e) {
        var error_text = 'fa.common.error.500';
        this.snackBar.open(this.resources.getResource(error_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    }


  }
  /**
   * Edit playlist
   * @param c_playlist
   */
  edit(c_playlist) {
    this.currentPlaylist = c_playlist;
  }
  /**
   * Deactivate Playlist
   * @param c_playlist Edit
   * @param not_active 
   */
  async deactivatePlaylist(c_playlist, not_active: boolean){
    try {      
        await ModelPlaylist.deactivatePlaylist(this.conf.http, {
          'id': c_playlist.id,
          'notactive': not_active, 
          'license_id': this.licenseId
        }).toPromise();
        //RELOAD
        await this.loadPlaylists(this.pageIndex);
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }    
  }
  /**
   * Delete playlist
   * @param c_playlist 
   */
  async delete(c_playlist) {
    try {      
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, arg: {
          title: this.resources.getResource('fa.common.information'), 
          text: this.resources.getResource('fa.playlist.actions.delete.confirm'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.OK')
          } },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
        await ModelPlaylist.deletePlaylist(this.conf.http, {
          'id': c_playlist.id,
          'license_id': this.licenseId
        }).toPromise();
        //RELOAD
        await this.loadPlaylists(this.pageIndex);
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }    
  }
  /**
   * Playlist Saved
   * @param ev 
   */
  async saved(ev) {
    await this.loadPlaylists(this.pageIndex);
    for(let c_playlist of this.playlistList.data) {
      if(c_playlist.id == this.currentPlaylist.id){
        this.currentPlaylist = c_playlist;
        break;
      }
    }
  }
  /**
   * End edit
   * @param ev 
   */
  endEdit(ev) {
    this.currentPlaylist = null;
  }
  /**
   * is kind of
   * @param c_playlist 
   * @param kind 
   */
  isKind(c_playlist, kind): boolean {
    return (c_playlist.kind + '') == kind;
  }
  /**
   * send notification
   * @param c_playlist 
   */
  async notifications(c_playlist) {
    try {
      let data: {title: string, body: string} = {
        title: this.resources.getResource('fa.playlist.actions.notifications.title.default'),
        body: this.resources.getResource('fa.playlist.actions.notifications.body.default').replace('{0}', c_playlist.title)
      };
      let datas: Array<Formdata> = new Array<Formdata>();
      let requiredInfo = {
        require: true,
        requireText: this.resources.getResource('fa.common.required'),
        datas: [],
        hint: '*'
      };
      let perimeter_list = <Array<any>> await ModelPerimeter.getPerimeterList(this.conf.http, this.licenseId).toPromise(); 
      let perimeter_data = [];
      for(let i_perimeter of perimeter_list) {
        perimeter_data.push({
          'value': i_perimeter.id,
          'name': i_perimeter.name
        });
      }
      let perimeterInfo = {
        require: true,
        requireText: this.resources.getResource('fa.common.required'),
        datas: perimeter_data,
        hint: '*'
      };
      datas.push(new Formdata('title', this.resources.getResource('fa.playlist.actions.notifications.title'), data['title'], formdataType.text, requiredInfo, false));
      datas.push(new Formdata('body', this.resources.getResource('fa.playlist.actions.notifications.body'), data['body'], formdataType.text, requiredInfo, false));
      datas.push(new Formdata('perimeter', this.resources.getResource('fa.products.perimeter'), data['perimeter'], formdataType.dropdown, perimeterInfo, false));

      const dialogRef = this.dialog.open(FormdialogComponent, {
        width: '450px',
        data: { http: this.conf.http, resources: this.resources, conf: this.conf, db : null, arg: {
          title: this.resources.getResource('fa.playlist.actions.notifications'), 
          cancelButton: this.resources.getResource('fa.common.cancel'), 
          OKButton: this.resources.getResource('fa.common.validate'),
          datas: datas} },
          panelClass: 'matDialogNoPadding'
      });    
      let result = await dialogRef.afterClosed().toPromise();
      if(result) {
          await ModelCommon.sendNotification(this.conf.http, {
            'title': result.title,
            'body': result.body,
            'product_id': 0,
            'playlist_id': c_playlist.id,
            'perimeter_id': parseInt(result.perimeter),
            'license_id': parseInt(this.licenseId)
          }).toPromise();
          
        var ok_text = 'fa.playlist.actions.notifications.sent';
        this.snackBar.open(this.resources.getResource(ok_text),
          this.resources.getResource('fa.common.OK'),
          {
            duration: 5000,
          });
      }
    } catch(e) {
      var error_text = 'fa.common.error.500';
      this.snackBar.open(this.resources.getResource(error_text),
        this.resources.getResource('fa.common.OK'),
        {
          duration: 5000,
        });
    }
  }
  
  /**
   * Get pages
   * @returns 
   */
  getPages(): number{
    return Math.ceil(this.pageSize/this.pageLength);
  }
  /**
   * Get pagination text
   * @returns 
   */
  getPaginationText(): string{
    return (this.pageIndex + 1) +'/' + this.getPages();
  }
  /**
   * Get next page
   * @returns
   */
  getNextPage(): number{
    return this.pageIndex+1<=this.getPages()-1 ? this.pageIndex+1 : this.getPages()-1; 
  }
  /**
   * Get last page
   * @return
   */
  getLastPage(): number{
    return this.getPages() - 1;
  }
}
